import styled from "styled-components";
import BgVideo from "../../../images/demo.png";
import { device } from "../../../theme/Device";
import { Link as LinkR } from "react-router-dom";

export const FoodExperience = styled.div`
  width: 100%;
  height: auto;
  margin-top: 93px;

  h3 {
    font-weight: bold;
    text-align: center;
    color: rgb(0, 0, 0);
    span {
      color: #ff9239;
    }
    @media only screen and ${device.mobileL} {
      margin-bottom: 0px;
      line-height: 30px;
    }

    @media only screen and ${device.tablet} {
      margin-bottom: 50px;
      line-height: 65px;
    }

    @media only screen and ${device.laptop} {
      margin-bottom: 50px;
      line-height: 75px;
    }
  }

  .takeatstitle {
    span {
      color: #ff8b7b;
    }
  }
`;

export const Card = styled.div`
  text-align: center;
  padding: 30px;
  border-radius: 38px;
  background: #fff;

  h5 {
    font-weight: bold;
    line-height: 27px;
    text-align: center;
    margin-top: 20px;
    color: #000000;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }
  img {
    @media only screen and ${device.mobileL} {
      height: 120px;
    }

    @media only screen and ${device.tablet} {
      height: 140px;
    }

    @media only screen and ${device.laptop} {
      height: 170px;
    }
  }
`;

export const HelpDiv = styled.div`
  width: 100%;
  height: auto;

  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    margin-top: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;
    span {
      color: #ff9239;
    }
  }
  @media only screen and ${device.mobileL} {
    margin-top: 83px;
    h2 {
      line-height: 40px;
    }
  }

  @media only screen and ${device.tablet} {
    margin-top: 153px;
    h2 {
      line-height: 60px;
      font-size: 35px;
    }
  }

  @media only screen and ${device.laptop} {
    margin-top: 153px;
    h2 {
      line-height: 60px;
      font-size: 40px;
    }
  }
`;

export const DivBtnRestaurant = styled.div`
  text-align: center;
`;

export const LinkSubRes = styled(LinkR)`
  width: 250px;
  height: 55px;
  text-decoration: none;
  line-height: 54px;
  display: inline-block;
  background: #700cbf;
  color: #fff;
  margin-top: 50px;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  border-radius: 53px;
`;

export const VideoDiv = styled.div`
  width: 100%;
  margin-top: 90px;
  padding: 15% 0px 0px 0px;
  margin-top: -25%;
  background: url(${BgVideo});
  background-position: top center;
  background-repeat: no-repeat;
  @media only screen and ${device.mobileL} {
    margin-top: -47%;
    background-size: auto;
  }

  @media only screen and ${device.tablet} {
    margin-top: -25%;
    background-size: 100% auto !important;
  }

  @media only screen and ${device.laptop} {
    margin-top: -25%;
    background-size: 100% auto !important;
  }
`;

export const Video = styled.div`
  text-align: center;

  margin: 160px auto;
  margin-top: 0px;

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export const UnderVideo = styled.div`
  h3 {
    margin-bottom: 100px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 50px;
    margin-top: 80px;

    span {
      color: #ff9239;
    }
  }

  img {
    width: 100%;
  }
  @media only screen and ${device.mobileL} {
    h3 {
      font-size: 25px;
    }
  }

  @media only screen and ${device.tablet} {
    font-size: 22px;
  }

  @media only screen and ${device.laptop} {
    h3 {
      font-size: 40px;
    }
  }
`;

export const BtnRDV = styled.div`
  span {
    position: relative;
    top: -33px;
  }
`;

export const ContainerFood = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;
export const ContainerFoodContent = styled.div`
  padding-top: 102px;
  .content-text {
    padding-top: 102px;
  }
  @media only screen and ${device.mobileL} {
    padding-top: 76px;
    .content-text {
      padding-top: 30px;
    }
  }

  @media only screen and ${device.tablet} {
    padding-top: 80px;
    .content-text {
      padding-top: 102px;
    }
  }

  @media only screen and ${device.laptop} {
    padding-top: 102px;
    .content-text {
      padding-top: 102px;
    }
  }
`;
export const ContainerFoodImg = styled.img`
  @media only screen and ${device.mobileL} {
    margin-top: 0px;
    width: 100%;
  }

  @media only screen and ${device.tablet} {
    margin-top: 130px;
    width: 100%;
  }

  @media only screen and ${device.laptop} {
    margin-top: 130px;
    width: 100%;
  }
`;

export const H5 = styled.h5`
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #000000;

  @media only screen and ${device.mobileL} {
    font-size: 20px;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    font-size: 27px;
    text-align: center;
  }

  @media only screen and ${device.laptop} {
    font-size: 30px;
    text-align: left;
  }
`;

export const P2B = styled.p`
  font-weight: normal;
  color: #000000;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media only screen and ${device.mobileL} {
    font-size: 16px;
  }

  @media only screen and ${device.tablet} {
    font-size: 18px;
  }

  @media only screen and ${device.laptop} {
    font-size: 20px;
  }
`;

export const ButtonInscris = styled.a`
  width: 250px;
  height: 65px;
  line-height: 64px;
  color: #fff;
  background: #700cbf;
  text-align: center;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  font-size: 21px;
  display: inline-block;
  border-radius: 53px;

  :hover {
    text-decoration: none;
    background: #fff;
    color: #700cbf;
    display: inline-block;
  }
  @media only screen and ${device.mobileL} {
    font-size: 18px;
  }

  @media only screen and ${device.tablet} {
    font-size: 20px;
  }

  @media only screen and ${device.laptop} {
    font-size: 21px;
  }
`;

export const ButtonCenter = styled.div`
  text-align: -webkit-center;
  margin-top: 10px;
`;

export const FunctionManager = styled.div`
  .orange-round {
    background-color: #ff9239;
    //collapse 1200
    margin: auto;
    border-radius: 50%;
    :after {
      content: "";
    }
    p {
      position: relative;
      padding: 5%;
      font-weight: 700;
      color: #fff;
      top: 21%;
      width: 100%;
      text-align: center;
    }

    .small-word {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @media only screen and ${device.mobileL} {
      height: 180px;
      width: 180px;
    }

    @media only screen and ${device.tablet} {
      height: 230px;
      width: 230px;
    }

    @media only screen and ${device.laptop} {
      height: 230px;
      width: 230px;
    }
  }

  .orange-carousel {
    margin-top: 60px;
    background-image: linear-gradient(white, white);
    background-repeat: no-repeat;

    @media only screen and ${device.mobileL} {
      background-size: 0% 4px;
      background-position: 45% center;
    }

    @media only screen and ${device.tablet} {
      background-size: 80% 4px;
      background-position: 45% center;
    }

    @media only screen and ${device.laptop} {
      background-size: 80% 4px;
      background-position: 45% center;
    }
  }
`;
