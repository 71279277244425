import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BtnDownload from "./../buttons/BtnDownload";
import {
  Display,
  Rien,
  Li,
  B,
  Buttons,
  Textbg,
  Textbg1,
  Subscribetoemail2,
  Subscribetoemail,
  Input,
  Container,
  Container2,
  Container3,
  Container4,
  Container5,
  Container6,
  Container7,
  ContainerFood,
  FoodExperience,
  H1,
  H2B,
  H3,
  H3bis,
  H3bis2,
  H5,
  C6H5,
  H5B,
  P,
  P2,
  P2B,
  Row,
  Pad,
  Small,
  Small2,
  App,
  Padding,
  Col,
  Col2,
  Register,
  Padding2,
  DivPartenaire,
  ItemCarousel,
  Video,
  Center,
  Center2,
  Shopping,
  BtnDivDl,
  HideImg,
  ReflexDiv,
  StandingManImg,
  SrcollIphoneImg,
  ImgTabMobile,
  ShoppingDiv,
  ContainerFoodImg,
  VisionContainer,
  MobileBannerImg,
} from "./Homepage.styles";

// import Image from "../../images/scroll_iPhone .png";
import StandMan from "../../images/homepage_img_banner.png";
import Iphone13Banner from "../../images/iphone13_homepage.png";
import Appstore from "../../images/app1.png";
import Playstore from "../../images/app2.png";
import sliderpic1 from "../../images/sliderpic1.png";
import sliderpic2 from "../../images/sliderpic2.png";
import sliderpic3 from "../../images/sliderpic3.png";
import sliderpic4 from "../../images/sliderpic4.png";
import sliderpic5 from "../../images/sliderpic5.png";
import sliderpic6 from "../../images/sliderpic6.png";
import sliderpic7 from "../../images/sliderpic7.png";
import appimage from "../../images/antigaspi.png";
import card1 from "../../images/card1.png";
import card2 from "../../images/card2.png";
import card3 from "../../images/card3.png";
import card4 from "../../images/card4.png";
import card5 from "../../images/card5.png";
import card6 from "../../images/card6.png";
import card7 from "../../images/card7.png";
import card8 from "../../images/card8.png";
import card9 from "../../images/card9.png";
import card10 from "../../images/card10.png";
import mobiletabimage from "../../images/iPhone13.png";
import mobileframe from "../../images/iPhone_home.png";
import mobileframe2 from "../../images/iPhone_home1.png";
import mobileframe3 from "../../images/iPhone_home2.png";
import textbg1 from "../../images/textbg1.png";
import textbg2 from "../../images/textbg2.png";
import textbg3 from "../../images/textbg3.png";
import Svg from "../../images/shape.svg";
//import Svg1 from "../../images/shape1.svg";
import Svg2 from "../../images/shape2.svg";
// import SophieIcon from "../../images/sophieimg.png";
import IPhoneAir from "../../images/iPadAir.png";
import Terrase1 from "../../images/Terrase1.png";
import Echange1 from "../../images/Echange1.png";
import Bondereduction from "../../images/Bondereduction.png";
// import Icon from '../../images/';

export default function Homepage(props) {
  // responsive of carousel items
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Container2>
      <Container>
        {/* Partie de Gauche */}
        <Pad className="container">
          <Row className="row">
            <Col2 className="col-lg-6 col-10 m-auto">
              <H1>
                Manger mieux,
                <br /> Manger solidaire !
              </H1>
              <P>
                Giveats, la première application food qui transforme tes
                commandes au restaurant et livraisons de repas en dons
                alimentaires collaboratifs pour lutter contre l’insécurité
                alimentaire des personnes précaires.
              </P>
              <Padding2 className="row">
                <BtnDownload
                  onClick={() => {
                    console.log("e");
                  }}
                />
                <Register to="/userform">S'inscrire</Register>
                <br></br>
                <Small2>
                  En t’inscrivant, tu acceptes les conditions de notre politique
                  de
                  <br></br>
                  confidentialité et d’accord pour recevoir les emails de notre
                  newsletter
                </Small2>
              </Padding2>
            </Col2>

            {/* Partie de droite */}
            <Col className="col-lg-6 col-12 text-center">
              {/* <SrcollIphoneImg src={Image} alt="first" /> */}
              <MobileBannerImg
                className="desktophide"
                src={Iphone13Banner}
                alt="iphone_banner"
              />
              <StandingManImg
                className="mobilehide"
                src={StandMan}
                alt="man_standing"
              />
              <App>
                <Small>
                  Lancement à venir bientôt sur
                  <br></br>
                  <b>Apple Store </b>
                  et
                  <b> Google Play</b>
                </Small>
                <br></br>
                <Padding>
                  <img src={Appstore} alt="store" className="apple" />
                  <img src={Playstore} alt="play" />
                </Padding>
              </App>
            </Col>
          </Row>
        </Pad>
      </Container>
      <img src={Svg} alt="svg" className="d-block w-100" />

      <FoodExperience className="container">
        <H3>
          La 1ère appli “Eat for good” <span>pour combattre la faim</span>{" "}
        </H3>

        <ContainerFood className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 text-center">
              <ContainerFoodImg alt="Terrase1" src={Terrase1} />
            </div>
            <div className="col-md-12 col-lg-6 section3">
              <H5>Une appli pour tous les repas hors domicile</H5>
              <P2B>
                Fais-toi <span>livrer tes plats, réserves ta table</span> et
                commande ton repas avant de venir. <br></br>
                Récupère ton <span>repas à emporter</span> sans frustration et
                paiement 100% mobile ! <br></br>
                <span>Une appli 3 en 1</span> pour tous tes repas hors
                domiciles.
              </P2B>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 section3  order-2 order-lg-1">
              <H5>Aide des personnes en précarité à se nourrir</H5>
              <P2B>
                Et si tu pouvais{" "}
                <span>aider des personnes en précarité à se nourrir</span>{" "}
                gratuitement à chaque fois que tu passes commande ? <br></br>
                Avec l’appli Giveats, c’est possible ! <br></br>
                Tes commandes sur l’appli participent collectivement et
                directement à{" "}
                <span>
                  sauver de vrais bons repas de restos pour les personnes
                  démunies.
                </span>
              </P2B>
            </div>
            <div className="col-md-12 col-lg-6  order-1 order-lg-2 text-center">
              <ContainerFoodImg alt="Echange1" src={Echange1} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 text-center">
              <ContainerFoodImg alt="Bondereduction" src={Bondereduction} />
            </div>
            <div className="col-md-12 col-lg-6 section3">
              <H5>
                Deviens un foodie “Giver” Gagne des réductions dans tes restos
                préférés
              </H5>
              <P2B>
                On a pensé à toi ! <br></br>
                Sur l’appli, ta fidélité est récompensée. <br></br>
                C’est la première appli qui t’octroie{" "}
                <span>
                  {" "}
                  des promos dans les restos où tu commandes le plus souvent...
                </span>{" "}
                <br></br>
                Des remises <span>jusqu’à -30% dans les restos </span>que tu
                fréquentes le plus souvent.
              </P2B>
            </div>
          </div>
        </ContainerFood>
      </FoodExperience>

      <Container3>
        <DivPartenaire className="container">
          <div className="row">
            <div className="col-sm-12 col-12">
              <H3>Ils parlent de nous !</H3>
            </div>

            <Carousel responsive={responsive}>
              <ItemCarousel>
                <a href="https://www.ouest-france.fr/leditiondusoir/2021-06-01/les-bonnes-nouvelles-du-jour-malgre-le-coronavirus-eda8f8cd-0e9c-4429-8fef-a1701c89a339">
                  <img src={sliderpic1} alt="sliderpic1" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.neonmag.fr/giveats-lappli-qui-permet-de-commander-des-repas-et-den-offrir-aux-personnes-dans-le-besoin-556525.html">
                  <img src={sliderpic2} alt="sliderpic2" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.ladn.eu/adn-business/news-business/actualites-annonceurs/application-giveats-foodservice-lutte-precarite-gaspillage-alimentaire/">
                  <img src={sliderpic3} alt="sliderpic3" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.7detable.com/article/digital/giveats-l-appli-qui-permet-de-commander-un-repas-et-d-en-offrir-egalement/3387">
                  <img src={sliderpic4} alt="sliderpic4" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://lapauseinfo.fr/giveats-application-vocation-eliminer-famine/">
                  <img src={sliderpic5} alt="sliderpic5" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.snacking-today.com/article/giveats-la-nouvelle-appli-food-service-francaise">
                  <img src={sliderpic6} alt="sliderpic6" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.neorestauration.com/article/giveats-l-appli-de-commande-de-repas-qui-donne-a-manger-aux-precaires,58388">
                  <img src={sliderpic7} alt="sliderpic7" />
                </a>
              </ItemCarousel>
            </Carousel>
          </div>
        </DivPartenaire>
        <Video>
          <div className="video-responsive">
            <iframe
              width="460"
              height="315"
              title="YouTube video player"
              src="https://www.youtube.com/embed/H9DrVNu6Ehs?enablejsapi=1"
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              preload="none"
            ></iframe>
          </div>
          <br></br>
          <div>
            <span className="btn1">
              <BtnDownload />
            </span>
          </div>
        </Video>
      </Container3>

      <img src={Svg2} alt="svg2" className="d-block w-100" />
      <ShoppingDiv className="container">
        <H3bis>
          Plus qu’une appli de resto,
          <br></br>
          un geste <span>solidaire</span> et <span>antigaspi quotidien</span>
        </H3bis>
        <Center>
          <img src={appimage} alt="appimage" />
        </Center>
        <Shopping>
          <p>
            Sur l’app Giveats, pré-commandes et effectues tes paiements à
            l'avance facilement en CB ou titres-restaurants dématérialisés.
          </p>
          <img src={card1} alt="card1" />
          <img src={card2} alt="card2" />
          <img src={card3} alt="card3" />
          <img src={card4} alt="card4" />
          <img src={card5} alt="card5" />
          <img src={card6} alt="card6" />
          <img src={card7} alt="card7" />
          <img src={card8} alt="card8" />
          <img src={card9} alt="card9" />
          <img src={card10} alt="card10" />
        </Shopping>
      </ShoppingDiv>
      {/* <img src={Svg1} alt="svg1" className="d-block w-100" /> */}
      <Container4 className="mobilehide">
        <H2B>Comment ça marche ?</H2B>
        <Display className="container">
          <div className="col-12 row">
            <Center2 className="col-lg-4 col-12 order-lg-2">
              <img src={mobiletabimage} alt="mobiletabimage" />
            </Center2>
            <ul
              className="nav col-lg-4 col-6 order-lg-1"
              id="myTab"
              role="tablist"
            >
              <Li>
                <a
                  className="nav-link"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  <p>
                    <B>1.</B> <br /> Choisis ton restaurant et ta méthode
                    (livraison, click&collect, réservation de table)
                  </p>
                </a>
              </Li>

              <Li>
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#contact"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  <p>
                    <B>3.</B> <br /> Laisse un micro-don alimentaire
                  </p>
                </a>
              </Li>

              <Li>
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#gagne"
                  role="tab"
                  aria-controls="gagne"
                  aria-selected="false"
                >
                  <p>
                    <B>5.</B>
                    <br />
                    Gagne des remises exclusives
                  </p>
                </a>
              </Li>

              <Li>
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#suis"
                  role="tab"
                  aria-controls="suis"
                  aria-selected="false"
                >
                  <p>
                    <B>7.</B> <br></br> Suis l'état de ta commande{" "}
                  </p>
                </a>
              </Li>
            </ul>
            <ul
              className="col-lg-4 col-6 nav order-lg-3"
              id="myTab"
              role="tablist"
            >
              <Li>
                <a
                  className="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  <p>
                    <B>2.</B> <br></br> Paie 100% en ligne
                  </p>
                </a>
              </Li>

              <Li>
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#suis"
                  role="tab"
                  aria-controls="suis"
                  aria-selected="false"
                >
                  <p>
                    <B>4.</B> <br></br> Suis ton impact
                  </p>
                </a>
              </Li>
              <Li>
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#note"
                  role="tab"
                  aria-controls="gagne"
                  aria-selected="false"
                >
                  <p>
                    <B>6.</B> <br></br> Note et laisse un commentaire
                  </p>
                </a>
              </Li>
            </ul>
          </div>
        </Display>
        <BtnDivDl className="col-12">
          <span className="btn1">
            <BtnDownload />
          </span>
        </BtnDivDl>
      </Container4>
      <Container6 marginTop="0px">
        <H3bis2>
          Pourquoi devenir <span>utilisateur de Giveats</span>
        </H3bis2>
        <div className="container">
          <div className="row">
            <div className="order-md-1 order-2 col-12 col-md-6  text-center">
              <HideImg src={mobileframe} alt="mobileframe" />
            </div>
            <div className="col-12 col-md-6 order-md-2 order-1">
              <div>
                <C6H5>Une expérience optimisée</C6H5>
                <P2>
                  Expérience assise ou en livraison, <br></br> mange en bonne
                  conscience !
                </P2>
              </div>

              <div>
                <C6H5>Livraison de repas éthique</C6H5>
                <P2>
                  Demain, fais-toi livrer par des livreurs giveats salariés.{" "}
                  <br></br>
                  Aujourd’hui des livreurs Stuart te livrent chez toi.
                </P2>
              </div>
            </div>
          </div>
        </div>
      </Container6>
      <Container5>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <H5B>Les invendus sauvés</H5B>
                <P2 color="#ffffff">
                  Nos restos s’engagent à ne plus jeter <br></br>les denrées
                  encore consommables !
                </P2>
              </div>

              <div>
                <H5B>La précarité alimentaire éliminée</H5B>
                <P2 color="#ffffff">
                  Autour de toi, des milliers de personnes dépendent de l’aide
                  alimentaire pour manger à leur faim.
                </P2>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <HideImg src={mobileframe2} alt="mobileframe2" zIndex="81" />
            </div>
          </div>
        </div>
      </Container5>
      <Container6 zIndex="82">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 text-center order-md-1 order-2">
              <HideImg src={mobileframe3} alt="mobileframe3" zIndex="82" />
            </div>
            <div className="col-12 col-md-6 order-md-2 order-1">
              <div>
                <C6H5>Des restos solidaires et engagés</C6H5>
                <P2>
                  Découvre des restaurateurs qui s’engagent <br></br> contre la
                  précarité alimentaire et le gaspillage.
                </P2>
              </div>
              <br></br>

              <div>
                <C6H5>L’appli récompense ta fidélité</C6H5>
                <P2>
                  Jusqu’à -30% de réductions dans tes restos <br></br> préférés
                  et des points “geeds” à chaque utilisation.
                </P2>
              </div>
            </div>
          </div>
        </div>
      </Container6>
      <Subscribetoemail>
        <Input
          className="mb-3"
          type="email"
          name="subscribetoemail"
          placeholder="Laisse ton email"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        ></Input>
        <Buttons type="submit">S’inscrire</Buttons>
      </Subscribetoemail>

      <Container7>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8 ">
              <Textbg>
                <h4>
                  <span>Ensemble,</span> combattons <br /> l’insécurité
                  alimentaire
                </h4>
                <p>
                  8 millions de personnes dépendent de l’aide alimentaire
                  <br className="mobilehide" />
                  en France, un chiffre beaucoup trop haut...
                  <br></br>
                  <br className="mobilehide" /> Avec la crise sanitaire, la
                  demande d’aide alimentaire
                  <br className="mobilehide" />
                  explose. Si bien que pour des millions de personnes en
                  <br className="mobilehide" />
                  situation de vulnérabilité économique ou sociale, l’accès{" "}
                  <br className="mobilehide" />à une alimentation sûre,
                  diversifiée et de bonne qualité
                  <br className="mobilehide" />
                  devient difficile et rare !
                </p>
              </Textbg>
            </div>
            <div
              className="col-12 col-lg-4 mdrstext"
              style={{
                textAlign: "center",
              }}
            >
              <h3 className="purple_title">11Mdrs</h3>
              <p>
                C’est la quantité de <br></br>repas consommés hors <br></br>
                domicile par les <br></br>
                Français en 2019
              </p>
              <small
                style={{
                  textAlign: "center",
                }}
              >
                Source : GIRA conseil & Statista
              </small>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mdrstext whitezone">
              <h3 className="white_title"> 1.33M tonnes</h3>
              <p>
                C’est le volume de denrées alimentaires gâchées chaque année
                dans la restauration
              </p>
              <small>Source : ADEME</small>
            </div>
            <div className="col-12 col-lg-8 ">
              <Textbg1>
                <h4>
                  <span>Ensemble,</span> pour une aide alimentaire collaborative
                </h4>
                <p>
                  Sauver la nourriture du gaspillage est un enjeu majeur. Le
                  gaspillage alimentaire est la{" "}
                  <b>3ème cause d’émission de gaz à effet de serre</b> dans le
                  monde.
                  <br></br>
                  <br></br>
                  De même, l’aide alimentaire est plus qu’importante dans la
                  société car c’est le premier pilier contre la précarité et
                  l’isolement social.
                  <br></br>
                  <br></br>
                  Et si on pouvait faire d’une pierre deux coups en résolvant le
                  gaspillage alimentaire et la précarité alimentaire via une
                  solution simple, efficace et quotidienne ?
                </p>
              </Textbg1>
              <div className="col-12 mb-2 divbutton">
                <BtnDownload />
              </div>
            </div>
          </div>
        </div>
      </Container7>
      <VisionContainer className="container">
        <h4>
          Notre <span>Vision</span>
        </h4>
        <div className="notebox">
          <h5 className="h51">SOLIDARITÉ ET ENTRAIDE CITOYENNE</h5>
          <p>
            Chez Giveats, nous avons la volonté de donner aux foodies et{" "}
            <br></br>
            aux restaurants le pouvoir de lutter facilement et quotidiennement{" "}
            <br></br>
            contre la précarité alimentaire et le gaspillage alimentaire.
          </p>
          <h5>
            <span>
              <b>Notre mission :</b>
            </span>{" "}
            <br></br>agir contre la précarité alimentaire en favorisant l’accès
            de tou·te·s à <br></br>
            une alimentation de qualité tout en évitant que les denrées encore{" "}
            <br></br>
            cosommables partent à la poubelle.
          </h5>
        </div>
      </VisionContainer>

      <section className="giveatsengage container">
        <div className="row">
          <div className="col-sm-12 col-12">
            <h2>Giveats s’engage à</h2>
          </div>
          <div
            className="col-sm-4 col-12 givetext"
            style={{
              backgroundImage: `url(${textbg1})`,
            }}
          >
            <p>
              Respecter tes données <br></br>
              et ne jamais les revendre
            </p>
          </div>
          <div
            className="col-sm-4 col-12 givetext"
            style={{
              backgroundImage: `url(${textbg2})`,
            }}
          >
            <p>
              Utiliser 10% de nos gains annuel pour proposer des repas aux
              précaires via l’appli Takeats
            </p>
          </div>
          <div
            className="col-sm-4 col-12 givetext "
            style={{
              backgroundImage: `url(${textbg3})`,
            }}
          >
            <p>Toujours tenter de réduire son impact carbone</p>
          </div>
        </div>
      </section>

      <Rien className="mobilehide">
        <div className="subcribeboxbottom">
          <h2>
            RIEN NE CHANGE <br></br>
            POUR TOI, <br></br>
            TOUT CHANGE <br></br>
            POUR EUX
          </h2>
          <p>
            Avec Giveats prend part à une initiative <br></br>
            citoyenne et collaborative pour enrayer <br></br>
            l’insécurité alimentaire !
          </p>

          <BtnDownload />
        </div>
      </Rien>
      <ReflexDiv className="container">
        <div>
          <h2>
            PRENDS <span> LE REFLEXE GIVEATS</span>
          </h2>
          <p>
            C’est avec une communauté de foodies engagés que nous pourrons{" "}
            <br /> avoir un impact et des arguments pour impliquer les
            restaurants et <br />
            partenaires; Rejoignez nous !{" "}
          </p>
        </div>
        <div className="text-center">
          <ImgTabMobile src={IPhoneAir} alt="iphoneair" />
        </div>
        <br></br>
        <br></br>
        <Subscribetoemail2>
          <Input
            className="mb-3"
            type="email"
            name="subscribetoemail"
            placeholder="Laisse ton email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          ></Input>
          <Buttons type="submit">S’inscrire</Buttons>
        </Subscribetoemail2>
      </ReflexDiv>
      {/* <Parlentslider>
        <h2>
          Nos utilisateurs en <span>parlent mieux que nous !</span>
        </h2>
        <Carousel responsive={responsive2}>
          <div className="item">
            <div className="parlentsliderwrap">
              <p>
                “ Super appli, on peut se faire plaisir et en même temps
                contribuer au bien vivre de quelqu’un d’autre, c’est génial, je
                recommande ! ”
              </p>
              <small>-Sophie</small>
            </div>

            <img src={SophieIcon} alt="sophieicon" />
          </div>
          <div className="item">
            <div className="parlentsliderwrap parcolo1">
              <p>
                “ Super appli, on peut se faire plaisir et en même temps
                contribuer au bien vivre de quelqu’un d’autre, c’est génial, je
                recommande ! ”
              </p>
              <small>-Sophie</small>
            </div>

            <img src={SophieIcon} alt="sophieicon" />
          </div>
          <div className="item">
            <div className="parlentsliderwrap parcolo2">
              <p>
                “ Super appli, on peut se faire plaisir et en même temps
                contribuer au bien vivre de quelqu’un d’autre, c’est génial, je
                recommande ! ”
              </p>
              <small>-Sophie</small>
            </div>
            <img src={SophieIcon} alt="sophieicon" />
          </div>
        </Carousel>
      </Parlentslider> */}
    </Container2>
  );
}
