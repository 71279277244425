import React from "react";
import {
  TeamBody,
  VideoDiv,
  BannerDiv,
  AboutUsDiv,
  TitleH2,
  StoryTextDiv,
  TeamImg,
  Figure,
  TeamP,
  MissionDiv,
  MissionNumberDiv,
  ValueDiv,
  ValueTextDiv,
  ValueImg,
  FoundersDiv,
  FoundersContentDiv,
} from "./Team.styles";

import RomeoImg from "../../images/team1.png";
import TeamImage from "../../images/team2.png";
import Mission1 from "../../images/tousimg5.png";
import Mission2 from "../../images/tousimg6.png";
import Mission3 from "../../images/tousimg7.png";
import Mission4 from "../../images/tousimg8.png";
import EnthusiasmIcon from "../../images/valeursicon1.png";
import kindnessIcon from "../../images/valeursicon2.png";
import TenacityIcon from "../../images/valeursicon3.png";
import SolidarityIcon from "../../images/valeursicon4.png";
import AudacityIcon from "../../images/valeursicon5.png";
import SittingWoman from "../../images/valeursicon6.png";
import Romeo from "../../images/team3.png";
import Joseph from "../../images/team5.png";
export default function Team() {
  return (
    <TeamBody>
      <BannerDiv>
        <div className="col-sm-12 col-12">
          <h1>À propos de nous</h1>
        </div>
        <VideoDiv className="container">
          <div className="video-responsive">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/rC_XMW_UQdk"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              preload="none"
            ></iframe>
          </div>
        </VideoDiv>
      </BannerDiv>
      <AboutUsDiv>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <TitleH2>
                Notre <span>histoire</span>
              </TitleH2>
            </div>
            <StoryTextDiv className="col-lg-6 col-12">
              <p>
                Début 2019, Kermisson-Roméo BERNARD, le fondateur de Giveats a
                pris conscience d’une aberration qui l’a poussé à agir :
              </p>

              <p>
                Depuis, la crise sanitaire a profondément aggravé la situation
                augmentant le nombre de personnes en situation de vulnérabilité
                économique en France (à plus de 10 millions)
              </p>

              <p>
                Roméo et ses associés ont fondé Giveats en mai 2020. La startup
                commercialise deux applications mobiles qui ont vocation à
                développer l’activité des restaurants, éviter le gaspillage
                alimentaire qui en est issu et aider les personnes dans le
                besoin à obtenir des plats de restaurant gratuit pour se nourrir
                quotidiennement.
              </p>

              <p>
                Les cofondateurs se sont retrouvés autour d’une idée commune :
                le premier levier contre la précarité alimentaire devrait être
                les restaurants et les métiers de la bouche . Ils ont alors
                décidé d’unir leurs forces et de s’y consacrer pleinement. Leur
                solution est de connecter les foodies et les restaurants via une
                appli mobile afin de transformer les commandes de repas, les
                déjeuners, les livraisons de repas et réservations de table
                passés sur l’appli en dons alimentaires collaboratifs et
                quotidiens pour les précaires.
              </p>
              <p>
                Manger, donner, gagner et recommencer, voilà le mot d’ordre. En
                effet, l’appli génère des repas suspendus gratuits pour les
                personnes précaires à chaque fois qu’un foodie “givers” passe
                une commande sur l’appli. Ces repas géolocalisés sont
                récupérables par les précaires utilisant l’appli Takeats.
              </p>
              <p>
                Pour financer ces repas, la startup s’engage à utiliser 10% de
                ses bénéfices annuels et 100% des micro-dons que les
                utilisateurs de Giveats sont invités à laisser sur l’addition.
              </p>
              <p>
                L’appli Takeats est plus qu'une appli, c’est donner la
                possibilité à des milliers de personnes vulnérables de trouver
                de quoi manger en toute autonomie et quotidiennement . Surtout,
                l’alternative permet d’avoir une source de denrées diversifiées
                et riches dans les restaurants partenaires qui voudront bien
                jouer le jeu.
              </p>
              <p>
                Une société où la nourriture n’est plus gâchée et où tout le
                monde mange à sa faim est possible si tout le monde s’y met.
                Grâce à Giveats, le pouvoir de changer les choses devient très
                simple.{" "}
              </p>
            </StoryTextDiv>
            <div className="col-lg-6 col-12 teamimag text-center">
              <TeamImg src={RomeoImg} alt="romeoimg" className="imgromeo" />
            </div>
            <div className="col-12 ">
              <TitleH2 marginTop="200px" marginBot="20px">
                Notre <span>vision</span>
              </TitleH2>
              <TeamP className="text-center">
                <b>Notre vision</b> est d’agir contre la précarité alimentaire
                en favorisant <br /> l’accès de tou·te·s à une alimentation
                diversifiée et en évitant que les <br /> denrées encore
                consommables partent à la poubelle dans
                <br />
                les restaurants.
              </TeamP>
              <Figure>
                <TeamImg src={TeamImage} alt="teamimg" className="imgteam" />
              </Figure>
            </div>
            <div className="col-sm-12 col-12 ">
              <TitleH2 marginTop="200px" marginBot="40px">
                Notre <span>mission</span>
              </TitleH2>
              <TeamP className="text-center">
                <b>
                  Donner le pouvoir aux foodies et aux restaurants d’enrayer{" "}
                  <br /> l’insécurité alimentaire des plus démunis en <i>4</i>{" "}
                  actions :
                </b>
              </TeamP>
            </div>
            <div className="col-lg-6 col-12 text-center ">
              <MissionDiv>
                <MissionNumberDiv>1</MissionNumberDiv>
                <TeamImg src={Mission1} alt="mission1" />
                <p>
                  Inspirer et donner le pouvoir <br /> d’agir ensemble contre{" "}
                  <br /> l’insécurité alimentaire <br />
                  en France
                </p>
              </MissionDiv>
            </div>

            <div className="col-lg-6 col-12 text-center ">
              <MissionDiv>
                <MissionNumberDiv>2</MissionNumberDiv>
                <TeamImg src={Mission2} alt="mission2" />
                <p>
                  Rendre la digitalisation des <br /> restaurants éthique <br />
                  et solidaire
                </p>
              </MissionDiv>
            </div>

            <div className="col-lg-6 col-12 text-center ">
              <MissionDiv>
                <MissionNumberDiv>3</MissionNumberDiv>
                <TeamImg src={Mission3} alt="mission3" />
                <p>
                  Améliorer l’image de <br /> marque des restaurants <br />
                  partenaires par de la RSE <br /> et plus de sécurité sanitaire
                </p>
              </MissionDiv>
            </div>

            <div className="col-lg-6 col-12 text-center ">
              <MissionDiv>
                <MissionNumberDiv>4</MissionNumberDiv>
                <TeamImg src={Mission4} alt="mission4" />
                <p>
                  Doubler la fidélité de la <br /> clientèle consom’acteurs{" "}
                  <br />
                  et augmenter son chiffre <br /> d’affaire
                </p>
              </MissionDiv>
            </div>

            <div className="container">
              <ValueDiv className="row">
                <div className="col-sm-12 col-12">
                  <TitleH2 marginBot="40px" marginTop="200px">
                    Nos <span>valeurs</span>
                  </TitleH2>
                </div>

                <div className="col-sm-12 col-12">
                  <ValueTextDiv>
                    <h5>
                      <b>Enthousiasme </b>
                      <ValueImg
                        src={EnthusiasmIcon}
                        alt="enthusiasmicon"
                        marginTop="-30px"
                        marginLeft="-10px"
                      />
                    </h5>
                    <p>Nous diffusons de l’énergie positive</p>
                  </ValueTextDiv>
                </div>

                <div className="col-sm-12 col-12 text-end">
                  <ValueTextDiv paddingLeft="50px">
                    <h5>
                      <b>Bienveillance </b>
                      <ValueImg
                        src={kindnessIcon}
                        alt="kindnessicon"
                        marginTop="-5px"
                        marginLeft="5px"
                      />
                    </h5>
                    <p>
                      Nous croyons qu’une consommation <br />
                      plus responsable peu faire bouger la société et sauver la{" "}
                      <br /> planète.{" "}
                    </p>
                  </ValueTextDiv>
                </div>

                <div className="col-sm-12 col-12">
                  <ValueTextDiv paddingLeft="50px">
                    <h5>
                      <b>Ténacité</b>
                      <ValueImg src={TenacityIcon} alt="tenacityicon" />
                    </h5>
                    <p>
                      Nous voulons rendre notre <br /> impact durable
                    </p>
                  </ValueTextDiv>
                </div>

                <div className="col-sm-9 col-12 text-end">
                  <ValueTextDiv>
                    <h5>
                      <b>Solidarité</b>
                      <ValueImg
                        src={SolidarityIcon}
                        alt="solidarityicon"
                        marginLeft="2px"
                      />
                    </h5>
                    <p>
                      Nous prônons l’entraide comme a <br /> voie de la richesse
                      intérieure
                    </p>
                  </ValueTextDiv>
                </div>

                <div className="col-sm-12 col-12">
                  <ValueTextDiv>
                    <h5>
                      <b> Audace </b>
                      <ValueImg
                        src={AudacityIcon}
                        alt="audacityicon"
                        marginTop="-6px"
                      />
                    </h5>
                    <p>Nous voulons changer le monde à notre échelle</p>
                  </ValueTextDiv>
                </div>

                <div className="col-sm-12 col-12 text-end">
                  <img
                    src={SittingWoman}
                    alt="sittingwoman"
                    className="imgsittingwoman"
                  />
                </div>
              </ValueDiv>
            </div>
          </div>
        </div>
      </AboutUsDiv>
      <FoundersDiv className="container">
        <div className="row">
          <div className="col-sm-12 col-12">
            <TitleH2 marginBot="50px">
              Les <span>co-fondateurs</span>
            </TitleH2>
          </div>
          <FoundersContentDiv className="col-lg-6 col-12">
            <img src={Romeo} alt="foundersromeo" />
            <h4>Kermison-Roméo BERNARD, CEO</h4>
            <p className="mobilehide">
              « J’ai lancé ce projet avec la volonté de donner à chacun la
              possibilité d’agir à son échelle. Il s’agit de donner à manger à
              ceux qui sont dans le besoin, tout en évitant des tonnes de
              gaspillage chaque année dans les restos. La crise sanitaire donne
              l’opportunité de repenser nos modèles. »{" "}
            </p>
          </FoundersContentDiv>

          <FoundersContentDiv className="col-lg-6 col-12 ">
            <img src={Joseph} alt="foundersjoseph" />
            <h4>Joseph Pijeaud, CFO</h4>
            <p className="mobilehide">
              « C’est un plaisir de prendre part à un monde où on peut allier
              modèle économique et intérêt socio-environnemental. Giveats c’est
              l’occasion de manger au restaurant pour la bonne cause. »
            </p>
          </FoundersContentDiv>
        </div>
      </FoundersDiv>
      {/* <RestOfTeamDiv className="container">
        <div className="row">
          <div className="col-sm-12 col-12">
            <TitleH2 marginBot="50px">Le reste de l’équipe </TitleH2>
          </div>
          <RestOfTeamContentDiv className="col-lg-6 col-12">
            <img src={Emma} alt="emmateam" />
            <h4>Emma Armand, Chargée de partenariats</h4>
            <p className="mobilehide">
              « L'aspect solidaire a justifié mon engagement dans Giveats.
              Établir des partenariats avec les associations luttant contre la
              précarité permet concrètement de faire changer les choses en
              donnant la possibilité à des personnes en situation de précarité
              de bénéficier de repas gratuits.»{" "}
            </p>
          </RestOfTeamContentDiv>

          <RestOfTeamContentDiv className="col-lg-6 col-12">
            <img src={Nastasia} alt="nastasiateam" />

            <h4>Nastasia Dottel, Créatice de contenu</h4>
            <p className="mobilehide">
              « Après des études en graphisme j’ai décidé de me tourner vers
              Giveats pour poursuivre en alternance car la cause me touche et je
              suis fière de pouvoir aider à travers mon métier »
            </p>
          </RestOfTeamContentDiv>
        </div>
      </RestOfTeamDiv> */}
    </TeamBody>
  );
}
