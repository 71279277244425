import React, { useState } from "react";
import apiSendinblue from "../../conf/api.sendinblue";
import "react-multi-carousel/lib/styles.css";
import { InputForm, Userinnerwrap, Section } from "./Form.styles.js";
import Usericon from "../../images/usericon.png";

export default function Assopage(props) {
  const initialcreateContactState = {
    email: "",
    attributes: {
      numero: "",
      prenom: "",
      nom: "",
      nom_organisation: "",
      type_organisation: "",
      code_postal: "",
      pays: "",
      nombre_etablissement: "",
    },
    listIds: [16],
    emailBlacklisted: false,
    numeroBlacklisted: false,
    updateEnabled: false,
  };
  const [createContact, setcreateContact] = useState(initialcreateContactState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChanges = (level) => (e) => {
    if (!level) {
      // Assume root level
      setcreateContact({
        ...createContact,
        [e.target.name]: e.target.value,
      });
    } else {
      setcreateContact({
        ...createContact,
        [level]: {
          ...createContact[level],
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const saveUser = () => {
    var data = {
      email: createContact.email,
      attributes: {
        numero: createContact.attributes.numero,
        prenom: createContact.attributes.prenom,
        nom: createContact.attributes.nom,
        nom_organisation: createContact.attributes.nom_organisation,
        type_organisation: createContact.attributes.type_organisation,
        ville: createContact.attributes.ville,
        code_postal: createContact.attributes.code_postal,
        pays: createContact.attributes.pays,
        nombre_etablissement: createContact.attributes.nombre_etablissement,
      },
      listIds: [11],
    };
    console.log(data);
    apiSendinblue
      .post("contacts", data)
      .then((response) => {
        setcreateContact({
          email: response.data.email,
          attributes: {
            numero: response.data.attributes.numero,
            nom: response.data.attributes.nom,
            prenom: response.data.attributes.prenom,
            nom_organisation: response.data.attributes.nom_organisation,
            type_organisation: response.data.attributes.type_organisation,
            ville: response.data.attributes.ville,
            code_postal: response.data.attributes.code_postal,
            pays: response.data.attributes.pays,
            nombre_etablissement: response.data.attributes.nombre_etablissement,
          },
        });
        setSubmitted(true);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Section>
      <div class="container">
        <div class="col-12 col-sm-12 text-center">
          <h2>
            Bienvenue !
            <img
              src={Usericon}
              alt="store"
              style={{
                marginTop: "-40px",
              }}
            />
          </h2>
        </div>
        {submitted === false ? (
          <InputForm>
            <div class="row">
              <div class="col-12 col-lg-6 text-center">
                <Userinnerwrap>
                  <label>Nom de l’organisation*</label>
                  <input
                    type="text"
                    name="nom_organisation"
                    value={createContact.attributes.nom_organisation}
                    onChange={handleInputChanges("attributes")}
                    placeholder="Cap Tiedo"
                  ></input>
                  <label>Type d’organisation*</label>

                  <div class="row">
                    <div class="col-sm-12 col-12">
                      <select
                        class="form-control menudropdown commandsdropdown"
                        id="commands"
                        name="TYPE_ASSOCIATION"
                      >
                        <option value="Select">Select</option>
                        <option value="Association">Association</option>
                        <option value="ONG">ONG</option>
                        <option value="Service Sicial DE LA Ville">
                          Service Sicial DE LA Ville
                        </option>
                        <option value="Autre">Autre</option>
                      </select>
                    </div>
                  </div>
                  <label>Code postal de l’organisation*</label>
                  <input
                    type="text"
                    name="code_postal"
                    value={createContact.attributes.code_postal}
                    onChange={handleInputChanges("attributes")}
                    placeholder="75005"
                  ></input>
                  <label>Nombre d’établissement</label>
                  <input
                    type="number"
                    name="nombre_etablissement"
                    value={createContact.attributes.nombre_etablissement}
                    onChange={handleInputChanges("attributes")}
                    placeholder="2"
                    min="1"
                  ></input>
                  <label>Pays*</label>
                  <div class="row">
                    <div class="col-sm-12 col-12">
                      <select
                        class="form-control menudropdown commandsdropdown"
                        id="commands"
                        name="PAYS"
                      >
                        <option value="France">France</option>
                      </select>
                    </div>
                  </div>
                </Userinnerwrap>
              </div>
              <div class="col-12 col-lg-6 text-center">
                <Userinnerwrap>
                  <label>Nom*</label>
                  <input
                    type="text"
                    name="nom"
                    value={createContact.attributes.nom}
                    onChange={handleInputChanges("attributes")}
                    placeholder="Doe"
                  ></input>
                  <label>Prénom*</label>
                  <input
                    type="text"
                    name="prenom"
                    value={createContact.attributes.prenom}
                    onChange={handleInputChanges("attributes")}
                    placeholder="John"
                  ></input>
                  <label>Adresse email*</label>
                  <input
                    type="email"
                    name="email"
                    value={createContact.email}
                    onChange={handleInputChanges()}
                    placeholder="ex : johndoe@email.com"
                  ></input>{" "}
                  <label>N° téléphone*</label>
                  <div class="row">
                    <div class="col-12 col-sm-12">
                      <div class="input-phone"></div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1 col-2">
                        <input type="checkbox" name="agree"></input>
                      </div>
                      <div class="col-sm-11 col-10 checkboxtext">
                        <p>
                          M’envoyer le dernier article de blog et souscrire à la
                          newsletter de Giveats
                        </p>
                      </div>
                    </div>
                  </div>
                  <button onClick={saveUser} class="submitbutton">
                    C’est parti !{" "}
                  </button>
                </Userinnerwrap>
              </div>
            </div>
          </InputForm>
        ) : (
          <h1> Submitted</h1>
        )}
      </div>
    </Section>
  );
}
