import styled from "styled-components";
import BgBlog from "../../images/bg_blog.png";

export const BlogBody = styled.div`
  background-image: url(${BgBlog});
  background-repeat: no-repeat;
`;
export const BlogContainer = styled.div`
  box-sizing: border-box;
  padding-bottom: 350px;
`;

export const Container = styled.div`
  text-align: center;
`;

export const TitleBlog = styled.div`
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;

  h1 {
    font-weight: 700;
  }
`;

export const Card = styled.div`
  padding: 0;
  margin: 10px;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }

  .card-header {
    padding: 0;
  }
  .card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 200px;
  }

  .tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .tag-teal {
    background-color: #47bcd4;
  }
  .tag-purple {
    background-color: #5e76bf;
  }
  .tag-pink {
    background-color: #cd5b9f;
  }

  .card-text h4 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    padding-top: 15px;
  }
  .user {
    display: flex;
    margin-top: auto;
  }

  .user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info h5 {
    margin: 0;
  }
  .user-info small {
    color: #545d7a;
  }
`;

export const Post = styled.div`
  margin-top: 180px;

  .img {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
  }

  .text-content {
    padding: 2rem;
  }

  .title {
    margin-bottom: 25px;
    color: black;
    display: block;
    font-size: 50px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 900;
  }

  .category-blog {
    margin-bottom: 35px;
    p {
      font-size: 1.2rem;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      float: left;
    }

    .user-details {
      p {
        margin-bottom: 0px;
      }
    }
  }

  .share {
    margin: auto;

    > button {
      margin-left: 1rem;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 150px;
`;

export const CategoryContainer = styled.div``;

export const TitleCat = styled.div`
  text-align: center;
  padding-top: 150px;
  margin-bottom: 80px;

  h2 {
    font-weight: 700;
  }
`;

export const CategoryCard = styled.div`
  margin-bottom: 35px;
  .category {
    cursor: pointer;
    height: 65px;
    display: flex;
    border-radius: 25px;
    align-items: center;
    font-size: 22px;
    background-color: #ff9239 !important;
    box-shadow: 0 12px 30px 0 hsla(0, 0%, 83.1%, 0.5) !important;
    transition: all 0.5s ease-in;
  }
  small {
    padding-left: 55px;
  }
`;

export const Search = styled.div`
  margin-bottom: 50px;
  .tb {
    display: table;
    width: 100%;
  }

  .td {
    display: table-cell;
    vertical-align: middle;
  }

  input,
  button {
    color: #fff;
    font-family: Nunito;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  #cover {
    position: relative;
    top: 100px;
    left: 0;
    right: 0;
    width: 550px;
    padding: 35px;
    margin: -83px auto 0 auto;
    background-color: #fd8368;
    border-radius: 20px;
    box-shadow: 0 10px 40px #fdb568, 0 0 0 20px #fd9a68;
    transform: scale(0.6);
  }

  form {
    height: 96px;
  }

  input[type="text"] {
    width: 100%;
    height: 96px;
    font-size: 60px;
    line-height: 1;
  }

  input[type="text"]::placeholder {
    color: #e16868;
  }

  #s-cover {
    width: 1px;
    padding-left: 35px;
  }

  button {
    position: relative;
    display: block;
    width: 84px;
    height: 96px;
    cursor: pointer;
  }

  #s-circle {
    position: relative;
    top: -8px;
    left: 0;
    width: 63px;
    height: 63px;
    margin-top: 0;
    border-width: 15px;
    border: 15px solid #fff;
    background-color: transparent;
    border-radius: 50%;
    transition: 0.5s ease all;
  }

  button span {
    position: absolute;
    top: 68px;
    left: 43px;
    display: block;
    width: 45px;
    height: 15px;
    background-color: transparent;
    border-radius: 10px;
    transform: rotateZ(52deg);
    transition: 0.5s ease all;
  }

  button span:before,
  button span:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45px;
    height: 15px;
    background-color: #fff;
    border-radius: 10px;
    transform: rotateZ(0);
    transition: 0.5s ease all;
  }

  #s-cover:hover #s-circle {
    top: -1px;
    width: 67px;
    height: 15px;
    border-width: 0;
    background-color: #fff;
    border-radius: 20px;
  }

  #s-cover:hover span {
    top: 50%;
    left: 56px;
    width: 25px;
    margin-top: -9px;
    transform: rotateZ(0);
  }

  #s-cover:hover button span:before {
    bottom: 11px;
    transform: rotateZ(52deg);
  }

  #s-cover:hover button span:after {
    bottom: -11px;
    transform: rotateZ(-52deg);
  }
  #s-cover:hover button span:before,
  #s-cover:hover button span:after {
    right: -6px;
    width: 40px;
    background-color: #fff;
  }
`;
