import { createGlobalStyle } from "styled-components";
import { device } from "./Device";

const GlobalStyle = createGlobalStyle`
  * {
  outline: none;
  }
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6, p, a, b {
    font-family: Nunito
  }

  h1 {
    
    @media only screen and ${device.mobileL} {
      font-size: 35px;
    }
    
    @media only screen and ${device.tablet} {
      font-size: 52px;
    }

    @media only screen and ${device.laptop} {
      font-size: 72px;
    }
  }

  h2 {
    @media only screen and ${device.mobileL} {
      font-size: 25px;
    }
    
    @media only screen and ${device.tablet} {
      font-size: 38px;
    }

    @media only screen and ${device.laptop} {
      font-size: 55px;
    }
  }

  h3 {
    @media only screen and ${device.mobileL} {
      font-size: 22px;
    }
    
    @media only screen and ${device.tablet} {
      font-size: 32px;
    }

    @media only screen and ${device.laptop} {
      font-size: 48px;
    }
  }

  h4 {
    
    @media only screen and ${device.mobileL} {
      font-size: 18px;
    }
    
    @media only screen and ${device.tablet} {
      font-size: 22px;
    }

    @media only screen and ${device.laptop} {
      font-size: 28px;
    }
  }

  h5 {
    
    @media only screen and ${device.mobileL} {
      font-size: 18px;
    }
    
    @media only screen and ${device.tablet} {
      font-size: 20px;
    }

    @media only screen and ${device.laptop} {
      font-size: 22px;
    }
  }

  h6 {

  }

  a {
    text-decoration:none
  }


  .navbar-active {
    background-color: #FFF;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
  }

  .btn-download{
  background: #c988ff;
  width: 240px;
  height: 45px;
  border-radius: 24px;
  border-style: none;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
  text-decoration: none;

  &:hover {
    background: #ff9239;
    color: #ffffff;
  }

  @media only screen and ${device.mobileL} {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  @media only screen and ${device.tablet} {
    margin-right: 15px;
    margin-bottom: 0px;
  }

  @media only screen and ${device.laptop} {
    margin-right: 15px;
    margin-bottom: 0px;
  }
}

  @media only screen and ${device.mobileL} {
    .mobilehide{
      display: none;
    }

    .phonehide{
      display: none;
    }

    .tablethide{
      display: block;
    }
    .mobileblack{
      color: black;
    }
    .desktophide {
      display: block;
    }
  }
  @media only screen and ${device.tablet} {
    .mobilehide{
      display: none;
    }
    .phonehide{
      display: block;
    }
    .mobileblack{
      color: black;
    }
    .tablethide{
      display: none;
    }
    .desktophide {
      display: block;
    }
  }

  
  @media only screen and ${device.laptop} {
    .mobilehide{
      display: block;
    }
    .phonehide{
      display: block;
    }
    .mobileblack{
      color: white;
    }
    .tablethide{
      display: none;
    }
    .desktophide {
      display: none;
    }
  }
`;

export default GlobalStyle;
