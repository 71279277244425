import React from "react";
import {
  Banner,
  TitleBannerDiv,
  CommissionDiv,
  TitleCommissionDiv,
  PackageDiv,
  StarterPackDiv,
  MemberFeesDiv,
  FeesTable,
  TdBlackWhite,
} from "./Price.styles";
import Close from "../../images/close.png";

export default function Price() {
  const bgGradient2 =
    "linear-gradient(172.99deg, #C988FF -22.73%, rgba(255, 255, 255, 0) 84.69%), #700CBF";
  const bgGradient3 =
    "linear-gradient(175.33deg, #FFAF3B -11.21%, rgba(255, 255, 255, 0) 69.19%), #FF9239";

  return (
    <>
      <Banner>
        <div className="container">
          <TitleBannerDiv className="col-sm-6">
            <h1>NOS TARIFS</h1>
          </TitleBannerDiv>
        </div>
      </Banner>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 342.318 1452 373.682"
        enable-background="new 0 342.318 1452 373.682"
        xmlSpace="preserve"
      >
        <rect
          x="-27.756"
          y="342.318"
          fill="#FFFFFF"
          stroke="#000000"
          stroke-miterlimit="10"
          width="1548.155"
          height="537.054"
        ></rect>
        <path
          fill="#FF9239"
          d="M669.462,550.5c-289,240.4-567.058,165.5-669.961,98V0h726.25H1452v385
C1311.573,340,958.46,310.1,669.462,550.5z"
        ></path>
      </svg>
      <CommissionDiv className="container">
        <div className="row">
          <TitleCommissionDiv className="col-12">
            <h2>Commission sur l’addition</h2>
          </TitleCommissionDiv>
        </div>

        <div className="row ">
          <div className="col-md-4 col-12 package-wrapper">
            <PackageDiv>
              <h4>Pack Boulangeries </h4>
              <span>Panier moyen de la carte inférieur ou égale à 7€</span>
              <ul>
                <li>Livraison à 25% HT</li>
                <li>Click&amp;collect 12 % HT</li>
              </ul>
            </PackageDiv>
          </div>
          <div className="col-md-4 col-12 package-wrapper">
            <PackageDiv backgroundColor={bgGradient2} minHeight="570px">
              <h4>Pack Bistronomie </h4>
              <span> Panier moyen de la carte entre 7€ et 25 </span>
              <ul>
                <li>Livraison à 25% HT</li>
                <li>Réservation de table avec précommande 1,50€ HT / table </li>

                <li>Click&amp;collect 13% HT</li>
              </ul>
            </PackageDiv>
          </div>
          <div className="col-md-4 col-12 package-wrapper">
            <PackageDiv backgroundColor={bgGradient3} minHeight="620px">
              <h4>Pack Gastronomie</h4>
              <span> Panier moyen de la carte supérieur à 25€ </span>
              <ul>
                <li>Livraison à 25% HT</li>
                <li>Réservation de table avec précommande 2€ HT / table</li>

                <li>Click&amp;collect 13% HT</li>
              </ul>
            </PackageDiv>
          </div>
          <StarterPackDiv className="col-md-12 col-12">
            <h3>Pack de démarrage </h3>
            <ul>
              <li>Une tablette (optionnelle)</li>
              <li>
                Votre packaging (sacs kraft biodégradables personnalisés, <br />
                serviettes, couverts biodégradables) (optionnel)
              </li>
              <li>Flyers</li>
              <li>Stickers</li>
              <li>QR Code (optionnel)</li>
              <li>Photos du menu (shooting photographe pro - optionnel)</li>
            </ul>
          </StarterPackDiv>

          <MemberFeesDiv className="col-md-12 col-12">
            <h3>Frais d’adhésion</h3>
            <FeesTable>
              <tbody>
                <tr>
                  <th></th>
                  <th>Obligatoire</th>
                  <th>Optionnel</th>
                </tr>

                <tr>
                  <td>
                    Frais de dossier <br />
                    annuels
                  </td>
                  <TdBlackWhite>
                    <b>200€</b>
                  </TdBlackWhite>
                  <TdBlackWhite>
                    <img src={Close} alt="close" />
                  </TdBlackWhite>
                </tr>

                <tr>
                  <td>Frais de packaging</td>
                  <TdBlackWhite>
                    <b>Sur mesure</b>
                  </TdBlackWhite>
                  <TdBlackWhite>
                    <img src={Close} alt="close" />
                  </TdBlackWhite>
                </tr>

                <tr>
                  <td>Frais de photos</td>
                  <TdBlackWhite>
                    <img src={Close} alt="close" />
                  </TdBlackWhite>
                  <TdBlackWhite>
                    <b>Environ 250€</b>
                  </TdBlackWhite>
                </tr>

                <tr>
                  <td>Frais QR Code</td>
                  <TdBlackWhite>
                    <img src={Close} alt="close" />
                  </TdBlackWhite>
                  <TdBlackWhite>
                    <b>
                      Montant fixe <br />à déterminer
                    </b>
                  </TdBlackWhite>
                </tr>
              </tbody>
            </FeesTable>
          </MemberFeesDiv>
        </div>
      </CommissionDiv>
    </>
  );
}
