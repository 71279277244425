import styled from "styled-components";
import { device } from "../../theme/Device";

export const Banner = styled.div`
  width: 100%;
  height: auto;
  background: #ff9239;
  color: #fff;
  padding: 162px 0px 0px 0px;
  line-height: 0px;
  min-height: 350px;
  font-family: Nunito;
`;

export const TitleBannerDiv = styled.div`
  h1 {
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
    color: #ffffff;
  }
`;

export const TextContainer = styled.div`
  padding-top: 50px;
  h3 {
    margin-top: 55px;
    margin-bottom: 15px;
  }
  @media only screen and ${device.mobileL} {
    p {
      font-size: 16px;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      font-size: 20px;
    }
  }
`;
