import apiAdmin from "../conf/api.admin";

const getAll = () => {
  return apiAdmin.get("/category");
};
const get = (id) => {
  return apiAdmin.get(`/category/${id}`);
};
const CategoryService = {
  getAll,
  get,
};

export default CategoryService;
