import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import HomepageView from "./components/Homepage/Homepage";
import TarifsView from "./components/Price/Price";
import PressView from "./components/Press/Press";
import CharityView from "./components/Charity/Charity";
import TeamView from "./components/Team/Team";
import BlogView from "./components/Blog/Blog";
import RestaurantView from "./components/Restaurants/index";
import TakeatsView from "./components/Takeats/index";
import Footer from "./components/Footer/Footer";
import GlobalStyle from "./theme/GlobalStyles";
import FormRestoView from "./components/Form/RestaurantForm";
import FormAssoView from "./components/Form/AssoForm";
import FormUserView from "./components/Form/UserForm";
import FormBenefView from "./components/Form/BenefForm";
import Mentions from "./pages/legales/Mentions";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import UseOfCookies from "./pages/use-of-cookies/UseOfCookies";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import Blog from "./components/Blog/index";
// import Maintenance from "./components/maintenance/Maintenance";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import { useDispatch, useSelector } from "react-redux";
//import { translatione } from "./I18n/i18n";

export default function App(props) {
  // const lang = useSelector((state) => state.languageReducer.language);
  // const dispatch = useDispatch();

  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
      <Navbar />
      <Switch>
        {/*Route to add for maintenance */}
        {/* <Route path="*" component={Maintenance} exact /> */}

        {/* <Route path="/" component={Popup} exact /> */}
        <Route path="/" component={HomepageView} exact />
        <Route path="/tarif" component={TarifsView} exact />

        <Route path="/press" component={PressView} exact />
        <Route path="/charity" component={CharityView} exact />
        <Route path="/team" component={TeamView} exact />
        <Route path="/blog" component={BlogView} exact />
        <Route path="/restaurant" component={RestaurantView} exact />
        <Route path="/takeats" component={TakeatsView} exact />
        <Route path="/restaurant-form" component={FormRestoView} exact />
        <Route path="/association-form" component={FormAssoView} exact />
        <Route path="/userform" component={FormUserView} exact />
        <Route path="/beneficiary-form" component={FormBenefView} exact />
        <Route path="/blog/:id" component={Blog} exact />

        {/*Terms and Legales routes */}
        <Route path="/mentions-legales" component={Mentions} exact />
        <Route path="/terms-of-use" component={TermsOfUse} exact />
        <Route path="/use-of-cookies" component={UseOfCookies} exact />
        <Route path="/privacy-policy" component={PrivacyPolicy} exact />

        {/* <Route
          path="/solution/clickcollect"
          component={ClickCollectView}
          exact
        />
        <Route path="/solution/delivery" component={DeliveryView} exact />
        <Route path="/solution/payment" component={PaymentView} exact />
        <Route path="/solution/tableorder" component={TableOrderView} exact />
        <Route
          path="/solution/tablereservation"
          component={TableReservationPreOrderView}
          exact
        />
        <Route
          path="/solution/takeats"
          component={TakeatsFunctionnalityView}
          exact
        /> */}
        <Redirect to="/" />
      </Switch>
      <Footer />

      {/* <div className="App">
        <div className="box_btn">
          <button
            className="btn btn-primary"
            onClick={() => dispatch({ type: "fr" })}
          >
            francais
          </button>
          <button
            className="btn btn-danger ml-10"
            onClick={() => dispatch({ type: "en" })}
          >
            anglais
          </button>
          <button
            className="btn btn-success ml-10"
            onClick={() => dispatch({ type: "es" })}
          >
            Espagnol
          </button>
        </div>
        <div className="titre">
          <h1>{translatione(lang, "titre")}</h1>
        </div>
      </div> */}
    </Router>
  );
}
