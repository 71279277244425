import React from "react";
import { useState } from "react";
import { DivPopup, Register, ImgClose } from "./Popup.styles";
import Close from "../../images/close.png";

export default function Popup(props) {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <DivPopup
      style={show === props.hide ? {} : { display: "none" }}
      className="text-center"
    >
      <ImgClose onClick={handleShow} src={Close} alt="close" />
      <h5>Oups !</h5>
      <p>L'appli est en cours de lancement.</p>
      <p>
        Vous pouvez vous inscrire à l'avance pour être informé de son lancement
        à Paris.
      </p>
      <p>Retrouvez Giveats sur appstore et Google store prochainement.</p>
      <Register to="/userform">S'inscrire</Register>
    </DivPopup>
  );
}
