import React from "react";
import "bootstrap";
import {
  BannerStyle,
  Title,
  TextDiv,
  ImageBanner,
  DivImage,
  SVG,
  ImageBannerPhone,
} from "./Banner.styles";
import BtnRestaurant from "../../buttons/BtnRestaurant";
import BtnDemo from "../../buttons/BtnDemo";
import Commercant from "../../../images/commercant.png";
import CommercantPhone from "../../../images/img_banner_restaurant.png";
import Svg from "../../../images/shape.svg";

export default function Banner() {
  return (
    <>
      <BannerStyle>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <Title>
                Restaurants : développez votre activité{" "}
                <br className="mobilehide" /> avec un marketplace éthique et
                solidaire
              </Title>
            </div>
            <TextDiv className="col-11 col-md-8">
              <p>
                <b>Fidélisez vos clients via une démarche engagée !</b> <br />{" "}
                <i>
                  La première marketplace qui digitalise tous vos canaux de
                  vente avec une solution tout-en-un et sauve vos excédents
                  alimentaires pour les offrir aux personnes en situation de
                  précarité.
                  <br />
                  <br /> Pour une société Zéro faim et Zéro gâchis !
                </i>
              </p>
              <div className="buttondiv">
                <span className="je">
                  <BtnRestaurant mRgTab="40px" mRgLaptop="40px" />
                </span>
                <span className="pre">
                  <BtnDemo />
                </span>
              </div>
              <p className="date2022">Début de beta test courant 2022</p>
            </TextDiv>
            <DivImage>
              <ImageBanner
                className="phonehide"
                alt="Commercant"
                src={Commercant}
              />
              <ImageBannerPhone
                className="tablethide"
                alt="commercant"
                src={CommercantPhone}
              />
            </DivImage>
          </div>
        </div>
      </BannerStyle>
      <SVG>
        <img src={Svg} alt="" />
      </SVG>
    </>
  );
}
