import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";

export const BecomeDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  padding-top: 120px;
  background: linear-gradient(
      143.16deg,
      rgba(255, 146, 57, 0.5) -8.14%,
      rgba(255, 255, 255, 0) 84.72%
    ),
    rgba(112, 12, 191, 0.9);
  position: relative;

  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  @media only screen and ${device.mobileL} {
    padding-bottom: 100px;
  }

  @media only screen and ${device.tablet} {
    padding-bottom: 200px;
  }

  @media only screen and ${device.laptop} {
    padding-bottom: 310px;
  }
`;

export const Ul = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;

  li {
    margin-bottom: 27px;
    width: 100%;
    display: inline-block;

    span {
      width: 55px;
      height: 55px;
      border-radius: 50px;
      display: inline-block;
      background: #ffffff;
      text-align: center;
      box-shadow: 0px 4px 9px #630aaa;
      font-weight: bold;
      font-size: 35px;
      float: left;
      margin-right: 20px;
      color: #700cbf;
    }

    p {
      font-family: Nunito;
      width: 80%;
      margin-bottom: 0px;
      float: left;
      line-height: 28px;
      color: #ffffff;
    }
  }

  @media only screen and ${device.mobileL} {
    p {
      font-size: 16px;
    }
    li span {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 25px;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      font-size: 20px;
    }
    li span {
      width: 55px;
      height: 55px;
      line-height: 55px;
      font-size: 35px;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      font-size: 23px;
    }
    li span {
      width: 55px;
      height: 55px;
      line-height: 55px;
      font-size: 35px;
    }
  }
`;

export const ImageDiv = styled.div`
  width: 560px;
  height: auto;
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 210px;

  img {
    width: 100%;
  }
`;

export const ThreeStepDiv = styled.div`
  width: 100%;
  height: auto;

  @media only screen and ${device.mobileL} {
    margin-top: 95px;
    padding: 0px 0px;
  }

  @media only screen and ${device.tablet} {
    margin-top: 153px;
    padding: 0px 50px;
  }

  @media only screen and ${device.laptop} {
    margin-top: 153px;
    padding: 0px 50px;
  }
`;

export const TitleStepDiv = styled.div`
  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }
  span {
    color: #ff9239;
  }
`;

export const StepCard = styled.div`
  text-align: center;
  margin-bottom: 35px;

  h5 {
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    margin-top: 20px;
    color: #000000;

    b {
      font-size: 40px;
      margin-bottom: 19px;
      display: inline-block;
    }
  }

  img {
    vertical-align: middle;
    border-style: none;
  }
`;

export const DivBtnStepDiv = styled.div`
  text-align: center;
  @media only screen and ${device.mobileL} {
    margin-top: 26px;
  }

  @media only screen and ${device.tablet} {
    margin-top: 100px;
  }

  @media only screen and ${device.laptop} {
    margin-top: 100px;
  }
`;

export const MerchantDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 153px;
  @media only screen and ${device.mobileL} {
    padding: 0px 0px;
  }

  @media only screen and ${device.tablet} {
    padding: 0px 50px;
  }

  @media only screen and ${device.laptop} {
    padding: 0px 50px;
  }
`;

export const TitleMerchantDiv = styled.div`
  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;

    span {
      color: #ff9239;
    }
  }
`;

export const ImageMerchantDiv = styled.div`
  @media only screen and ${device.mobileL} {
    padding: 0px 0px;
  }

  @media only screen and ${device.tablet} {
    padding: 0px 0px;
  }

  @media only screen and ${device.laptop} {
    padding: 0px 200px;
  }
`;

export const MerchantCard = styled.div`
  margin-bottom: 30px;
  text-align: center;

  h5 {
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    margin-top: 20px;
    color: #000000;
  }
  @media only screen and ${device.mobileL} {
    img {
      width: 100px;
    }
  }

  @media only screen and ${device.tablet} {
    img {
      width: 150px;
    }
  }

  @media only screen and ${device.laptop} {
    img {
      width: 200px;
    }
  }
`;
