import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Row,
  Source,
  Banner,
  BannerImg,
  Title,
  DivBanner,
  ContainerBanner,
  P1,
  Caption,
  CaptionImg,
  CaptionH4,
  Statistical,
  H2title,
  Listing,
  Purple,
  Yellow,
  Pink,
  Orange,
  Footsmall,
  Hungry,
  HungryImg,
  HungryH2,
  HungryP,
  Precariousness,
  PrecariousnessSVG,
  PrecariousnessH4,
  PrecariousnessImg,
  TwoProblem,
  TwoProblemH2,
  TwoProblemLeft,
  TwoProblemH3Left,
  TwoProblemPLeft,
  TwoProblemRight,
  TwoProblemH3Right,
  TwoProblemPRight,
  SolutionDiv,
  SolutionH3,
  SolutionP,
  SolutionImg,
  Know,
  KnowTitle,
  KnowP,
  KnowSpan,
  KnowText1,
  KnowText1H2,
  KnowText1B,
  KnowText2,
  KnowText2H2,
  KnowText2B,
  KnowSmall,
  Sanitary,
  SanitaryH2,
  SanitarySpan,
  SanitaryBlock,
  SanitarySmall,
  SanitarySecondH2,
  SanitarySecondSpan,
  SanitaryThirdH2,
  SanitaryThirdSpan,
  SanitaryProgressbar,
  SanitaryImg,
  SanitaryImgB,
  Testimony,
  TestimonyP,
  TestimonySmall,
  Major,
  Majbox,
  MajorH3,
  MajorP,
  Together,
  TogetherSpan,
  TogetherA,
  TogetherP,
  TogetherB,
  SanitaryPic,
  SanitaryProdDiv,
  SanitaryPici2,
  SanitaryProgBlock,
  TestimonyQuote,
  ImgTogether,
} from "./Charity.styles";

//import the background img
import bgmic1 from "../../images/bgmic1.png";
import bgmic2 from "../../images/bgmic2.png";
import ceo from "../../images/ceo.png";
import bgline from "../../images/bgline.png";
import Vector172 from "../../images/Vector-172.png";
import Vector173 from "../../images/Vector-173.png";
import Vector174 from "../../images/Vector-174.png";
import Vector175 from "../../images/Vector-175.png";
import Vector176 from "../../images/Vector-176.png";
import bgcharity from "../../images/bgcharity.png";

//end of import background img

import caretivebannner from "../../images/caretivebannner.png";
import bread from "../../images/tousimg9.png";
import waste from "../../images/tousimg10.png";
import hungry from "../../images/faimimage.png";
import precariousness from "../../images/lesimage.png";
import mobile1 from "../../images/mobile1.png";
import mobile2 from "../../images/mobile2.png";
import progressimg1 from "../../images/progressimg1.png";
import progressimg2 from "../../images/progressimg2.png";
import progressimg3 from "../../images/progressimg3.png";
import progressimg4 from "../../images/progressimg4.png";
import testimoniacomsleft from "../../images/testimoniacomsleft.png";
import testimoniacomsright from "../../images/testimoniacomsright.png";
import fullmobile from "../../images/caritative.png";
import ensembleimg from "../../images/ensembleimg.png";
import SanitaryPic1 from "../../images/sanitaryimg1.png";
import SanitaryPic2 from "../../images/sanitaryimg2.png";

export default function Charity() {
  return (
    <>
      {/* first section  */}
      <Banner>
        <BannerImg>
          <img src={caretivebannner} alt="caretivebannner" />
        </BannerImg>
        <ContainerBanner>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1440 207.788"
            enable-background="new 0 0 1440 207.788"
            xmlSpace="preserve"
          >
            <path
              fill="#700CBF"
              d="M364.627,0.2C218.577,4.106,5.503,174.016-4,198.753V1047h1449V124.54 C1429.159,83.093,1359.97,0.2,1209.92,0.2c-187.57,0-298.604,149.078-537.187,99.602C434.151,50.327,510.678-3.706,364.627,0.2z"
            />
          </svg>
          <DivBanner>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-12">
                  <Title>
                    1/3 de la nourriture produite est gaspillée <br />
                    La précarité alimentaire ne devrait pas exister.
                  </Title>
                  <P1>
                    Giveats change les règles en transformant ce gâchis <br />
                    en don alimentaire quotidien pour les précaires
                  </P1>
                </div>
                <div className="col-md-6 col-10 m-auto mt-5">
                  <Caption>
                    <CaptionImg src={bread} />
                    <CaptionH4>
                      Éliminer la <br /> précarité alimentaire
                    </CaptionH4>
                  </Caption>
                </div>
                <div className="col-md-6 col-10 m-auto mt-5">
                  <Caption>
                    <CaptionImg src={waste} />
                    <CaptionH4>
                      Éviter le <br /> gaspillage alimentaire
                    </CaptionH4>
                  </Caption>
                </div>
              </div>
            </div>
          </DivBanner>
        </ContainerBanner>
      </Banner>
      {/* second section */}
      <Statistical className="container">
        <div className="row">
          <div className="col-sm-12 col-12">
            <H2title
              className="mt-5"
              style={{
                marginBottom: "110px",
              }}
            >
              En effet, en France
            </H2title>
          </div>
          <Row className="row text-center">
            <div className="col-md-6 col-12 ">
              <Listing>
                <Purple>
                  8 millions de Français-e-s dépendent de l’aide alimentaire
                  ajourd’hui
                </Purple>
                <Yellow>
                  La crise sanitaire a fait basculer 1 million de Français-e-s
                  dans la pauvreté
                </Yellow>
                <Pink>La France compte désormais 9.8 millions de pauvres</Pink>
                <Orange>
                  20% des 2.7 millions d’étudiant-e-s vivent en dessous du seuil
                  de pauvreté
                </Orange>
                <Purple>
                  1 Français-e sur 4 restreint les quantités dans son assiette
                  pour des raisons financières
                </Purple>
                <Yellow>
                  1 Français-e sur 4 restreint les quantités dans son assiette
                  pour des raisons financières
                </Yellow>
                <Footsmall className="mobilehide">
                  Sources : Oxfam France, Studhelp, IPSOS x Secours populaire{" "}
                  <br />
                  IPSOS x Bnp Paribas Cardif
                </Footsmall>
              </Listing>
            </div>
            <div className="col-md-6 col-12 ">
              <Listing>
                <Orange>
                  Le gaspillage alimentaire représente 10 millions de tonnes par
                  an
                </Orange>
                <Purple>
                  18 milliards de repas sont jetés à la poubelle par an
                </Purple>
                <Yellow>
                  20 à 30 kg de nourriture sont gaspillée par personne et par an
                </Yellow>
                <Pink>
                  Une perte d’argent de 240€ en moyenne par personne et par an
                </Pink>
                <Orange>
                  15.3 millions de tonnes een équivalent CO2 donc 3% des
                  émissions nationales
                </Orange>
                <Purple>
                  1.33 millions de tonnes de nourriture gaspillées par an en
                  restauration
                </Purple>
                <Footsmall className="mobilehide">Sources : ADEME</Footsmall>
              </Listing>
            </div>
          </Row>
        </div>
      </Statistical>{" "}
      {/* third section */}
      <Hungry>
        <div className="container">
          <div className="col-md-12 col-12">
            <figure>
              <HungryImg src={hungry} />
              <HungryH2>Faim et gaspillage = Même combat</HungryH2>
              <HungryP>
                Chez Giveats, nous sommes convaincus que si l’on utilise la
                nourriture gaspillée pour la donner à ceux qui n’y ont pas ou
                peu accès, nous pouvons réduire deux problèmes majeurs de notre
                société.
              </HungryP>
              <HungryP>
                Notre volonté est de nous attaque aux causes structurelles de la
                précarité alimentaire pour l’enrayer !
              </HungryP>
            </figure>
          </div>
        </div>
      </Hungry>
      {/* fourth section */}
      <Precariousness>
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-5">
              <PrecariousnessH4>
                “La précarité alimentaire n’est pas une fatalité, dès à présente
                agissons ensemble pour y remédier !”
                <br />
                <br className="mobilehide" />
                Roméo BERNARD, CEO Giveats
              </PrecariousnessH4>
            </div>
            <div className="col-10 col-lg-7 m-auto">
              <PrecariousnessImg src={precariousness} />
            </div>
          </div>
        </div>
        <PrecariousnessSVG>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1440 539"
            enable-background="new 0 0 1440 539"
            xmlSpace="preserve"
          >
            <path fill="#FF9239" d="M0,260.001h1440v278.5H0V260.001z" />
            <path
              fill="#FF9239"
              d="M330.96,45.002c-100.139-3.6-262.364,87.833-330.959,134v102.999h1440V5.002
                    c-268.373-40-541.252,174-692.963,143C595.327,117.001,456.134,49.502,330.96,45.002z"
            />
          </svg>
        </PrecariousnessSVG>
      </Precariousness>
      {/* fifth section */}
      <TwoProblem>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <TwoProblemH2>Deux problèmes qui nous coutent chers</TwoProblemH2>
            </div>
            <div className="col-12 col-lg-6">
              <TwoProblemLeft>
                <TwoProblemH3Left>LA FAIM</TwoProblemH3Left>
                <TwoProblemPLeft>
                  1ère cause de mortalité dans le monde. Chaque 4 secondes{" "}
                  <br />
                  quelqu’un meurt de faim dans le <br />
                  monde
                </TwoProblemPLeft>
              </TwoProblemLeft>
            </div>
            <div className="col-12 col-lg-6">
              <TwoProblemRight>
                <TwoProblemH3Right>
                  LE GASPILLAGE <br /> ALIMENTAIRE
                </TwoProblemH3Right>
                <TwoProblemPRight>
                  Il représente 18% de <br />
                  la pollution mondiale{" "}
                </TwoProblemPRight>
              </TwoProblemRight>
            </div>
          </div>
        </div>
      </TwoProblem>
      {/* sixth section */}
      <div className="row">
        <div className="col-12 col-lg-6">
          <SolutionDiv>
            <SolutionH3 className="mobilehide">
              Nous avons la solution
            </SolutionH3>
            <SolutionP>
              Les excédents alimentaires de nos restos partenaires sont réservés
              exclusivement aux personnes en situation de précarité
            </SolutionP>
            <SolutionP>
              Notre but est d’aider les personnes précaires et soutenir les
              associations d’aide alimentaire, en apportant une source
              complémentaire de repas
            </SolutionP>
            <SolutionP>
              Nous souhaitons dépasser la logique d’assistanat, en proposant une
              solution alternative digitale qui permet d'impliquer directement
              les premiers concernés en leur donnant le choix de leur
              alimentation
            </SolutionP>
            <SolutionP>
              <b>
                Takeats est une solution adaptée à la diversité des situations
                et des préférences des personnes précaires et leur permet leur
                autonomisation
              </b>
            </SolutionP>
          </SolutionDiv>
        </div>
        <div
          className="col-12 col-lg-6"
          // style={{
          //   paddingLeft: "1px",
          //   paddingRight: "1px",
          // }}
        >
          <SolutionImg src={mobile1} className="img-fluid" />
        </div>
        <div
          className="col-12 col-lg-6"
          // style={{
          //   paddingLeft: "1px",
          //   paddingRight: "1px",
          // }}
        >
          <SolutionImg src={mobile2} className="img-fluid" />
        </div>
        <div
          className="col-12 col-lg-6"
          // style={{
          //   paddingLeft: "1px",
          //   paddingRight: "1px",
          // }}
        >
          <SolutionDiv>
            <SolutionP>
              L’accès à une alimentation de qualité pour tou-te-s est essentiel.{" "}
              <br></br>Chez Giveats, nous permettons aux personnes en situation
              de précarité alimentaire de pouvoir manger des repas de qualité
              préparés dans nos restos partenaires
            </SolutionP>
            <SolutionP>
              Les repas sur Takeats sont gratuits et permettent aux personnes
              précaires de dépasser la contrainte budgétaire qui les empêche
              habituellement de bénéficier d’une alimentation de qualité
            </SolutionP>
            <SolutionP>
              Nous voulons développer la solidarité dans notre société, en
              donnant la possibilité à chacun d’agir par un geste simple et
              quotidien
            </SolutionP>
            <SolutionP>
              <b>
                Chaque utilisateur peut agir à son échelle en laissant un
                micro-don au moment de sa commande. Pourquoi pas toi ?
              </b>
            </SolutionP>
          </SolutionDiv>
        </div>
      </div>
      {/* seventh section */}
      <Know>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <KnowTitle>Le saviez-vous ?</KnowTitle>
              <KnowP>
                L'objectif de{" "}
                <KnowSpan>Développement Durable n°2 “Zéro Faim”</KnowSpan>
                <br />
                vise à éradiquer la faim dans le monde d’ici 2030, mais...
                <br />
              </KnowP>
            </div>
            <div className='"col-12 col-md-6 text-center mt-5'>
              <KnowText1
                style={{
                  backgroundImage: `url(${bgmic1})`,
                }}
              >
                <KnowText1H2>
                  <KnowText1B>690 millions </KnowText1B>
                  <br />
                  de personnes ont souffert de la faim en 2019 <br /> dans le
                  monde
                </KnowText1H2>
              </KnowText1>
            </div>
            <div className='"col-12 col-md-6 text-center'>
              <KnowText2
                style={{
                  backgroundImage: `url(${bgmic2})`,
                }}
              >
                <KnowText2H2>
                  <KnowText2B>+130 millions </KnowText2B>de personnes
                  supplémentaires pourraient souffrir de la faim en 2021 à cause
                  de la pandémie
                </KnowText2H2>
              </KnowText2>
            </div>
            <div className="col-12 col-md-12 text-center savieztext-link">
              <KnowSmall>Source : ONU</KnowSmall>
            </div>
          </div>
        </div>
      </Know>
      {/* eighth section */}
      <Sanitary
        style={{
          backgroundImage: `url(${bgcharity})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <SanitaryH2>
                La crise sanitaire a créé
                <SanitarySpan>
                  {" "}
                  un élan de <br /> solidarité{" "}
                </SanitarySpan>
                dans la société française
              </SanitaryH2>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <SanitaryBlock className="col-12 col-lg-6 text-center ">
                <p>
                  La cause pour laquelle les Français·e·s comptent le plus
                  donner en 2021 est l’aide aux plus démunis
                </p>
              </SanitaryBlock>
              <SanitarySmall>
                Sources : IPSOS x Apprentis d’Auteuil
              </SanitarySmall>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <SanitaryBlock className="col-12 col-lg-6 text-center ">
                <p>
                  52% de Français·e·s ont envie d’être plus solidaires en raison
                  de la crise
                </p>
              </SanitaryBlock>
              <SanitarySmall>
                Sources : IPSOS x Apprentis d’Auteuil
              </SanitarySmall>
            </div>
            <div className="col-12 col-lg-6 text-center ">
              <SanitaryBlock className="col-12 col-lg-6 text-center">
                <p>
                  76% des 18-25 ans déclarent que la crise sanitaire leur a
                  donné envie d’être plus solidaire des autres
                </p>
              </SanitaryBlock>
              <SanitarySmall>Sources : IPSOS x la FAGE</SanitarySmall>
            </div>
            <div className="col-12 col-lg-6 text-center ">
              <SanitaryBlock className="col-12 col-lg-6 text-center ">
                <p>
                  Plus de 2/3 des Français-e-s se disent prêts à s’impliquer
                  personnellement pour aider les personnes en situation de
                  pauvreté
                </p>
              </SanitaryBlock>
              <SanitarySmall>Sources : IPSOS x Secours Populaire</SanitarySmall>
            </div>
          </div>
          <div>
            <SanitaryPic src={SanitaryPic1} />
          </div>
        </div>

        <div className="container">
          <SanitaryProdDiv className="row">
            <div className="col-12 col-md-12">
              <SanitarySecondH2>
                <SanitarySecondSpan>
                  1/3 des aliments produits{" "}
                </SanitarySecondSpan>{" "}
                dans le monde ne sont pas consommés, or cela a des conséquences
                environnementales
              </SanitarySecondH2>
            </div>

            <SanitaryPici2 src={SanitaryPic2} className="mobilehide" />
            <div className="col-12 col-md-12 text-center margin-bottom-70">
              <SanitaryBlock marginBottom="50px">
                <p>
                  L’alimentation représente <b>30% des émissions de gaz </b>à
                  effet de serre à l’échelle mondiale
                </p>
              </SanitaryBlock>
            </div>

            <div className="col-12 col-lg-6 text-center mm">
              <SanitaryBlock marginBottom="50px">
                <p>
                  Dans le monde, l’alimentation représente{" "}
                  <b>70% de la consommation d’eau potable</b>
                </p>
              </SanitaryBlock>
            </div>

            <div className="col-12 col-lg-6 text-center">
              <SanitaryBlock>
                <p>
                  L’alimentation représente entre
                  <b> 20 et 50% de l’empreinte environnementale</b> de
                  Français-e-s
                </p>
              </SanitaryBlock>
            </div>

            <div className="col-12 col-md-12 text-center">
              <SanitarySmall>Sources :Labo ESS & ADEME</SanitarySmall>
            </div>
          </SanitaryProdDiv>
        </div>

        <div className="container phonehide">
          <div className="row">
            <div className="col-12 col-md-12">
              <SanitaryThirdH2>
                Le gaspillage allimentaire se retrouve à{" "}
                <SanitaryThirdSpan>
                  {" "}
                  chaque étape de la chaine alimentaire
                </SanitaryThirdSpan>
              </SanitaryThirdH2>
            </div>
            <SanitaryProgBlock className="row">
              <div class="col-4 col-md-4 text-right  progressbaar">
                <h5>Production agricole</h5>
              </div>
              <div className="col-6 col-md-8 progressbaar ">
                <SanitaryProgressbar></SanitaryProgressbar>
              </div>
            </SanitaryProgBlock>
            <SanitaryProgBlock className="row">
              <div className="col-6 col-md-4 text-right  progressbaar">
                <h5>Transformation</h5>
              </div>
              <div className="col-6 col-md-8 progressbaar ">
                <SanitaryProgressbar
                  style={{ width: "37%" }}
                ></SanitaryProgressbar>
              </div>
            </SanitaryProgBlock>
            <SanitaryProgBlock className="row">
              <div className="col-6 col-md-4 text-right  progressbaar">
                <h5>Distribution</h5>
              </div>
              <div className="col-6 col-md-8 progressbaar ">
                <SanitaryProgressbar
                  style={{ width: "29%" }}
                ></SanitaryProgressbar>
              </div>
            </SanitaryProgBlock>
            <SanitaryProgBlock className="row">
              <div className="col-6 col-md-4  text-right progressbaar">
                <h5>Transformation</h5>
              </div>
              <div className="col-6 col-md-8 progressbaar ">
                <SanitaryProgressbar
                  style={{ width: "29%" }}
                ></SanitaryProgressbar>
              </div>
            </SanitaryProgBlock>
            <SanitaryProgBlock className="row">
              <div className="col-6 col-md-4 text-right  progressbaar">
                <h5>Consommation à domicile</h5>
              </div>
              <div className="col-6 col-md-8  progressbaar">
                <SanitaryProgressbar
                  style={{ width: "35%" }}
                ></SanitaryProgressbar>
              </div>
            </SanitaryProgBlock>
            <div className="col-4 col-md-4 "></div>
            <div className="col-8 col-md-8 ">
              <div className="row">
                <div className="col-2">
                  <b>0</b>
                </div>
                <div className="col-2">
                  <b>10</b>
                </div>
                <div className="col-2">
                  <b>20</b>
                </div>
                <div className="col-2">
                  <b>30</b>
                </div>
                <div className="col-2">
                  <b>40</b>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <SanitarySmall
                className="smalltextformation"
                style={{ marginTop: "60px" }}
              >
                Sources données : Labo ESS
              </SanitarySmall>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h2
                style={{
                  marginTop: "280px",
                  marginBottom: "110px",
                  textAlign: "center",
                  fontStyle: "normal",
                  fontWeight: "bold",
                }}
              >
                Le saviez-vous ?
                <p style={{ fontSize: "19px", lineHeight: "30px" }}>
                  Les types d'aliments les plus gaspillés
                </p>
              </h2>
            </div>
            <div className="col-6 col-md-6 text-center">
              <SanitaryImg src={progressimg1} />
              <SanitaryImgB>25%</SanitaryImgB>
            </div>
            <div className="col-6 col-md-6 text-center">
              <SanitaryImg src={progressimg2} />
              <SanitaryImgB>18%</SanitaryImgB>
            </div>
            <div className="col-6 col-md-6 text-center">
              <SanitaryImg src={progressimg3} />
              <SanitaryImgB>15%</SanitaryImgB>
            </div>
            <div className="col-6 col-md-6 text-center">
              <SanitaryImg src={progressimg4} />
              <SanitaryImgB>13%</SanitaryImgB>
            </div>
            <div className="col-12 col-md-12 text-center">
              <SanitarySmall style={{ marginTop: "0px", textAlign: "center" }}>
                Sources données: Labo ESS
              </SanitarySmall>
            </div>
          </div>
        </div>
      </Sanitary>
      {/* ninth section */}
      <Testimony
        style={{
          backgroundImage: `url(${ceo})`,
        }}
      >
        <div className="row">
          <div className="col-2 col-md-1 text-right testileft padding-right-0">
            <TestimonyQuote src={testimoniacomsleft} alt={testimoniacomsleft} />
          </div>
          <div className="col-8 col-md-4 text-center padding-left-0 padding-right-0">
            <TestimonyP>
              Le principe est simple, <br className="mobilehide" /> quand on
              mange au restaurant <br className="mobilehide" /> avec
              l'application Giveats, on se fait <br className="mobilehide" />
              plaisir tout en aidant quelqu'un <br className="mobilehide" />
              dans le besoin à manger des <br className="mobilehide" /> repas
              sauvés
            </TestimonyP>
          </div>
          <div className="col-2 col-md-1 text-left testiright">
            <TestimonyQuote
              src={testimoniacomsright}
              alt={testimoniacomsright}
            />
          </div>
          <div className="col-12 col-md-7 text-right padding-right-0">
            <TestimonySmall>
              -Roméo Bernard, CEO et fondateur de Giveats
            </TestimonySmall>
          </div>
        </div>
      </Testimony>
      {/* tenth section */}
      <Major>
        <div className="row">
          <div className="col-md-12 col-12">
            <H2title>
              Les <KnowSpan>étapes majeures </KnowSpan> de la lutte <br />{" "}
              contre la précarité alimentaire
            </H2title>
          </div>
          <div className="col-lg-4 col-md-12 text-center majbox-box majbox-box-one">
            <Majbox
              className="majbox"
              style={{
                backgroundImage: `url(${Vector172})`,
              }}
            >
              <MajorH3>2013</MajorH3>
              <MajorP>
                Signature du Pacte national de lutte contre le gaspillage
                alimentaire entre l’État et les acteurs de la chaîne
                alimentaire. Il comporte 11 mesures fondées sur l’engagement de
                ces acteurs
              </MajorP>
            </Majbox>
          </div>
          <div className="col-lg-4 col-md-12 text-center majbox-box majbox-box-two">
            <Majbox
              className="majbox majbox2"
              style={{
                backgroundImage: `url(${Vector173})`,
              }}
            >
              <MajorH3>11 février 2016</MajorH3>
              <MajorP>
                La loi Garot établit un lien entre aide alimentaire et lutte
                contre le gaspillage en obligeant les supermarchés de +400m2 à
                céder ses invendus alimentaires à une association d’aide
                alimentaire
              </MajorP>
            </Majbox>
          </div>
          <div className="col-lg-4 col-md-12 text-center majbox-box majbox-box-three">
            <Majbox
              className="majbox majbox3"
              style={{
                backgroundImage: `url(${Vector174})`,
              }}
            >
              <MajorH3>30 octobre 2018</MajorH3>
              <MajorP>
                La loi EGALIM précise que l’ensemble de la restauration
                collective, publique comme privée, devra engager une démarche de
                lutte contre le gaspillage alimentaire à l’issue d’un diagnostic
                préalable
              </MajorP>
            </Majbox>
          </div>
          <div className="col-lg-4 col-md-12 text-center majbox-box majbox-box-four">
            <Majbox
              className="majbox majbox4"
              style={{
                backgroundImage: `url(${Vector175})`,
              }}
            >
              <MajorH3>10 février 2020</MajorH3>
              <MajorP>
                La loi AGEC fixe des cibles claires et chiffrées : réduction de
                50% entre 2015 et 2030 dans la consommation, la production, la
                transformation et la restauration commerciale
              </MajorP>
            </Majbox>
          </div>
          <div className="col-lg-4 col-md-12 text-center majbox-box majbox-box-five">
            <Majbox
              className="majbox majbox5"
              style={{
                backgroundImage: `url(${Vector176})`,
              }}
            >
              <MajorH3>5 janvier 2021</MajorH3>
              <MajorP>
                Proposition de loi Pour une nouvelle étape contre le gaspillage
                alimentaire qui propose notamment de faire de la lutte contre le
                gaspillage alimentaire une “grande cause nationale” pour l’année
                2021
              </MajorP>
            </Majbox>
          </div>
        </div>
      </Major>
      <Source>
        Sources : Proposition de loi, Ministère de la Transition Écologique,
        Labo ESS
      </Source>
      {/* last section */}
      <Together>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1440 1100.871"
          enableBackground="new 0 0 1440 1100.871"
          xmlSpace="preserve"
        >
          <path
            fill="#FFD796"
            d="M312.5,171C232.5,64.6,59.833,12.667-16.5,0v1158.5H1479v-517c-8.33-55.5-67.5-208.2-237.5-375
  C1029,58,757,237,675,266.5C620.611,286.067,412.5,304,312.5,171z"
          />
        </svg>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <H2title style={{ marginBottom: `50px`, marginTop: "-150px" }}>
                Ensemble, nous avons{" "}
                <KnowSpan>
                  le pouvoir <br /> de faire changer les choses
                </KnowSpan>
              </H2title>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <ImgTogether
                src={fullmobile}
                alt={fullmobile}
                className="img-fluid"
              />
              <TogetherSpan class="mobilehide">
                <TogetherA href="https://giveats.io/caritative">
                  Je me lance
                </TogetherA>
              </TogetherSpan>
            </div>
            <div
              className="col-12 col-lg-6 text-center  "
              style={{ paddingTop: `300px` }}
            >
              <TogetherP className="mobileblack">
                10% de nos bénéfices annuels
              </TogetherP>
              <TogetherB className="mobileblack">+</TogetherB>
              <TogetherP className="mobileblack">
                100% des dons que tu réalises
              </TogetherP>
              <TogetherB className="mobileblack">=</TogetherB>
              <TogetherP className="mobileblack">
                Des repas gratuits pour les plus démunis = une réduction de la
                précarité et du gaspillage alimentaire en France
              </TogetherP>
              <TogetherP className="imgtogether">
                <img
                  src={ensembleimg}
                  alt={ensembleimg}
                  style={{
                    height: "240px",
                    width: "auto",
                  }}
                />
              </TogetherP>
            </div>
          </div>
        </div>
      </Together>
    </>
  );
}
