import React from "react";
import { Banner, TitleBannerDiv, TextContainer } from "./UseOfCookies.styles";

export default function Mentions() {
  return (
    <section>
      <Banner>
        <div className="container">
          <TitleBannerDiv className="col-sm-8">
            <h1>Utilisations de cookies</h1>
          </TitleBannerDiv>
        </div>
      </Banner>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 342.318 1452 373.682"
        enable-background="new 0 342.318 1452 373.682"
        xmlSpace="preserve"
      >
        <rect
          x="-27.756"
          y="342.318"
          fill="#FFFFFF"
          stroke="#000000"
          stroke-miterlimit="10"
          width="1548.155"
          height="537.054"
        ></rect>
        <path
          fill="#FF9239"
          d="M669.462,550.5c-289,240.4-567.058,165.5-669.961,98V0h726.25H1452v385
C1311.573,340,958.46,310.1,669.462,550.5z"
        ></path>
      </svg>
      <TextContainer className="container col-10">
        <h3>Définition et nature des données à caractère personnel</h3>
        <p>
          Lors de votre utilisation de notre site internet accessible à
          l’adresse www.giveats.fr (ci-après : le « Site »), nous pouvons être
          amenés à vous demander de nous communiquer des données à caractère
          personnel vous concernant, afin d’utiliser les Services que nous
          proposons (ci-après, les « Services »).
        </p>
        <p>
          Dans le cadre de la présente charte, le terme « données à caractère
          personnel » désigne toutes les données qui permettent d’identifier un
          individu, ce qui correspond notamment à vos nom, prénom, numéro de
          téléphone, adresse IP[n1], adresse de courrier électronique, adresse
          postale, nom de votre entreprise, numéro SIRET, nom du représentant
          légal de votre entreprise, ainsi qu’à tout autre renseignement que
          vous choisirez de nous communiquer à votre sujet.
        </p>
        <h3>Objet de la présente charte</h3>
        <p>
          La présente charte a pour objet de vous informer sur les moyens que
          nous mettons en œuvre pour collecter vos données à caractère
          personnel, dans le respect le plus strict de vos droits.
        </p>
        <p>
          Nous vous indiquons à ce sujet que nous nous conformons, dans la
          collecte et la gestion de vos données à caractère personnel, à la loi
          n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
          aux libertés, dans sa version actuelle (ci-après : la « Loi
          Informatique et Libertés »), ainsi qu’au règlement (UE) 2016/679 du 27
          avril 2016 relatif à la protection des personnes physiques à l'égard
          du traitement des données à caractère personnel et à la libre
          circulation de ces données (ci-après : le « RGPD »).
        </p>
        <h3>Identité du responsable de la collecte de données</h3>
        <p>
          Le responsable de la collecte de vos données à caractère personnel est
          la société GIVEATS, société par actions simplifiée, immatriculée au
          Registre du Commerce et des Sociétés de Pontoise sous le n° 883 632
          663 ayant son siège social 21 rue Vincent van Gogh 95170
          Deuil-la-Barre (ci-après : « Nous »).
        </p>
        <h3>
          Point de contact en matière de protection des données à caractère
          personnel[n2]
        </h3>
        <p>
          Nous avons nommé un point de contact en matière de protection des
          données à caractère personnel, dont les coordonnées sont les suivantes
          : [contact@giveats.com].
        </p>
        <h3>Collecte des données à caractère personnel</h3>
        <p>
          Le fondement légal de notre collecte de vos données personnelles est
          le suivant :
        </p>
        <ul>
          <li>
            L’intérêt légitime lorsque vous nous fournissez volontairement des
            données à caractère personnel lors de votre visite sur notre Site,
            les données étant alors collectées pour nous permettre de mieux
            répondre à vos demandes d’information sur nos Services et vous
            envoyer des newsletters le cas échéant,
          </li>
          <li>
            Votre consentement s’agissant des cookies décrits dans notre
            Politique de Cookies en annexe[n3].
          </li>
        </ul>
        <p>
          Vos données à caractère personnel sont collectées pour répondre à une
          ou plusieurs des finalités suivantes :
        </p>
        <ul>
          <li>Personnaliser les réponses à vos demandes d’information,</li>
          <li>
            Adresser des newsletters, sollicitations et messages promotionnels.
            Dans le cas où vous ne le souhaiteriez pas, nous vous donnons la
            faculté d’exprimer votre refus à ce sujet lors de la collecte de vos
            données[n4],
          </li>
          <li>Constituer un fichier de prospects,</li>
          <li>
            Élaborer des statistiques commerciales et de fréquentation de nos
            services.
          </li>
        </ul>
        <p>
          Nous vous informons, lors de la collecte de vos données personnelles,
          si certaines données doivent être obligatoirement renseignées ou si
          elles sont facultatives. Les données obligatoires sont nécessaires
          pour répondre à vos demandes d’information. Concernant les données
          facultatives, vous êtes entièrement libre de les indiquer ou non. Nous
          vous indiquons également quelles sont les conséquences éventuelles
          d’un défaut de réponse.[n5]
        </p>
        <h3>Destinataires des données collectées</h3>
        <p>Auront accès à vos données à caractère personnel :</p>
        <ul>
          <li>
            le personnel de notre société, les services chargés du contrôle
            (commissaire aux comptes notamment) ;
          </li>
          <li>
            nos sous-traitants (prestataire d’hébergement, prestataire de
            logiciel de support et d’assistance, outil CRM, outils de
            productivité et de bureautique[n6]).
          </li>
        </ul>
        <p>
          Peuvent également être destinataires de vos données à caractère
          personnel les organismes publics, exclusivement pour répondre à nos
          obligations légales, les auxiliaires de justice, les officiers
          ministériels et les organismes chargés d’effectuer le recouvrement de
          créances.
        </p>
        <h3>Cession des données à caractère personnel</h3>
        <p>
          Vos données à caractère personnel ne feront pas l’objet de cessions,
          locations ou échanges au bénéfice de tiers.
        </p>
        <h3>Durée de conservation des données à caractère personnel</h3>
        <ul>
          <li>
            Concernant les données relatives à la gestion de prospects :
            <p>
              Les données à caractère personnel relatives à un prospect, non
              client, pourront être conservées pendant un délai de 3 (trois) ans
              à compter de leur collecte ou du dernier contact émanant du
              prospect.
            </p>
            <p>
              Au terme de ce délai de 3 (trois) ans, nous pourrons reprendre
              contact avec vous pour savoir si vous souhaitez continuer à
              recevoir des sollicitations commerciales.
            </p>
          </li>
          <li>
            Concernant les pièces d’identité :
            <p>
              En cas d’exercice du droit d’accès ou de rectification, les
              données relatives aux pièces d’identité pourront être conservées
              pendant le délai prévu à l’article 9 du code de procédure pénale,
              soit 1 (un) an. En cas d’exercice du droit d’opposition, ces
              données peuvent être archivées pendant un délai de 3 (trois) ans.
            </p>
          </li>
          <li>
            Concernant la gestion des listes d’opposition à recevoir de la
            prospection :
            <p>
              Les informations permettant de prendre en compte votre droit
              d’opposition sont conservées au minimum 3 (trois) ans à compter de
              l’exercice du droit d’opposition.
            </p>
          </li>
        </ul>
        <h3>Sécurité</h3>
        <p>
          Nous vous informons prendre toutes précautions utiles, mesures
          organisationnelles et techniques appropriées pour préserver la
          sécurité, l’intégrité et la confidentialité de vos données à caractère
          personnel et notamment, empêcher qu’elles soient déformées,
          endommagées ou que des tiers non autorisés y aient accès.
        </p>
        <h3>Hébergement</h3>
        <p>
          Nous vous informons que vos données sont conservées et stockées,
          pendant toute la durée de leur conservation sur les serveurs de la
          société OVH, situés en France.
        </p>
        <h3>Transfert hors de l’Union Européenne</h3>
        <p>
          Dans le cadre des outils que nous utilisons (voir article 6 sur les
          destinataires concernant nos sous-traitants), vos données sont
          susceptibles de faire l’objet de transferts hors de l’Union
          européenne. Le transfert de vos données dans ce cadre est sécurisé au
          moyen des outils suivants :
        </p>
        <ul>
          <li>
            Soit ces données sont transférées dans un pays ayant été jugé comme
            offrant un niveau de protection adéquat par une décision de la
            Commission européenne;
          </li>
          <li>
            Soit nous avons conclu avec nos sous-traitants un contrat spécifique
            encadrant les transferts de vos données en dehors de l’Union
            européenne, sur la base des clauses contractuelles types entre un
            responsable de traitement et un sous-traitant approuvées par la
            Commission européenne.
          </li>
        </ul>
        <h3>Cookies</h3>
        <p>
          Pour plus d’informations sur les cookies, nous vous renvoyons vers
          notre Politique de Cookies en annexe.
        </p>
        <h3>Accès à vos données à caractère personnel</h3>
        <p>
          Conformément à la loi n° 78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés, et au RGPD, vous
          disposez du droit d’obtenir la communication et, le cas échéant, la
          rectification ou l’effacement des données vous concernant. Vous pouvez
          également vous adresser à :
        </p>
        <ul>
          <li>adresse de courrier électronique : [contact@giveats.fr]</li>
          <li>
            adresse de courrier postal : GIVEATS, 21 rue Vincent van Gogh 95170
            Deuil-la-Barre[n7]
          </li>
        </ul>
        <p>
          Il est rappelé aux personnes dont les données sont collectées sur le
          fondement de notre intérêt légitime, comme mentionné à l’article «
          Collecte des données à caractère personnel », qu’elles peuvent à tout
          moment s'opposer au traitement des données les concernant. Nous
          pouvons toutefois être amenés à poursuivre le traitement s’il existe
          des motifs légitimes pour le traitement qui prévalent sur vos droits
          et libertés ou si le traitement est nécessaire pour constater, exercer
          ou défendre nos droits en justice.
        </p>
        <h3>
          Droit de définir des directives relatives au traitement des données
          après votre mort
        </h3>
        <p>
          Vous disposez du droit de définir des directives relatives à la
          conservation, à l'effacement et à la communication de vos données à
          caractère personnel après votre mort.
        </p>
        <p>
          Ces directives peuvent être générales, c’est-à-dire qu’elles portent
          alors sur l’ensemble des données à caractère personnel qui vous
          concernent. Elles doivent dans ce cas être enregistrées auprès d’un
          tiers de confiance numérique certifié par la CNIL.
        </p>
        <p>
          Les directives peuvent aussi être spécifiques aux données traitées par
          notre société. Il convient alors de nous les transmettre aux
          coordonnées suivantes :
        </p>
        <ul>
          <li>adresse de courrier électronique : contact@giveats.fr</li>
          <li>
            adresse de courrier postal : GIVEATS, 21 rue Vincent van Gogh 95170
            Deuil-la-Barre[n8]
          </li>
        </ul>
        <p>
          En nous transmettant de telles directives, vous donnez expressément
          votre consentement pour que ces directives soient conservées,
          transmises et exécutées selon les modalités prévues aux présentes.
        </p>
        <p>
          Vous pouvez désigner dans vos directives une personne chargée de leur
          exécution. Celle-ci aura alors qualité, lorsque vous serez décédé(e),
          pour prendre connaissance desdites directives et nous demander leur
          mise en œuvre. A défaut de désignation vos héritiers auront qualité
          pour prendre connaissance de vos directives à votre décès et nous
          demander leur mise en œuvre.
        </p>
        <p>
          Vous pouvez modifier ou révoquer vos directives à tout moment, en nous
          écrivant aux coordonnées ci-dessus.
        </p>
        <h3>Portabilité de vos données à caractère personnel</h3>
        <p>
          Vous disposez d’un droit à la portabilité des données à caractère
          personnel que vous nous aurez fournies, entendues comme les données
          que vous avez déclarées activement et consciemment dans le cadre de
          l’accès et de l’utilisation des Services, ainsi que des données
          générées par votre activité dans le cadre de l’utilisation des
          Services. Nous vous rappelons que ce droit ne porte pas sur les
          données collectées et traitées sur une autre base légale que le
          consentement ou l’exécution du contrat nous liant.
        </p>
        <p>
          Ce droit peut être exercé gratuitement, à tout moment, et notamment
          lors de la fermeture de votre compte sur la Solution, afin de
          récupérer et de conserver vos données à caractère personnel.
        </p>
        <p>
          Dans ce cadre, nous vous adresserons vos données à caractère
          personnel, par tous moyens jugés utiles, dans un format ouvert
          standard couramment utilisé et lisible par machine, conformément à
          l’état de l’art.
        </p>
        <h3>Introduction d’une réclamation devant une autorité de contrôle</h3>
        <p>
          Vous êtes également informés que vous disposez du droit d’introduire
          une réclamation auprès d’une autorité de contrôle compétente, (la
          Commission Nationale Informatique et Libertés pour la France), dans
          l’Etat membre dans lequel se trouve votre résidence habituelle, votre
          lieu de travail ou le lieu où la violation de vos droits aurait été
          commise, si vous considérez que le traitement de vos données à
          caractère personnel objet de la présente charte constitue une
          violation des textes applicables.
        </p>
        <p>
          Ce recours pourra être exercé sans préjudice de tout autre recours
          devant une juridiction administrative ou juridictionnelle. En effet,
          vous disposez également d’un droit à un recours administratif ou
          juridictionnel effectif si vous considérez que traitement de vos
          données à caractère personnel objet de la présente charte constitue
          une violation des textes applicables.
        </p>
        <h3>Limitation du traitement</h3>
        <p>
          Vous avez le droit d’obtenir la limitation du traitement de vos
          données personnelles, dans les cas suivants :
        </p>
        <ul>
          <li>
            Pendant la durée de vérification que nous mettons en œuvre, lorsque
            vous contestez l’exactitude de vos données à caractère personnel,
          </li>
          <li>
            Lorsque le traitement de ces données est illicite, et que vous
            souhaitez limiter ce traitement plutôt que supprimer vos données,
          </li>
          <li>
            Lorsque nous n’avons plus besoin de vos données personnelles, mais
            que vous souhaitez leur conservation pour exercer vos droits,
          </li>
          <li>
            Pendant la période de vérification des motifs légitimes, lorsque
            vous vous êtes opposés au traitement de vos données personnelles.
          </li>
        </ul>
        <h3>Modifications</h3>
        <p>
          Nous nous réservons le droit, à notre seule discrétion, de modifier à
          tout moment la présente charte, en totalité ou en partie. Ces
          modifications entreront en vigueur à compter de la publication de la
          nouvelle charte. Votre utilisation du Site suite à l’entrée en vigueur
          de ces modifications vaudra reconnaissance et acceptation de la
          nouvelle charte. A défaut et si cette nouvelle charte ne vous convient
          pas, vous ne devrez plus accéder au Site.
        </p>
        <h3>Entrée en vigueur</h3>
        <p>La présente charte est entrée en vigueur le [16 aout 2019].</p>
        <h3>Qu’est-ce qu’un Cookie ?</h3>
        <p>
          Lors de votre navigation sur notre Site des cookies, pixels et autres
          traceurs (ci-après désignés ensemble les « Cookies ») sont déposés sur
          votre navigateur.
        </p>
        <p>
          Un Cookie est un petit fichier, souvent crypté, stocké dans votre
          navigateur ou votre terminal et identifié par un nom. Il est déposé
          lors de la consultation d’un site ou d’une application. Chaque fois
          que vous revenez sur le site ou sur l’application en question, le
          Cookie est récupéré sur votre navigateur ou sur votre terminal. Ainsi,
          chaque fois que vous consultez le site ou l’application, le navigateur
          est reconnu.
        </p>
        <p>
          Le dépôt de ces Cookies est susceptible de nous permettre d’accéder à
          vos données de navigation et/ou à des données à caractère personnel
          vous concernant.
        </p>
        <h3>Identification des Cookies</h3>
        <h4>Cookies techniques et fonctionnels</h4>
        <p>
          Les Cookies techniques et fonctionnels sont nécessaires au bon
          fonctionnement du Site et pour vous fournir nos services. Ils sont
          utilisés tout au long de votre navigation, afin de la faciliter et
          d’exécuter certaines fonctions.
        </p>
        <p>
          Un Cookie technique peut par exemple être utilisé pour mémoriser vos
          réponses renseignées dans un formulaire ou encore vos préférences
          s’agissant de la langue ou de la présentation du Site, lorsque de
          telles options sont disponibles.
        </p>
        <p>Nous utilisons les Cookies techniques et fonctionnels suivants :</p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cookies techniques (nécessaires)</td>
              <td>
                Ces cookies sont nécessaires au bon fonctionnement du site et
                rendent votre navigation plus confortable. Ils vous permettent
                de vous authentifier plus facilement sur notre plateforme et
                gardent en mémoire le contenu de votre panier d’achat. Ils
                permettent également de personnaliser votre interface
                utilisateur notamment concernant le choix de la langue.
              </td>
              <td>10 ans</td>
            </tr>
            <tr>
              <td>Cookies d'identification</td>
              <td>
                <p>
                  Nous utilisons certains cookies pour nous rappeler de vous et
                  des choix que vous avez déjà effectués sur GIVEATS afin de
                  vous faire gagner du temps et de rendre votre expérience plus
                  fluide.
                </p>
                <p>
                  Les cookies d'identification que nous utilisons nous
                  permettent de nous rappeler :
                </p>
                <ul>
                  <li>
                    où vous avez déjà commandé : pour pouvoir vous montrer les
                    restaurants qui pourraient vous intéresser
                  </li>
                  <li>la langue que vous voulez utiliser</li>
                  <li>
                    la devise dans laquelle vous voulez habituellement payer
                  </li>
                  <li>
                    l'adresse à laquelle vous voulez habituellement être livré
                  </li>
                  <li>
                    toutes les notifications que vous avez rejetées : nous ne
                    vous les montrerons donc pas deux fois.
                  </li>
                </ul>
              </td>
              <td>10 ans</td>
            </tr>
            <tr>
              <td>Personnalisation du contenu</td>
              <td>
                Ces cookies offrent une meilleure expérience sur notre site
                grâce à la personnalisation de nos recommandations de contenus
                et de services.
              </td>
              <td>10 ans</td>
            </tr>
            <tr>
              <td>Cookies pour améliorer le contenu que vous voyez</td>
              <td>
                <p>
                  Nous testons en continu de nouvelles façons de rendre GIVEATS
                  aussi performant que possible.
                </p>
                <p>
                  Pour ce faire, nous essayons de nouvelles idées avec de petits
                  groupes de clients avant de les présenter à tout le monde.
                  Pour effectuer cela, nous devons utiliser… oui, vous l'avez
                  deviné : des cookies !
                </p>
                <p>
                  Les cookies que nous utilisons pour cela nous permettent de :
                </p>
                <ul>
                  <li>
                    savoir quelles versions de notre site Web vous utilisez
                  </li>
                  <li>
                    contrôler les versions que vous utilisez : pour trouver
                    celles qui fonctionnent le mieux
                  </li>
                </ul>
              </td>
              <td>10 ans</td>
            </tr>
            <tr>
              <td>Cookies d’analyse</td>
              <td>
                <p>
                  [n9] Les cookies d'analyse nous aident à comprendre comment
                  les gens utilisent notre site Web. Cela nous aide à effectuer
                  des améliorations de manière constante.
                </p>
                <p>
                  Les cookies d'analyse que nous utilisons nous permettent de
                  voir :
                </p>
                <p>si vous êtes déjà venu(e) sur GIVEATS</p>
                <p>
                  qui s'est rendu sur notre site Web sans être connecté(e) à un
                  compte : cela nous indique combien de personnes sont nouvelles
                  sur Deliveroo
                </p>
                <p>
                  combien de temps les gens passent-ils sur GIVEATS et quand ils
                  ferment leur navigateur
                </p>
                <p>
                  comment nos clients sont arrivés sur GIVEATS. Par exemple,
                  s'ils sont venus sur notre site Web à partir d'une recherche
                  Google
                </p>
                <p>
                  les parties de notre site Web qui sont utilisées et celles qui
                  ne le sont pas.
                </p>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h3>Cookies publicitaires</h3>
        <p>
          Les Cookies publicitaires peuvent être créés non seulement par notre
          Site mais également par d’autres sites internet et applications
          diffusant des publicités, annonces, widgets ou autres éléments sur la
          page affichée.
        </p>
        <p>
          Ces Cookies peuvent notamment servir à personnaliser et mesurer
          l'efficacité de la publicité ou encore à réaliser du ciblage
          publicitaire.
        </p>
        <p>Nous utilisons les Cookies publicitaires suivants :</p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>GOOGLE ANALYTICS</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>MICROSOFT / BING</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>FACEBOOK</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>SNAPCHAT</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>LINKEDIN</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>TWITTER</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>TIKTOK</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Captify</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h3>Cookies de personnalisation de contenu</h3>
        <p>
          Les Cookies de personnalisation de contenu nous permettent de vous
          proposer les contenus les plus susceptibles de correspondre à vos
          centres d’intérêt au regard de votre profil de navigation. Votre
          profil de navigation est établi en fonction des contenus que vous déjà
          avez consultés.
        </p>
        <p>
          Nous utilisons les Cookies de personnalisation de contenu suivants :
        </p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cookies pour améliorer le contenu que vous</td>
              <td>
                <p>
                  Nous testons en continu de nouvelles façons de rendre GIVEATS
                  aussi performant que possible.
                </p>
                <p>
                  Pour ce faire, nous essayons de nouvelles idées avec de petits
                  groupes de clients avant de les présenter à tout le monde.
                  Pour effectuer cela, nous devons utiliser… oui, vous l'avez
                  deviné : des cookies !
                </p>
                <p>
                  Les cookies que nous utilisons pour cela nous permettent de :
                </p>
                <ul>
                  <li>
                    savoir quelles versions de notre site Web vous utilisez
                  </li>
                  <li>
                    contrôler les versions que vous utilisez : pour trouver
                    celles qui fonctionnent le mieux
                  </li>
                </ul>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Personnalisation du contenu</td>
              <td>
                Ces cookies offrent une meilleure expérience sur notre site
                grâce à la personnalisation de nos recommandations de contenus
                et de services.
              </td>
              <td></td>
            </tr>
            <tr>
              <td>youtube</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h3>Cookies de réseaux sociaux</h3>
        <p>
          Les Cookies de réseaux sociaux vous permettent de partager des
          contenus de notre Site sur les réseaux sociaux et de faire connaître,
          sur ces réseaux, votre opinion ou votre consultation de nos Services
          en cliquant notamment sur les liens « j’aime » et « partager ».
        </p>
        <p>
          Ces Cookies peuvent également permettre de tracer la navigation des
          utilisateurs sur le Site.
        </p>
        <p>
          Nous vous invitons à consulter les politiques de protection de la vie
          privée des réseaux sociaux à l’origine de ces Cookies, pour prendre
          connaissance des finalités d’utilisation des informations de
          navigation qu’ils peuvent recueillir grâce à ces Cookies et des
          modalités d’exercice de vos droits auprès de ces réseaux sociaux.
        </p>
        <p>Nous utilisons les Cookies de réseaux sociaux suivants :</p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Facebook</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Linkedin</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
            <tr>
              <td>instagram</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
            <tr>
              <td>linkedin</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Twitter</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h3> Cookies analytiques</h3>
        <p>
          Ces Cookies nous permettent de mesurer le nombre de visites, le nombre
          de pages vues et l’activité des utilisateurs. Ils peuvent le cas
          échéant collecter votre adresse IP pour déterminer la ville depuis
          laquelle vous vous connectez. [n15]Les Cookies analytiques nous
          permettent de générer des statistiques de fréquentation et de
          navigation de notre Site afin d’améliorer nos performances. Les
          Cookies utilisés permettent également d’identifier les problèmes de
          navigation et à terme, de les régler.
        </p>
        <p>Nous utilisons les Cookies analytiques suivants :</p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google analitics</td>
              <td>
                Collecte des informations sur les utilisateurs et leur activité
                sur le site Web à des fins d'analyse et de rapport
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <h3>OU ALTERNATIVE AUX TABLEAUX :</h3>
        <p>
          Nous pouvons utiliser des Cookies publicitaires, de réseaux sociaux ou
          analytiques. Vous en serez informé lors de votre première visite sur
          le Site ou l’Application utilisant ces Cookies. Vous serez alors
          invité à les accepter ou à les refuser conformément aux modalités
          décrites ci-après
        </p>
        <h4>Vos préférences en matière de Cookies</h4>
        <ul>
          <li>
            <h5>Cookies pouvant être déposés sans consentement</h5>
            <p>
              Certains Cookies ne nécessitent pas votre consentement, c’est le
              cas des :
            </p>
            <ul>
              <li>
                Cookies techniques et fonctionnels qui sont nécessaires au
                fonctionnement du Site ;
              </li>
              <li>
                De certains Cookies de mesure d’audience ou des Cookies qui
                permettent de tester des versions différentes du Site à des fins
                d’optimisation des choix éditoriaux.
              </li>
            </ul>
          </li>
          <li>
            <h5>
              L’acceptation ou le refus des Cookies soumis à votre consentement
              express
            </h5>
            <p>
              Tous les autres Cookies nécessitent votre consentement. Il s’agit
              des Cookies publicitaires, de réseaux sociaux, de personnalisation
              de contenu et de certains Cookies d’analyse d’audience[n18]. Vous
              pouvez choisir librement d’accepter ou de refuser l’utilisation de
              ces Cookies.
            </p>
            <p>
              Vous pouvez accepter ou refuser ces Cookies lors de votre première
              navigation sur le Site.
            </p>
            <p>
              Vous êtes libre de retirer votre consentement et plus généralement
              de modifier vos préférences à tout moment, via le lien suivant.
            </p>
          </li>
          <li>
            <h5>Le paramétrage de votre navigateur</h5>
            <p>
              Il est également possible de paramétrer votre navigateur afin
              qu’il accepte ou refuse certains Cookies.
            </p>
            <p>
              Chaque navigateur propose des modalités de configuration
              différentes :
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <span>Pour Internet Explorer :</span> rendez-vous dans le menu «
            Paramètres », puis « Option Internet ». Cliquez sur «
            Confidentialité » puis sur « Paramètres de confidentialité avancés »
            ;
          </li>
          <li>
            <span>Pour Chrome :</span>rendez-vous dans le menu « Paramètres »
            puis cliquez sur « Confidentialité et Sécurité ». Cliquez enfin sur
            « Autorisation » et « Cookies et données du sites » ;
          </li>
          <li>
            <span>Pour Safari :</span>rendez-vous dans l’application « Réglages
            » puis allez sur Safari. Cliquez sur l’onglet « Confidentialité et
            Sécurité ». Vous pouvez désormais choisir de bloquer tous les
            Cookies ;
          </li>
          <li>
            <span>Pour Iphone :</span> rendez-vous dans le menu « Réglage »,
            puis « Safari », cliquez sur « Cookies ». Vous pouvez désormais
            choisir de bloquer tous les Cookies ;
          </li>
          <li>
            <span>Pour Android : </span>rendez-vous sur « Chrome », cliquez sur
            l’onglet « » tout en haut à droite de l’écran, puis sur « paramètre
            de site » et ensuite sur « Cookies ». Vous pouvez désormais choisir
            d’accepter ou de bloquer les Cookies.
          </li>
        </ul>
      </TextContainer>
    </section>
  );
}
