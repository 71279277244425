import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Div,
  TextDiv,
  SectionButton,
  DivPartenaire,
  ItemCarousel,
} from "./TakeatsDunno.styles";
import BtnAsso from "../../buttons/BtnAsso";
import MobileFrame5 from "../../../images/HiPhone13.png";
import MobileFrame6 from "../../../images/iPhone157.png";
import MobileFrame7 from "../../../images/iPhone158.png";
import Unicon7 from "../../../images/Unicon7.png";
import Unicon8 from "../../../images/Unicon8.png";
import Unicon9 from "../../../images/Unicon9.png";

export default function TakeatsDunno() {
  // responsive of carousel items
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <Div>
        <h2>
          Pourquoi devenir <br />
          partenaire de giveats
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 text-center order-md-1 order-2 ">
              <img alt="mobileframe5" src={MobileFrame5} />
            </div>
            <TextDiv className="col-12 col-md-6 pourtext order-md-2 order-1">
              <div>
                <p>Lutter contre le gaspillage et la précarité alimentaire</p>
              </div>
              <div>
                <p>Un espace assos simple pour enregistrer des bénéficiaires</p>
              </div>
            </TextDiv>
          </div>
        </div>
      </Div>
      <Div
        backgroundColor="#700CBF"
        zIndex="9"
        marginTop="-400px;"
        marginTopPhone="-335px"
      >
        <div className="container">
          <div className="row">
            <TextDiv className="col-12 col-md-6" color="#fff">
              <div>
                <p className="purple">
                  Bénéficier d'une visibilité sur notre site internet et notre
                  appli
                </p>
              </div>
              <div>
                <p className="purple">
                  Rendre notre société plus solidaire et impliquée dans la lutte
                  contre la précarité
                </p>
              </div>
            </TextDiv>

            <div className="col-12 col-md-6 text-center ">
              <img alt="mobileframe6" src={MobileFrame6} />
            </div>
          </div>
        </div>
      </Div>
      <Div zIndex="10" marginTop="-400px;" marginTopPhone="-335px">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 text-center order-md-1 order-2">
              <img alt="mobileframe7" src={MobileFrame7} />
            </div>
            <TextDiv className="col-12 col-md-6 order-md-2 order-1">
              <div>
                <p>
                  Impliquer les consommateurs et les restaurants dans une
                  démarche solidaire et éthique
                </p>
              </div>
            </TextDiv>
          </div>
        </div>
      </Div>
      <SectionButton>
        <div className="container">
          <div className="col-12 col-sm-12 btndiv">
            <BtnAsso />
          </div>
        </div>
      </SectionButton>
      <DivPartenaire>
        <div className="">
          <div className="col-sm-12 col-12">
            <h2>Nos associations partenaires</h2>
          </div>
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            <div className="item">
              <ItemCarousel>
                <a href="https://fsde.fr/">
                  <img alt="Unicon7" src={Unicon7} />
                </a>
                <h3>FSDE</h3>
              </ItemCarousel>
            </div>
            <ItemCarousel>
              <a href="https://www.dansmarue.net/">
                <img alt="Unicon8" src={Unicon8} />
              </a>
              <h3>Dans ma rue</h3>
            </ItemCarousel>

            <ItemCarousel>
              <a href="https://www.studhelp.fr/">
                <img alt="Unicon9" src={Unicon9} />
              </a>
              <h3>StudHelp</h3>
            </ItemCarousel>
          </Carousel>
        </div>
      </DivPartenaire>
    </>
  );
}
