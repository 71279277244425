import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import textbg from "../../images/textbg.png";
import BgImgNothingChange from "../../images/Group628.png";
import subcribeboxbottombg from "../../images/subcribeboxbottombg.png";

import subscribeimg from "../../images/subcribeimg.png";
import { device } from "../../theme/Device";

export const Display = styled.div`
  padding-top: 80px;
  display: flex;
  justify-content: center;
`;

export const Rien = styled.section`
  width: 100%;
  height: 498;
  margin-top: 126px;

  .subcribeboxbottom {
    width: 800px;
    height: 650px;
    text-align: center;
    background-image: url(${subcribeboxbottombg});
    background-size: 100% auto;
    float: right;
    margin-top: -150px;
    padding: 170px 10px 10px 238px;

    h2 {
      font-weight: bold;
      line-height: 64px;
      text-align: center;
      color: white;
    }

    p {
      font-weight: normal;
      font-size: 25px;
      line-height: 34px;
      text-align: center;
      color: white;
    }
  }
  /* @media only screen and ${device.mobileL} {
    background-image: url(${BgImgNothingChange});

    background-size: 100% auto;
  }

  @media only screen and ${device.tablet} {
    background-image: url(${BgImgNothingChange});

    background-size: 100% auto;
  } */

  @media only screen and ${device.laptop} {
    background-image: url(${subscribeimg});
    background-repeat: no-repeat;
    background-size: 70% auto;
    display: inline-block;
  }
`;

export const Li = styled.li`
  font-weight: bold;
  text-align: left;
  line-height: 26px;
  color: #ffffff;

  .apple {
    margin-right: 20px;
  }

  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    line-height: 26px;
    color: #ffffff;
    @media only screen and ${device.mobileL} {
      padding-left: 0px;
      padding-top: 0px;
      font-size: 18px;
    }

    @media only screen and ${device.tablet} {
      padding-top: 0px;
      padding-left: 70px;
      font-size: 20px;
    }

    @media only screen and (min-width: 992px) {
      padding-left: 70px;
      padding-top: 74px;
      font-size: 20px;
    }
  }
`;

export const B = styled.b`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  line-height: 48px;
  color: #ff9239;
  @media only screen and ${device.mobileL} {
    font-size: 45px;
  }

  @media only screen and ${device.tablet} {
    font-size: 57px;
  }

  @media only screen and ${device.laptop} {
    font-size: 57px;
  }
`;

export const Buttons = styled.button`
  height: 58px;
  background: #ff9239;
  box-shadow: 3px 2px 7px #e5e5e5;
  border-radius: 35px;
  font-weight: bold;
  line-height: 27px;
  border: none;
  cursor: pointer;
  margin-left: -60px;
  text-align: center;
  color: #ffffff;
  @media only screen and ${device.mobileL} {
    width: 116px;
    font-size: 14px;
  }

  @media only screen and ${device.tablet} {
    width: 230px;
    font-size: 20px;
  }

  @media only screen and ${device.laptop} {
    width: 230px;
    font-size: 20px;
  }
`;

export const Textbg = styled.div`
  //min-height: 500px;

  h4 {
    font-weight: bold;
  }
  p {
    font-weight: normal;
    text-align: left;
    line-height: 24px;
    color: #000000;
    position: relative;

    :before {
      content: "";
      width: 3px;
      height: 100%;
      border-radius: 10px;
      background: #ff9239;
      position: absolute;
      top: 0px;
    }
  }
  a {
    width: 238px;
    font-size: 18px;
    font-weight: bold;
    height: 64px;
    line-height: 63px;
    background: #ffaf3b;
    box-shadow: 2px 4px 4px #b78876;
    color: #fff;
    text-align: center;
    border-radius: 53px;
    display: inline-block;
    text-decoration: none;
  }

  @media only screen and ${device.mobileL} {
    background: none;
    h4 {
      padding-top: 65px;
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 32px;
      color: #fff;
      span {
        color: #ff9239;
      }
    }
    p {
      padding-left: 0px;
      padding-bottom: 0px;
      font-size: 16px;
      color: #fff;
      :before {
        left: -15px;
      }
    }
    h4,
    p {
      text-align: center;
      padding-left: 0px;
    }
  }
  @media only screen and ${device.tablet} {
    background: url(${textbg});
    background-repeat: no-repeat;
    background-size: 656px;
    padding: 104px 107px 169px 111px;

    h4 {
      padding-top: 65px;
      margin-bottom: 20px;
      font-size: 43px;
      line-height: 52px;
      color: #000;
      span {
        color: #ff9239;
      }
    }
    p {
      padding-left: 0px;
      padding-bottom: 0px;
      font-size: 18px;
      color: #000;
      :before {
        left: -15px;
      }
    }
    h4,
    p {
      text-align: left;
      padding-left: 0px;
    }
  }

  @media only screen and ${device.laptop} {
    background: url(${textbg});
    background-repeat: no-repeat;
    background-size: 656px;
    padding: 173px 8px 190px 1px;
    h4 {
      padding-top: 0px;
      margin-bottom: 20px;
      font-size: 43px;
      line-height: 52px;
      color: #000;
      span {
        color: #ff9239;
      }
    }
    p {
      padding-bottom: 3%;
      font-size: 18px;
      color: #000;
      :before {
        left: 66px;
      }
    }
    h4,
    p {
      text-align: left;
      padding-left: 15%;
    }
  }
`;

export const Textbg1 = styled.div`
  text-align: center;
  min-height: 500px;

  h4 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-weight: normal;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    position: relative;
    padding-left: 15%;
    padding-bottom: 3%;
    :before {
      content: "";
      height: 100%;
      width: 3px;
      border-radius: 10px;
      background: #ff9239;
      position: absolute;
      top: 0px;
    }
  }
  a {
    width: 238px;
    font-size: 18px;
    font-weight: bold;
    height: 64px;
    line-height: 63px;
    background: #ffaf3b;
    box-shadow: 2px 4px 4px #b78876;
    color: #fff;
    text-align: center;
    border-radius: 53px;
    display: inline-block;
  }
  @media only screen and ${device.mobileL} {
    background: none;
    h4 {
      font-size: 25px;
      line-height: 32px;
      color: #fff;
      span {
        color: #700cbf;
      }
    }
    p {
      font-size: 16px;
      color: #fff;
      :before {
        left: -23px;
      }
    }
    h4,
    p {
      text-align: center;
      padding-left: 0px;
    }
  }
  @media only screen and ${device.tablet} {
    background: url(${textbg});
    background-repeat: no-repeat;
    background-size: 694px;
    padding: 122px 103px 166px 21px;
    h4 {
      font-size: 43px;
      line-height: 52px;
      color: #000;
      span {
        color: #ff9239;
      }
    }
    p {
      font-size: 18px;
      color: #000;
      :before {
        left: 66px;
      }
    }
    h4,
    p {
      text-align: left;
      padding-left: 15%;
    }
  }

  @media only screen and ${device.laptop} {
    background: url(${textbg});
    background-repeat: no-repeat;
    background-size: 650px;
    padding: 103px 40px 164px 25px;
    h4 {
      font-size: 43px;
      line-height: 52px;
      color: #000;
      span {
        color: #ff9239;
      }
    }
    p {
      font-size: 18px;
      color: #000;
      :before {
        left: 66px;
      }
    }
    h4,
    p {
      text-align: left;
      padding-left: 15%;
    }
  }
  @media only screen and (min-width: 1200px) {
    background-size: 682px;
    padding: 122px 157px 164px 25px;
  }
  @media only screen and (min-width: 1400px) {
    background-size: 723px;
    padding: 122px 211px 164px 25px;
  }
`;

export const Subscribetoemail = styled.form`
  position: relative;
  z-index: 83;
  width: 100%;
  height: 410px;;
  padding-top :110px;
  text-align: center !important;
  background-color: #fff;
  
  @media only screen and ${device.mobileL} {
    margin-top -335px;
  }

  @media only screen and ${device.tablet} {
    margin-top -155px;
  }

  @media only screen and ${device.laptop} {
    margin-top -410px;
  }
`;

export const Subscribetoemail2 = styled.form`
  width: 100%;
  height: auto;
  text-align: center !important;
`;

export const Input = styled.input`
  height: 58px;
  border: none;
  background: #f5f5f5;
  box-shadow: 3px 2px 7px #e5e5e5;
  border-radius: 35px;
  font-weight: bold;
  line-height: 27px;
  padding: 0px 62px 0px 20px;
  text-align: center;
  color: #9c9b9b;

  @media only screen and ${device.mobileL} {
    width: 196px;
    font-size: 14px;
    padding: 0px 0px 0px 0px;
    text-align: left;
    padding-left: 12px;
  }

  @media only screen and ${device.tablet} {
    width: 291px;
    font-size: 20px;
    padding: 0px 62px 0px 20px;
  }

  @media only screen and ${device.laptop} {
    width: 291px;
    font-size: 20px;
    padding: 0px 62px 0px 20px;
  }
`;

export const Container = styled.div`
  // min-height: 692px;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // top: 0;
  // z-index: 0;
  background: linear-gradient(
      157.13deg,
      #ff9239 -18.26%,
      rgba(255, 255, 255, 0) 62.56%
    ),
    #700cbf;
`;

export const Container2 = styled.div`
  // min-height: 692px;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // top: 0;
  // z-index: 0;
  background: white;

  .text-center {
    text-align: center !important;
  }

  .giveatsengage {
    width: 100%;
    height: auto;
    padding-top: 40px;

    h2 {
      font-weight: 800;
      margin-bottom: 60px;
      line-height: 75px;
      text-align: center;
      color: #000000;
    }

    .givetext {
      display: flex;
      justify-content: center;
      background-repeat: no-repeat;
      min-height: 350px;
      background-position: center top;

      @media only screen and ${device.mobileL} {
        background-size: 308px;
        p {
          margin-top: 84px;
        }
      }

      @media only screen and ${device.tablet} {
        background-size: 324px;
        p {
          margin-top: 96px;
        }
      }

      @media only screen and ${device.laptop} {
        background-size: 393px;
        p {
          margin-top: 132px;
        }
      }

      p {
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        padding: 31px 0px 0px 31px;
        text-align: center;
        max-width: 19ch;
        color: #000000;
      }
    }
  }
`;

export const Container3 = styled.div`
  min-height: 692px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  background: #f6f6f6;
  margin-top: 50px;
  margin-bottom: -25px;

  .btn1 a {
    margin-top: 30px;
  }
`;

export const Container4 = styled.div`
  min-height: 692px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  background: linear-gradient(
      180deg,
      #700cbf -27.63%,
      rgba(255, 255, 255, 0) 141.47%
    ),
    #ff9239;
  span a {
    text-align: center;
  }
  .btn1 a {
    margin-bottom: 50px;
  }
`;

export const Container5 = styled.div`
  position: relative;
  z-index: 81;
  width: 100%;
  padding-top: 100px;
  background: #ff9239;

  @media only screen and ${device.mobileL} {
    margin-top: -335px;
  }

  @media only screen and ${device.tablet} {
    margin-top: -325px;
  }

  @media only screen and ${device.laptop} {
    margin-top: -410px;
  }
`;

export const Container6 = styled.div`
  position: relative;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "-410px")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "80")};
  background: #fafafa;
  width: 100%;
  padding-top: 100px;

  @media only screen and ${device.mobileL} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "-335px")};
  }

  @media only screen and ${device.tablet} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "-359px")};
  }

  @media only screen and ${device.laptop} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "-410px")};
  }
`;
export const ContainerFood = styled.div`
  width: 100%;
  padding-top: 50px;

  .section3 {
    @media only screen and ${device.mobileL} {
      padding-top: 28px;
    }

    @media only screen and ${device.tablet} {
      padding-top: 102px;
    }

    @media only screen and ${device.laptop} {
      padding-top: 102px;
    }
  }
`;

export const Container7 = styled.div`
  min-height: 692px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;

  background: linear-gradient(
      180deg,
      #700cbf -51.58%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ff9239;
  .mdrstext {
    text-align: center;
    h3 {
      font-weight: bold;
      padding: 0px;
      line-height: 76px;
    }
    p {
      line-height: 30px;
      color: #ffffff;
    }
    small {
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
    }
  }

  .purple_title {
    color: #700cbf;
    text-shadow: -5px 0px 1px white;
  }

  .white_title {
    color: white;
    text-shadow: 5px 0px 1px #700cbf;
  }

  @media only screen and ${device.mobileL} {
    .whitezone {
      margin-bottom: 60px;
    }
    .mdrstext {
      margin-top: 50px;
      h3 {
        font-size: 38px;
        margin: 0px 0px 8px 0px;
      }
      p {
        font-size: 18px;
      }
    }
    .divbutton {
      text-align: center;
    }
  }

  @media only screen and ${device.tablet} {
    .whitezone {
      margin-bottom: 0px;
    }
    .mdrstext {
      margin-top: 140px;
      h3 {
        font-size: 68px;
        margin: 0px 0px 20px 0px;
      }
      p {
        font-size: 22px;
      }
    }
    .divbutton {
      text-align: center;
    }
  }

  @media only screen and ${device.laptop} {
    .whitezone {
      margin-bottom: 0px;
    }
    .mdrstext {
      margin-top: 140px;
      h3 {
        font-size: 78px;
        margin: 0px 0px 40px 0px;
      }
      p {
        font-size: 24px;
      }
    }
    .divbutton {
      text-align: left;
    }
  }
`;

export const VisionContainer = styled.div`
  .notebox {
    text-align: center;
    .h51 {
      line-height: 52px;
      font-weight: 800;
      margin-bottom: 12px;
      text-align: center;
      color: #000000;
    }

    p {
      line-height: 23px;
      color: #000000;
    }

    h5 {
      margin-top: 30px;
      line-height: 26px;
      text-align: center;
      color: #000000;
    }

    h5 span {
      color: #700cbf;
      font-weight: bold;
    }
  }
  h4 {
    font-weight: 800;
    font-size: 30px;
    line-height: 75px;
    margin-bottom: 41px;
    margin-top: 41px;
    text-align: center;
    color: #000000;
    span {
      color: #ff9239;
    }
  }
  @media only screen and ${device.mobileL} {
    .h51 {
      font-size: 22px;
    }
    h4 {
      font-size: 25px;
    }
    h5 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }

  @media only screen and ${device.tablet} {
    .h51 {
      font-size: 24px;
    }
    h4 {
      font-size: 30px;
    }
    h5 {
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.laptop} {
    .h51 {
      font-size: 24px;
    }
    h4 {
      font-size: 30px;
    }
    h5 {
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }
`;
export const BtnDivDl = styled.div`
  margin-top: 100px;
  text-align: center;
`;
export const FoodExperience = styled.div`
  width: 100%;
  height: auto;
  margin-top: 93px;

  h3 {
    font-weight: bold;
    line-height: 75px;
    text-align: center;
    color: rgb(0, 0, 0);
  }

  .takeatstitle {
    span {
      color: #ff8b7b;
    }
  }

  @media only screen and ${device.mobileL} {
    h3 {
      line-height: 50px;
      margin-bottom: 0px;
    }
  }

  @media only screen and ${device.tablet} {
    h3 {
      line-height: 75px;
      margin-bottom: 50px;
    }
  }

  @media only screen and ${device.laptop} {
    h3 {
      line-height: 75px;
      margin-bottom: 50px;
    }
  }
`;

export const FoodDiv = styled.div`
  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: black;
    margin: 0px 0px 10px;
    padding: 0px;
  }

  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: black;
    margin: 0px 0px 20px;
  }
`;

export const H1 = styled.h1`
  margin-bottom: 28px;
  color: #ffffff;

  @media only screen and ${device.mobileL} {
    font-weight: 900;
    text-align: center;
    line-height: 45px;
  }

  @media only screen and ${device.tablet} {
    font-weight: 800;
    text-align: left;
    line-height: 65px;
  }

  @media only screen and ${device.laptop} {
    font-weight: 800;
    text-align: left;
    line-height: 80px;
  }
`;

export const H2B = styled.h2`
  margin-bottom: 28px;
  line-height: 80px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
`;

export const H3 = styled.h3`
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 80px;
  line-height: 75px;
  text-align: center;
  span {
    color: #ff9239;
  }
`;

export const H3bis = styled.h2`
  font-weight: bold;
  margin-bottom: 28px;
  text-align: center;
  padding-top: 8%;

  span {
    color: #ff9239;
  }
`;

export const H3bis2 = styled.h2`
  font-weight: bold;
  margin-bottom: 28px;
  line-height: 75px;
  text-align: center;
  padding-bottom: 5%;
  span {
    color: #ff9239;
  }
`;

export const H5 = styled.h5`
  margin: 0px;
  margin-bottom: 20px;
  padding: 0px;
  font-weight: bold;
  line-height: 41px;
  color: #000000;

  @media only screen and ${device.mobileL} {
    font-size: 23px;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    font-size: 27px;
    text-align: center;
  }

  @media only screen and ${device.laptop} {
    font-size: 30px;
    text-align: left;
  }
`;

export const C6H5 = styled.h5`
  margin: 0px;
  margin-bottom: 20px;
  padding: 0px;
  font-weight: bold;
  line-height: 41px;
  color: #000000;

  @media only screen and ${device.mobileL} {
    font-size: 23px;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    font-size: 26px;
    text-align: left;
  }

  @media only screen and ${device.laptop} {
    font-size: 30px;
    text-align: left;
  }
`;

export const H5B = styled.h5`
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  font-weight: 800;
  line-height: 41px;
  color: #ffffff;

  @media only screen and ${device.mobileL} {
    font-size: 23px;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    font-size: 26px;
    text-align: left;
  }

  @media only screen and ${device.laptop} {
    font-size: 30px;
    text-align: left;
  }
`;

export const P = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  position: relative;

  :before {
    content: "";
    height: 100%;
    width: 3px;
    border-radius: 10px;
    background: #ff9239;
    position: absolute;
    top: 0px;
    left: -15px;
  }

  @media only screen and ${device.mobileL} {
    text-align: center;
    font-size: 18px;
  }

  @media only screen and ${device.tablet} {
    text-align: left;
    font-size: 20px;
  }

  @media only screen and ${device.laptop} {
    text-align: left;
    font-size: 24px;
  }
`;

export const P2 = styled.p`
  font-weight: normal;
  margin-bottom: 43px;
  line-height: 34px;
  color: ${(props) => (props.color ? props.color : "#000000")};

  @media only screen and ${device.mobileL} {
    font-size: 18px;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    font-size: 20px;
    text-align: left;
  }

  @media only screen and ${device.laptop} {
    font-size: 22px;
    text-align: left;
  }
`;

export const P2B = styled.p`
  color: #000000;
  span {
    font-weight: bold;
  }

  @media only screen and ${device.mobileL} {
    font-size: 16px;
  }

  @media only screen and ${device.tablet} {
    font-size: 18px;
  }

  @media only screen and ${device.laptop} {
    font-size: 20px;
  }
`;

export const Row = styled.div`
  padding-top: 130px;
`;

export const Pad = styled.div``;

export const Small = styled.small`
  font-size: smaller;
  color: white;
  line-height: 20px;
`;

export const Small2 = styled.small`
  width: 100%;
  height: auto;
  margin-top: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  display: inline-block;
  color: #ffffff;
`;

export const App = styled.div`
  color: white;
  text-align: center;
`;

export const Padding = styled.div`
  padding-top: 25px;
  img {
    margin-right: 20px;
  }
`;

export const Col = styled.div``;

export const Col2 = styled.div``;

export const Register = styled(LinkR)`
  width: 135px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 45px;
  background: #ff9239;
  text-align: center;
  line-height: 44px;
  display: inline-block;
  border-radius: 24px;
  text-decoration: none;

  &:hover {
    background: #c988ff;
    color: #ffffff;
  }
`;

export const Padding2 = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;

  @media only screen and ${device.mobileL} {
    text-align: center;
    margin-top: 48px;
    display: block;
  }

  @media only screen and ${device.tablet} {
    text-align: left;
    margin-top: 100px;
    display: flex;
  }

  @media only screen and ${device.laptop} {
    text-align: left;
    margin-top: 100px;
    display: flex;
  }
`;

export const DivPartenaire = styled.div`
  width: 100%;
  height: auto;
`;

export const ItemCarousel = styled.div`
  text-align: center;

  img {
    @media only screen and ${device.mobileL} {
      width: 120px;
    }

    @media only screen and ${device.tablet} {
      width: 180px;
    }

    @media only screen and ${device.laptop} {
      width: 200px;
    }
  }
`;

export const Video = styled.div`
  text-align: center;
  padding: 0px;
  margin: 160px auto 25px;
  width: 80%;

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
`;

export const Center = styled.div`
  text-align: center;
  margin-bottom: 3%;
  img {
    @media only screen and ${device.mobileL} {
      width: 100%;
    }

    @media only screen and ${device.tablet} {
      width: 550px;
    }

    @media only screen and ${device.laptop} {
      width: 850px;
    }
  }
`;

export const Center2 = styled.div`
  text-align: center;

  img {
    @media only screen and ${device.mobileL} {
      width: 239px;
    }

    @media only screen and ${device.tablet} {
      width: 295px;
    }

    @media only screen and ${device.laptop} {
      width: 315px;
    }
  }
`;
export const ShoppingDiv = styled.div`
   
    @media only screen and ${device.mobileL} {
      margin-bottom: 100px;
    }

    @media only screen and ${device.tablet} {
      margin-bottom: 150px;
    }

    @media only screen and ${device.laptop} {
      margin-bottom: 200px;
    }
  }
`;
export const Shopping = styled.div`
  font-size: 13px;
  font-weight: 400;

  img {
    width: 60px;
    margin-bottom: 11px;
    margin-right: 15px;
  }

  p {
    font-family: "Nunito";
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  @media only screen and ${device.mobileL} {
    margin: auto;
  }

  @media only screen and ${device.tablet} {
    margin: auto;
  }

  @media only screen and ${device.laptop} {
    margin-left: 54%;
    margin-top: -13%;
  }
`;

export const FoodFigImg = styled.img`
  width: ${(props) => (props.imgWidth ? props.imgWidth : "100px")};
`;
export const ContainerFoodImg = styled.img`
  width: 450px;
  padding-top: 102px;

  @media only screen and ${device.mobileL} {
    width: 100%;
  }

  @media only screen and ${device.tablet} {
    width: 100%;
  }

  @media only screen and ${device.laptop} {
    width: 450px;
  }
`;
export const HideImg = styled.img`
  position: relative;
  z-index: ${(props) => (props.zIndex ? props.zIndex : "80")};

  @media only screen and ${device.mobileL} {
    width: 250px;
  }

  @media only screen and ${device.tablet} {
    width: 350px;
  }

  @media only screen and ${device.laptop} {
    width: 450px;
  }
`;

export const ReflexDiv = styled.div`
  h2 {
    font-weight: bold;
    color: #000000;
    text-align: center;
  }

  p {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 80px;
  }
  span {
    color: #ff9239;
  }
  @media only screen and ${device.mobileL} {
    h2 {
      margin: 47px 0px;
    }
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.tablet} {
    h2 {
      margin: 120px 0px;
    }
    p {
      font-size: 22px;
    }
  }

  @media only screen and ${device.laptop} {
    h2 {
      margin: 120px 0px;
    }
    p {
      font-size: 25px;
    }
  }
`;

export const SrcollIphoneImg = styled.img`
  position: relative;
  z-index: 99;

  @media only screen and ${device.mobileL} {
    right: 270px;
    top: 603px;
  }

  @media only screen and ${device.tablet} {
    right: 270px;
    top: 603px;
  }

  @media only screen and ${device.laptop} {
    top: 0px;
    right: -150px;
  }
`;

export const StandingManImg = styled.img`
  @media only screen and ${device.mobileL} {
    margin-top: 25px;
    width: 200px;
  }
  @media only screen and ${device.tablet} {
    margin-top: 25px;
    width: 363px;
  }

  @media only screen and ${device.laptop} {
    margin-top: 0px;
    width: 563px;
  }
`;
export const MobileBannerImg = styled.img`
  @media only screen and ${device.mobileL} {
    margin: auto;
    margin-top: 25px;
    width: 100%;
  }
  @media only screen and ${device.tablet} {
    margin: auto;
    margin-top: 25px;
    width: 363px;
  }

  @media only screen and ${device.laptop} {
    margin-top: 0px;
    width: 563px;
  }
`;

export const ImgTabMobile = styled.img`
  @media only screen and ${device.mobileL} {
    width: 100%;
  }

  @media only screen and ${device.tablet} {
    width: 100%;
  }

  @media only screen and ${device.laptop} {
    width: 100%;
  }
`;
