import React from "react";
import { Container } from "./Press.styles";
import "react-multi-carousel/lib/styles.css";
import presstextbg from "../../images/presstextbg.png";
import uploadimage from "../../images/uploadimage.png";
import preeicon from "../../images/preeicon.png";

export default function Press(props) {
  return (
    <Container>
      <section className="pressbannner">
        <div className="container">
          <div className="col-sm-6 col-12">
            <h1>
              {" "}
              RESSOURCES <br></br> PRESSE
            </h1>
          </div>
        </div>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 342.318 1452 373.682"
          enable-background="new 0 342.318 1452 373.682"
        >
          <rect
            x="-27.756"
            y="342.318"
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            width="1548.155"
            height="537.054"
          ></rect>
          <path
            fill="#FF9239"
            d="M669.462,550.5c-289,240.4-567.058,165.5-669.961,98V0h726.25H1452v385C1311.573,340,958.46,310.1,669.462,550.5z"
          ></path>
        </svg>
      </section>

      <section className="preecontentmain">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <h2>Notre Histoire</h2>
              <p>
                Début 2019,{" "}
                <b>Kermisson-Roméo BERNARD, le fondateur de Giveats </b> a pris
                conscience d’une aberration qui l’a poussé à agir :
              </p>
              <p>
                Dans le monde, la faim est la première cause de mortalité.{" "}
                <b>6 millions</b> de personnes dépendaient de l’aide alimentaire
                pour se nourrir en France. Pourtant,{" "}
                <b>1,4 tonne de nourriture est gâchée chaque année</b> inhérente
                à l’activité des restaurants.
              </p>

              <p>
                Depuis, la crise sanitaire a profondément aggravé la situation
                augmentant le nombre de personnes en situation de vulnérabilité
                économique en France (à plus de 10 millions)
              </p>

              <p>
                Roméo et ses associés ont fondé Giveats en mai 2020. La Startup
                commercialise deux applications mobiles qui ont vocation à
                <b>
                  {" "}
                  développer l’activité des restaurants, éviter le gaspillage
                  alimentaire
                </b>{" "}
                qui en est issu et <b>aider les personnes dans le besoin</b> à
                obtenir des plats de restaurant gratuit pour se nourrir
                quotidiennement.
              </p>

              <p>
                Les cofondateurs se sont retrouvés autour d’une idée commune :
                <b>
                  {" "}
                  le premier levier contre la précarité alimentaire devrait être
                  les restaurants et les métiers de la bouche
                </b>
                . Ils ont alors décidé d’unir leurs forces et de s’y consacrer
                pleinement. Leur solution est de connecter les foodies et les
                restaurants via une appli mobile afin de transformer les
                commandes de repas, les déjeuners, les livraisons de repas et
                réservations de tables passées sur l’appli en dons alimentaires
                collaboratifs et quotidiens pour les précaires.
              </p>
              <p>
                <b>Manger, donner, gagner et recommencer</b>, voilà le mot
                d’ordre. En effet, l’appli génère des repas suspendus gratuits
                pour les personnes précaires à chaque fois qu’un foodie “givers”
                passe une commande sur l’appli. Ces repas géolocalisés sont
                récupérables par les précaires utilisant l’appli Takeats.
              </p>
              <p>
                Pour financer ces repas,{" "}
                <b>
                  la startup s’engage à utiliser 10% de ses bénéfices annuels et
                  100% des micro-dons
                </b>{" "}
                que les utilisateurs de Giveats sont invités à laisser sur
                l’addition.
              </p>
              <p>
                L’appli Takeats est plus qu'une appli,{" "}
                <b>
                  c’est donner la possibilité à des milliers de personnes
                  vulnérables de trouver de quoi manger en toute autonomie et
                  quotidiennement
                </b>
                . Surtout, l’alternative permet d’avoir une source de denrées
                diversifiées et riches dans les restaurants partenaires qui
                voudront bien jouer le jeu.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mainvideo container">
        <div className="videomain">
          <div className="video-responsive">
            <iframe
              width="560"
              height="315"
              title="mainvideo"
              src="https://www.youtube.com/embed/rC_XMW_UQdk?enablejsapi=1"
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen
              preload="none"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="ressourcesmedias">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <h2>Ressources médias</h2>
            </div>
            <div className="col-12 text-center">
              <div className="col-12">
                <div className="uploadbox">
                  <div className="uploadfilinput">
                    <img src={uploadimage} alt="uploadimage" />
                  </div>
                  <span>Images</span>
                  <small>
                    <a href="https://www.dropbox.com/sh/5kbgbkfubqbv1ug/AABUbWQ5-s5s7E47MjfVBU7ca?dl=0">
                      Télécharger le dossier logo
                    </a>
                  </small>
                </div>
              </div>

              <div className="col-12">
                <div className="uploadbox ">
                  <div className="uploadfilinput">
                    <img src={uploadimage} alt="uploadimage" />
                  </div>
                  <span>Communiqué de presse</span>
                  <small>
                    <a href="https://www.dropbox.com/sh/5kbgbkfubqbv1ug/AABUbWQ5-s5s7E47MjfVBU7ca?dl=0">
                      Télécharger le communiqué de presse
                    </a>
                  </small>
                </div>
              </div>
              <div className="uploadbox">
                <div className="uploadfilinput">
                  <img src={uploadimage} alt="uploadimage" />
                </div>
                <span>Dossier de presse</span>
                <small>
                  <a href="https://www.dropbox.com/sh/5kbgbkfubqbv1ug/AABUbWQ5-s5s7E47MjfVBU7ca?dl=0">
                    Télécharger le dossier de presse{" "}
                  </a>
                </small>
              </div>
              <div className="col-12">
                <div className="uploadbox">
                  <span>Contact presse</span>
                  <figure>
                    <img src={preeicon} alt="preeicon" />
                  </figure>
                  <p>ANAÏS FERRANDEZ</p>
                  <b>
                    <a href="mailto:communication@giveats.com">
                      communication@giveats.com
                    </a>
                  </b>
                </div>
              </div>
              <div className="col-12">
                <div className="uploadbox">
                  <div className="uploadfilinput">
                    <img src={uploadimage} alt="uploadimage" />
                  </div>
                  <span>Équipe</span>
                  <small>
                    <a href="https://www.dropbox.com/sh/5kbgbkfubqbv1ug/AABUbWQ5-s5s7E47MjfVBU7ca?dl=0">
                      Télécharger les photos lifestyle <br></br>et bannières de
                      l’appli
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}
