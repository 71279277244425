import apiAdmin from "../conf/api.admin";

const getAll = () => {
  return apiAdmin.get("/post");
};
const get = (id) => {
  return apiAdmin.get(`/post/${id}`);
};
const getByDate = () => {
  return apiAdmin.get("/postdate");
};
const getByDatePublished = () => {
  return apiAdmin.get("/post/date/published");
};
const getByCategoryPublished = (id) => {
  return apiAdmin.get(`/post/category_published/${id}`);
};
const Postservice = {
  getAll,
  get,
  getByDate,
  getByDatePublished,
  getByCategoryPublished,
};

export default Postservice;
