import React from "react";
import iPhone15 from "../../../images/iPhone15.png";
import Takeatswhyapps from "../../../images/Takeatswhyapps.png";
import iPhone13Pro from "../../../images/iPhone13Pro.png";
import {
  Div,
  List,
  Image,
  DivWhiteImg,
  TextDiv,
  DivWhy,
  ButtonDiv,
} from "./TakeatsHow.styles";
import BtnBenef from "../../buttons/BtnBenef";
import BtnAsso from "../../buttons/BtnAsso";

export default function TakeatsHow() {
  return (
    <>
      <Div>
        <div className="container">
          <h2>Comment ça marche ?</h2>
          <h2 className="sncdh2">
            Plus de galère <br />
            pour te nourrir !
          </h2>
          <div className="row">
            <div className="col-md-7 col-12 commlist">
              <h5>
                Et si tu pouvais sauver des repas <br /> suspendus autour de toi
                pour <br /> t’aider à te nourrir ?
              </h5>
              <ul>
                <List>
                  <span>1</span>
                  <p>
                    Inscris-toi ici ou auprès d’une assos partenaire et reçois
                    ton code d’activation
                  </p>
                </List>

                <List>
                  <span>2</span>
                  <p>
                    Télécharge l’appli, visualise les paniers repas autour de
                    toi à sauver
                  </p>
                </List>

                <List>
                  <span>3</span> <p>Réserve-les dans l’appli gratuitement</p>
                </List>

                <List>
                  <span>4</span>
                  <p>
                    Récupère-les auprès de nos restos partenaires en
                    clic&collect{" "}
                  </p>
                </List>
              </ul>
            </div>

            <Image className="col-md-4 col-12">
              <img alt="mobileframe4" src={iPhone15} />
            </Image>
            <div className="col-sm-12 col-12 text-center">
              <span className="btn1">
                <BtnBenef />
              </span>
              {/* <span className="btn1">
                <a href="/">Devenir une asso partenaire</a>
              </span> */}
            </div>
          </div>
        </div>
      </Div>
      <DivWhiteImg className="container">
        <img src={iPhone13Pro} alt="iphone13" />
      </DivWhiteImg>

      <DivWhy>
        <div className="container">
          <h2>Pourquoi l’appli Takeats ? </h2>
          <div className="row">
            <div className="col-12 col-lg-6 order-lg-1 order-2">
              <img alt="Takeatswhyapps" src={Takeatswhyapps} />
            </div>
            <TextDiv className="col-12 col-lg-6 order-lg-2 order-1 row">
              <h5>
                Sur l’app Giveats, la lutte contre le gaspillage <br></br>
                et celle contre la précarité alimentaire <br></br>
                représentent le même combat !
              </h5>
              <div>
                <p>
                  Nous pensons que les gâchis alimentaires <br></br>
                  sont une aberration quand encore aujourd’hui <br></br>
                  des milliers de personnes ont du mal <br></br>à se nourrir.
                </p>
              </div>

              <div>
                <h5>
                  <b>1/3</b> des aliments <br /> produits sont <br />
                  gaspillés alors que...
                </h5>
              </div>

              <div>
                <h5>
                  <b>8M</b> de Français.e.s <br />
                  dépendent de l’aide <br /> alimentaire
                </h5>
              </div>
              <ButtonDiv className="col-12 col-sm-12 mobilehide">
                <small className="btn5">
                  <a href="/">En savoir + </a>
                </small>
              </ButtonDiv>
            </TextDiv>
          </div>
          <ButtonDiv className="col-12 col-sm-12 desktophide mt-4 text-center">
            <small className="btn5">
              <a href="/">En savoir + </a>
            </small>
          </ButtonDiv>
        </div>
      </DivWhy>
    </>
  );
}
