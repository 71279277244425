import React from "react";

import BannerComponent from "./Banner/Banner";
import FoodExperienceComponent from "./Food-Experience/FoodExperience";
import AdvantageComponent from "./Advantage/Advantage";
import SolutionTable from "./Solution-Table/SolutionTable";
import BecomeComponent from "./Become/Become";
import OurPromiseComponent from "./Our-Promise/OurPromise";

export default function RestaurantComponent() {
  return (
    <>
      <BannerComponent />
      <FoodExperienceComponent />
      <AdvantageComponent />
      <SolutionTable />
      <BecomeComponent />
      <OurPromiseComponent />
    </>
  );
}
