import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";
import BgTakeats from "../../../images/bgtakeats3.png";

export const Container = styled.div`
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: auto;
  z-index: 0;
  height: 1100px;

  // background-image: url(${BgTakeats});
  // background-size: 100% auto;
  background-repeat: no-repeat;
  background: linear-gradient(
      316.41deg,
      rgba(255, 139, 123, 0.67) 70.24%,
      rgba(255, 191, 182, 0.368176) 89.31%,
      rgba(255, 255, 255, 0) 112.9%
    ),
    #ff9239;

  h1 {
    font-weight: 800;
    margin-bottom: 28px;
    color: #ffffff;
  }

  p {
    font-weight: bold;
    line-height: 33px;
    color: #ffffff;
    position: relative;
    font-size: 25px;
  }

  p:before {
    content: "";
    height: 100%;
    width: 3px;
    border-radius: 10px;
    background: #ff9239;
    position: absolute;
    top: 0px;
    left: -15px;
  }

  @media only screen and ${device.mobileL} {
    padding-top: 163px;
    h1 {
      text-align: center;
      font-size: 35px;
      line-height: 35px;
    }
    p {
      text-align: center;
      font-size: 18px;
    }
    .btn2 a {
      margin-top: 20px;
    }
  }

  @media only screen and ${device.tablet} {
    padding-top: 210px;
    h1 {
      text-align: left;
      font-size: 40px;
      line-height: 63px;
    }
    p {
      text-align: left;
      font-size: 21px;
    }
    .btn2 a {
      margin-top: 0px;
    }
  }

  @media only screen and ${device.laptop} {
    padding-top: 210px;
    h1 {
      text-align: left;
      font-size: 50px;
      line-height: 63px;
    }
    p {
      text-align: left;
      font-size: 24px;
    }

    .btn2 a {
      margin-top: 0px;
    }
  }
`;

export const FigureImage = styled.div`
  @media only screen and ${device.mobileL} {
    text-align: center;
    img {
      width: 263px;
      margin-bottom: 40px;
    }
  }

  @media only screen and ${device.tablet} {
    text-align: left;
    img {
      width: 397px;
      margin-bottom: 19px;
    }
  }

  @media only screen and ${device.laptop} {
    text-align: left;
    img {
      width: 397px;
      margin-bottom: 19px;
    }
  }
`;

export const ButtonDiv = styled.div`
  @media only screen and ${device.mobileL} {
    text-align: center;
    margin-top: 35px;
  }

  @media only screen and ${device.tablet} {
    text-align: left;
    margin-top: 154px;
  }

  @media only screen and ${device.laptop} {
    text-align: left;
    margin-top: 100px;
  }
`;

export const BtnBenef = styled(LinkR)`
  width: 200px;
  height: 55px;
  line-height: 54px;
  font-weight: 700;
  background: #700cbf;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.25);
  color: #fff;
  margin-right: 20px;
  text-align: center;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.25);
  display: inline-block;
  border-radius: 47px;
  :hover {
    background: #fff;
    color: #000;
    text-decoration: none;
  }
`;
export const BtnAsso = styled(LinkR)`
  width: 320px;
  height: 55px;
  line-height: 54px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  background: #ff9239;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 47px;
  :hover {
    background: #fff;
    color: #000;
    text-decoration: none;
  }
  @media only screen and ${device.mobileL} {
    width: 320px;
  }

  @media only screen and ${device.tablet} {
    width: 320px;
  }

  @media only screen and ${device.laptop} {
    width: 360px;
  }
`;
export const Image = styled.img`
  @media only screen and ${device.mobileL} {
    display: none;
    width: 270px;
    right: -136px;
    position: relative;
  }

  @media only screen and ${device.tablet} {
    display: block;
    right: -23px;
    position: absolute;
    width: 370px;
    top: 398px;
  }

  @media only screen and ${device.laptop} {
    display: block;
    right: -23px;
    position: absolute;
    width: 490px;
    top: 393px;
  }

  @media only screen and ${device.laptopL} {
    right: -23px;
    position: absolute;
    width: 570px;
    top: 150px;
  }
`;

export const Section = styled.section`
  margin-top: -10px;
`;
