import styled from "styled-components";
import { device } from "../../theme/Device";
import { Link as LinkR } from "react-router-dom";
import BgFooter from "../../images/footbg11.svg";

export const FooterDiv = styled.div`
  background: url(${BgFooter});
  padding-top: 120px;
  margin-top: 40px;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @media only screen and ${device.mobileL} {
  }

  @media only screen and ${device.tablet} {
  }

  @media only screen and ${device.laptop} {
  }
`;
export const ImgDiv = styled.div`
  margin-bottom: 50px;

  .logo {
    position: relative;
    width: 280px;
  }
`;
export const Container = styled.div``;
export const Copyright = styled.div`
  background: #f6f6f6;
  .copyright {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    line-height: 34px;
    padding-top: 30px;
    color: #000000;
    p {
      border-top: 1px solid #c4c4c4;
    }
  }
  .ft-link-legals {
    color: #000000;
  }
  @media only screen and ${device.mobileL} {
    margin-top: -716px;
    padding-top: 716px;
    .copyright {
      font-size: 12px;
    }
  }

  @media only screen and ${device.tablet} {
    margin-top: -516px;
    padding-top: 516px;
    .copyright {
      font-size: 15px;
    }
  }

  @media only screen and ${device.laptop} {
    margin-top: 0px;
    padding-top: 0px;
    .copyright {
      font-size: 16px;
    }
  }
`;

export const Link = styled(LinkR)``;

export const LangageWrapper = styled.div`
  right: 30px;
  position: absolute;
  z-index: 1;
  width: 70px;
  height: auto;
  background: #ffffff;
  box-shadow: 2px 4px 5px rgb(0 0 0 / 25%);
  border-radius: 14px;

  .current_lang {
    cursor: pointer;
    text-transform: uppercase;
    overflow: hidden;

    .lang {
      padding: 8px 16px;

      .lang img {
        width: 20px;
        margin-left: 0;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
`;

export const ContentFooter = styled.div`
  h3 {
    margin: 0px;
    padding: 0px;
    padding-top: 22px;
    font-weight: bold;
    line-height: 34px;
    text-align: left;
    color: #000000;
  }
  .mention-contact {
    padding-top: 22px;
  }

  ul {
    margin: 0px 0px 80px 0px;
    padding: 0px;

    li {
      list-style: none;
      margin-bottom: 5px;

      a {
        font-weight: normal;
        color: #000;
        text-align: center;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }

  @media only screen and ${device.mobileL} {
    h3 {
      font-size: 14px;
    }
    a {
      font-size: 12px;
    }
  }

  @media only screen and ${device.tablet} {
    h3 {
      font-size: 16px;
    }

    a {
      font-size: 14px;
    }
  }

  @media only screen and ${device.laptop} {
    h3 {
      font-size: 18px;
    }

    a {
      font-size: 16px;
    }
  }
`;

export const StoreSocialDiv = styled.div`
  margin-bottom: 20px;
  small {
    width: 100%;
    height: auto;
    display: inline-block;
    margin-bottom: 10px;
    color: #606060;

    font-size: 80%;
    font-weight: 400;
  }

  .store {
    margin-right: 5px;
  }
`;

export const SocialDiv = styled.div`
  margin-top: 30px;

  a {
    margin-right: 10px;

    img {
      height: 37px;
    }
  }
`;

export const NewsletterForm = styled.form`

h3 {
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  text-align: left;
  color: #000000;
`;

export const NewsletterDiv = styled.div`
  position: relative;
  margin-bottom: 30px;

  input {
    width: 291px;
    height: 58px;
    background: #ffffff;
    box-shadow: 3px 2px 7px #e5e5e5;
    border-radius: 35px;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    border: none;
    padding: 10px 30px;
    text-align: left;
  }

  button {
    background: #ff9239;
    box-shadow: 3px 2px 7px #e5e5e5;
    border-radius: 35px;
    font-weight: bold;
    height: 60px;
    border: none;
    right: 0px;
    position: relative;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    padding: 0px 20px;
    cursor: pointer;
  }

  @media only screen and ${device.mobileL} {
    input {
      width: 180px;
      font-size: 12px;
    }

    button {
      font-size: 12px;
      right: 0px;
    }
  }

  @media only screen and ${device.tablet} {
    input {
      width: 200px;
      font-size: 14px;
    }

    button {
      font-size: 14px;
    }
  }

  @media only screen and ${device.laptop} {
    input {
      width: 291px;
      font-size: 16px;
    }

    button {
      font-size: 16px;
      margin-left: -100px;
    }
  }
`;

export const ConditionAgreeDiv = styled.div`
  p {
    font-weight: normal;
    line-height: 16px;
    color: #000000;
  }

  small {
    font-weight: normal;
    font-size: 10px;
    display: inline-block;
    color: #000000;
  }

  @media only screen and ${device.mobileL} {
    p {
      font-size: 10px;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      font-size: 11px;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      font-size: 12px;
    }
  }
`;
