import axios from "axios";

const apiAdmin = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    "Content-type": "application/json",
  },
});

export default apiAdmin;
