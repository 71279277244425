import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";

export const BannerStyle = styled.div`
  width: 100%;
  height: auto;
  padding-top: 210px;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: -4px;
  background: linear-gradient(
      157.13deg,
      #ff9239 -18.26%,
      rgba(255, 255, 255, 0) 62.56%
    ),
    #700cbf;
  position: relative;

  @media only screen and ${device.mobileL} {
  }

  @media only screen and ${device.tablet} {
    padding-bottom: 250px;
  }

  @media only screen and ${device.laptop} {
    padding-bottom: 250px;
  }
`;

export const Title = styled.h1`
  font-weight: 800;
  margin-bottom: 28px;
  color: #ffffff;
  font-size: 55px;
  line-height: 58px;
  br {
    display: block;
  }

  @media only screen and ${device.mobileL} {
    font-size: 35px;
    line-height: 38px;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    font-size: 38px;
    line-height: 52px;
    text-align: left;
  }

  @media only screen and ${device.laptop} {
    font-size: 55px;
    line-height: 55px;
    text-align: left;
  }
`;

export const TextDiv = styled.div`
  p {
    font-weight: bold;
    line-height: 33px;
    color: #ffffff;
    position: relative;
    margin-top: 40px;

    :before {
      content: "";
      height: 100%;
      width: 3px;
      border-radius: 10px;
      background: #ff9239;
      position: absolute;
      top: 0px;
    }

    b {
      font-weight: bolder;
    }

    i {
      font-style: normal;
      font-family: Nunito;
      font-weight: 400;
    }
  }

  .buttondiv {
    width: 100%;
    height: auto;
    margin-top: 39px;
  }

  .date2022 {
    font-weight: 300;

    :before {
      content: none;
    }
  }
  @media only screen and ${device.mobileL} {
    p {
      padding-left: 20px;
      font-size: 20px;
      text-align: center;
      :before {
        left: 0px;
      }
    }
    i {
      font-size: 18px;
      line-height: 28px;
    }
    .buttondiv {
      text-align: center;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      padding-left: 0px;
      font-size: 22px;
      text-align: left;
      :before {
        left: -15px;
      }
    }
    i {
      font-size: 20px;
      line-height: 34px;
    }
    .buttondiv {
      text-align: left;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      padding-left: 0px;
      font-size: 24px;
      text-align: left;
      :before {
        left: -15px;
      }
    }
    i {
      font-size: 25px;
      line-height: 34px;
    }
    .buttondiv {
      text-align: left;
    }
  }
`;

export const ButtonInscris = styled(LinkR)`
  height: 45px;
  line-height: 44px;
  font-weight: bold;
  color: #fff;
  background: #700cbf;
  text-align: center;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  display: inline-block;
  border-radius: 53px;

  :hover {
    text-decoration: none;
    background: #fff;
    color: #000;
    display: inline-block;
  }

  @media only screen and ${device.mobileL} {
    margin-bottom: 20px;
    width: 240px;
    font-size: 18px;
    margin-right: 0px;
  }

  @media only screen and ${device.tablet} {
    margin-bottom: 20px;
    width: 240px;
    font-size: 18px;
    margin-right: 40px;
  }

  @media only screen and ${device.laptop} {
    margin-bottom: 0px;
    width: 240px;
    font-size: 18px;
    margin-right: 40px;
  }
`;

export const ButtonRDV = styled.a`
  height: 45px;
  line-height: 44px;
  font-weight: bold;
  color: #700cbf;
  background: #fff;
  text-align: center;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  display: inline-block;
  border-radius: 53px;

  @media only screen and ${device.mobileL} {
    width: 240px;
    font-size: 18px;
  }

  @media only screen and ${device.tablet} {
    width: 240px;
    font-size: 18px;
  }

  @media only screen and ${device.laptop} {
    width: 240px;
    font-size: 18px;
  }
`;

export const DivImage = styled.div``;

export const ImageBanner = styled.img`
  left: auto;
  z-index: -1;
  right: 0px;
  position: absolute;

  @media only screen and ${device.mobileL} {
    width: 544px;
    top: 1268px;
  }

  @media only screen and (min-width: 462px) {
    width: 544px;
    top: 1119px;
  }

  @media only screen and ${device.tablet} {
    width: 826px;
    top: 496px;
  }

  @media only screen and ${device.laptop} {
    width: 826px;
    top: 440px;
  }
`;
export const ImageBannerPhone = styled.img`
  width: 100%;
`;
export const SVG = styled.div`
  width: 100%;

  img {
    width: 100%;
    vertical-align: middle;
    border-style: none;
  }
  @media only screen and ${device.mobileL} {
    margin-top: -2%;
  }

  @media only screen and ${device.tablet} {
    margin-top: -1px;
  }

  @media only screen and ${device.laptop} {
    margin-top: -1px;
  }
`;
