import React from "react";
import { ButtonInscris } from "./Btn.styles";

const BtnRestaurant = (props) => {
  return (
    <ButtonInscris
      color={props.color}
      background={props.bg}
      mRgMob={props.mRgMob}
      mRgTab={props.mRgTab}
      mRgLaptop={props.mRgLaptop}
      to={props.to ? props.to : "/restaurant-form"}
    >
      {props.content ? props.content : "Inscrire mon restaurant"}
    </ButtonInscris>
  );
};

export default BtnRestaurant;
