import React from "react";
import TakeatsDunnoComponent from "./Takeats-Dunno/TakeatsDunno";
import TakeatsHowComponent from "./Takeats-How/TakeatsHow";
import TakeatsHomeComponent from "./Takeats-Home/TakeatsHome";
import TakeatsFoodComponent from "./Takeats-Food/TakeatsFood";
import { TakeatsBody } from "./Takeats-Dunno/TakeatsDunno.styles";

export default function TakeatsComponent() {
  return (
    <TakeatsBody>
      <TakeatsHomeComponent />
      <TakeatsFoodComponent />
      <TakeatsHowComponent />
      <TakeatsDunnoComponent />
    </TakeatsBody>
  );
}
