import React from "react";
import { ButtonDemo } from "./Btn.styles";

const BtnPurple = (props) => {
  return (
    <ButtonDemo
      href="https://calendly.com/restaurant-giveats/30min"
      target="_blank"
    >
      {props.content ? props.content : "Demander une démo"}
    </ButtonDemo>
  );
};

export default BtnPurple;
