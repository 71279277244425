import styled from "styled-components";
import LiCheck from "../../images/licheck.png";
import { device } from "../../theme/Device";

const bgGradient1 =
  "linear-gradient(169.66deg, #FFBE9D -17.88%, rgba(255, 255, 255, 0) 71.84%), #FFA8A7";

export const Banner = styled.div`
  width: 100%;
  height: auto;
  background: #ff9239;
  color: #fff;
  padding: 270px 0px 0px 0px;
  line-height: 0px;
  min-height: 350px;
  font-family: Nunito;
`;

export const TitleBannerDiv = styled.div`
  h1 {
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
    color: #ffffff;
  }
`;

export const CommissionDiv = styled.div`
  width: 100%;
  height: auto;

  .package-wrapper {
    margin-bottom: 25px;
  }
`;

export const TitleCommissionDiv = styled.div`
  h2 {
    text-align: left;
    font-weight: 800;
    width: 100%;
    margin-bottom: 50px;
    line-height: 75px;
    color: #263238;
  }
`;

export const PackageDiv = styled.div`
  min-height: ${(props) => (props.minHeight ? props.minHeight : "326px")};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : bgGradient1};
  border-radius: 78px;
  padding: 35px 17px;
  text-align: center;
  color: #fff;

  h4 {
    font-weight: bold;
    line-height: 20px;
    color: #ffffff;
    margin: 0px;
    padding: 0px;

    @media only screen and ${device.mobileL} {
      font-size: 18px;
    }
  }

  span {
    font-size: 16px;
    display: inline-block;
    margin-top: 10px;
  }

  ul {
    margin: 60px 0px;
    padding: 0px;

    li {
      text-align: left;
      list-style: none;
      margin-bottom: 10px;
      position: relative;
      padding-left: 29px;
      line-height: 22px;

      :before {
        left: 0px;
        position: absolute;
        content: "";
        width: 24px;
        height: 19px;
        background: url(${LiCheck});
      }
    }
  }
`;

export const StarterPackDiv = styled.div`
  h3 {
    font-weight: bold;
    line-height: 41px;
    color: #000000;
    margin-bottom: 25px;
    margin-top: 90px;
  }

  li {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    margin-left: 15px;
  }
`;

export const MemberFeesDiv = styled.div`
  h3 {
    font-weight: bold;
    line-height: 41px;
    color: #000000;
    margin-bottom: 25px;
    margin-top: 90px;
  }
`;

export const FeesTable = styled.table`
  width: 50%;
  border-radius: 14px;
  background: #ff9239;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;

  th {
    padding: 11px 0px;
  }

  td {
    padding: 11px 20px;
    line-height: 22px;
  }
  @media only screen and ${device.mobileL} {
    font-size: 16px;
  }

  @media only screen and ${device.tablet} {
    font-size: 20px;
  }

  @media only screen and ${device.laptop} {
    font-size: 20px;
  }
`;

export const TdBlackWhite = styled.td`
  background: #fff;
  color: #000;
  border-right: 1px solid #c4c4c4;

  b {
    font-weight: bolder;
  }
`;
