import React from "react";
import { Copyright } from "./Footer.styles";

export default function CopyrightComponent() {
  return (
    <Copyright>
      <div class="copyright container row">
        <p>
          Copyright © 2022 Giveats SAS. - Tous droits réservés I
          <a class="ft-link-legals" href="/mentions-legales">
            {" "}
            Mentions légales
          </a>
        </p>
      </div>
    </Copyright>
  );
}
