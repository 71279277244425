import React from "react";
import { Download } from "./Btn.styles";
import { useState } from "react";
import Popup from "../popup/Popup";

const BtnDownload = (props) => {
  const [popup, setPopup] = useState(true);
  const showPopup = () => {
    setPopup(!popup);
  };
  return (
    <>
      <Download onClick={showPopup}>
        {props.content ? props.content : "Télécharger maintenant"}
      </Download>
      <Popup hide={popup} />
    </>
  );
};

export default BtnDownload;
