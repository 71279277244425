import React, { useState } from "react";
import apiSendinblue from "../../conf/api.sendinblue";
import "react-multi-carousel/lib/styles.css";
import { InputForm, Userinnerwrap, Section } from "./Form.styles.js";
import Usericon from "../../images/usericon.png";

export default function Benefpage(props) {
  const initialcreateContactState = {
    email: "",
    attributes: {
      numero: "",
      prenom: "",
      nom: "",
    },
    listIds: [15],
    emailBlacklisted: false,
    numeroBlacklisted: false,
    updateEnabled: false,
  };
  const [createContact, setcreateContact] = useState(initialcreateContactState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChanges = (level) => (e) => {
    if (!level) {
      // Assume root level
      setcreateContact({
        ...createContact,
        [e.target.name]: e.target.value,
      });
    } else {
      setcreateContact({
        ...createContact,
        [level]: {
          ...createContact[level],
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const saveUser = () => {
    var data = {
      email: createContact.email,
      attributes: {
        numero: createContact.attributes.numero,
        prenom: createContact.attributes.prenom,
        nom: createContact.attributes.nom,
      },

      listIds: [11],
    };
    console.log(data);
    apiSendinblue
      .post("contacts", data)
      .then((response) => {
        setcreateContact({
          email: response.data.email,
          attributes: {
            numero: response.data.attributes.numero,
            nom: response.data.attributes.nom,
            prenom: response.data.attributes.prenom,
          },
        });
        setSubmitted(true);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Section>
      <div class="container">
        <div class="col-12 col-sm-12 text-center">
          <h2>
            Bienvenue !
            <img
              src={Usericon}
              alt="store"
              style={{
                marginTop: "-40px",
              }}
            />
          </h2>
        </div>
        {submitted === false ? (
          <InputForm>
            <div class="col-12 col-lg-6 col-md-8 text-center">
              <Userinnerwrap>
                <label>Nom*</label>
                <input
                  type="text"
                  name="nom"
                  value={createContact.attributes.nom}
                  onChange={handleInputChanges("attributes")}
                  placeholder="Doe"
                ></input>
                <label>Prénom*</label>
                <input
                  type="text"
                  name="prenom"
                  required
                  value={createContact.attributes.prenom}
                  onChange={handleInputChanges("attributes")}
                  placeholder="John"
                ></input>
                <label>Adresse email*</label>
                <input
                  type="email"
                  id="email"
                  required
                  value={createContact.email}
                  onChange={handleInputChanges()}
                  name="email"
                  placeholder="ex : johndoe@email.com"
                ></input>{" "}
                <label>N° téléphone*</label>
                <input
                  type="text"
                  name="numero"
                  value={createContact.attributes.numero}
                  onChange={handleInputChanges("attributes")}
                  placeholder="0"
                ></input>
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <div class="input-phone"></div>
                  </div>
                  <div class="row">
                    <div class="col-sm-1 col-2">
                      <input type="checkbox" name="agree"></input>
                    </div>
                    <div class="col-sm-11 col-10 checkboxtext">
                      <p>
                        M’envoyer le dernier article de blog et souscrire à la
                        newsletter de Giveats
                      </p>
                    </div>
                  </div>
                </div>
                <button onClick={saveUser} class="submitbutton">
                  C’est parti !{" "}
                </button>
              </Userinnerwrap>
            </div>
          </InputForm>
        ) : (
          <h1> nice job</h1>
        )}
      </div>
    </Section>
  );
}
