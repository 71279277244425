import styled from "styled-components";
import { device } from "../../theme/Device";
import { Link as LinkR } from "react-router-dom";

export const DivPopup = styled.div`
  position: fixed;
  bottom: 15px;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%);
  border-radius: 35px;

  h5 {
    font-weight: 800;
    padding-top: 10px;
    margin-bottom: 50px;
  }
  p {
    font-weight: 600;
    margin: 0px;
  }
  p:last-of-type {
    margin-bottom: 50px;
  }

  @media only screen and ${device.mobileL} {
    p {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 0px) {
    width: 80vw;
  }
  @media only screen and (min-width: 435px) {
    width: 400px;
  }

  @media only screen and ${device.tablet} {
    width: 400px;
    p {
      font-size: 16px;
    }
  }

  @media only screen and ${device.laptop} {
    width: 400px;
    p {
      font-size: 16px;
    }
  }
`;

export const Register = styled(LinkR)`
  width: 135px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 45px;
  background: #ff9239;
  text-align: center;
  line-height: 44px;
  display: inline-block;
  border-radius: 24px;
  text-decoration: none;
  margin-bottom: 10px;

  &:hover {
    background: #c988ff;
    color: #ffffff;
  }
`;

export const ImgClose = styled.img`
  position: absolute;
  top: 10px;
  right: 18px;
  width: 18px;
  cursor: pointer;
`;
