import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Card,
  HelpDiv,
  DivBtnRestaurant,
  VideoDiv,
  Video,
  UnderVideo,
  BtnRDV,
  H3,
  FoodExperience,
  ContainerFood,
  ContainerFoodImg,
  H5,
  P2B,
  ButtonInscris,
  ButtonCenter,
  ContainerFoodContent,
  LinkSubRes,
  FunctionManager,
} from "./FoodExperience.styles";
import BtnDemo from "../../buttons/BtnDemo";
import BtnRestaurant from "../../buttons/BtnRestaurant";
import Engagement from "../../../images/Engagement-client.png";
import Server from "../../../images/server.png";
import Market from "../../../images/Market-place.png";
import Ecosysteme from "../../../images/Ecosysteme.png";
import Experience from "../../../images/Experience.png";
import Fideliser from "../../../images/Fideliser2.png";
import Optimisation from "../../../images/Optimisation.png";

export default function FoodExperiences() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  const responsiveOrange = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 1024, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <FoodExperience className="container">
        <h3>
          La solution pour piloter toute votre activité
          <br />
          et participer à <span> résoudre la précarité alimentaire</span>{" "}
        </h3>

        <ContainerFood>
          <ContainerFoodContent className="col-12 row">
            <div class="col-12 col-lg-6">
              <ContainerFoodImg alt="Market" src={Market} />
            </div>
            <div class="col-12 col-lg-6 content-text">
              <H5>
                Une marketplace tout-en-un <br></br>pour gérer toute votre
                activité <br></br> de manière autonome{" "}
              </H5>
              <br></br>
              <P2B>
                Plus de compromis ! <br></br>
                Vous avez à coeur d’optimiser votre service sur place et en
                ligne ? <br></br>
                Vous avez à coeur de gagner en autonomie et conserver vos
                données clients ? <br></br>
                Digitalisez toute votre activité avec la première plateforme
                restaurant physidigitale (à emporter / livraison / réservation
                de table,) avec des solutions de paiements adaptés pour régler
                l’addition full mobile. <br></br>
                Devenez Numéro un dans le service client.
              </P2B>
            </div>
          </ContainerFoodContent>
          <ContainerFoodContent className="row col-12">
            <div class="col-12 col-lg-6 order-2 order-lg-1 content-text">
              <H5>
                Devenez un restaurant “du coeur” <br></br>
                éthique et solidaire contre<br></br>
                la précarité alimentaire{" "}
              </H5>
              <br></br>
              <P2B>
                Et si vous pouviez limiter vos pertes tout en aidant des
                personnes en situation de précarité à manger ? <br></br>
                Giveats vous propose de prendre part à un programmde d’aide
                alimentaire transparente et unique. <br></br>
                Mettez en ligne un menu solidaire sur notre app d’entraide
                takeats, vendez vos invendus et excédents de service. <br></br>
                Un repas acheté dans votre établissement par nos utilisateurs
                participe à fournir un autre repas à une personne dans le
                besoin. <br></br>
                <br></br>
                Donnez encore plus de sens en prenant part à une action
                citoyenne et solidaire sans surcoût.<br></br>
              </P2B>
            </div>

            <div class="col-12 col-lg-6 text-center order-1 order-lg-2 ">
              <ContainerFoodImg alt="Ecosysteme" src={Ecosysteme} />
            </div>
          </ContainerFoodContent>
          <ContainerFoodContent className="row col-12">
            <div class="col-12 col-lg-6 text-center">
              <ContainerFoodImg alt="Experience" src={Experience} />
            </div>
            <div class="col-12 col-lg-6 content-text">
              <div>
                <H5>
                  Une solution unique pour fidéliser <br></br>sans effort vos
                  clients de chalandise{" "}
                </H5>
                <br></br>
                <P2B>
                  Giveats Manager est la première interface qui recréait du lien
                  avec vos meilleurs clients sur tous les canaux. <br></br>
                  Les données clients vous appartiennent, vous programmer vos
                  actions promotionnelles de manière ciblées sur les catégories
                  clients <br></br>
                  C’est la première appli qui permet de suivre la fréquentation
                  globale de vos clients sur tous les canaux. <br></br>
                  <br></br>
                  Prenez part au programme de fidélité de Giveats pour
                  récompenser la fidélité des clients les plus assidus et dans
                  votre zone géographique.
                </P2B>
              </div>
            </div>
          </ContainerFoodContent>
        </ContainerFood>
        <ButtonCenter>
          <BtnDemo />
        </ButtonCenter>
      </FoodExperience>
      <HelpDiv>
        <div className="container">
          <div className="col-sm-12 col-12">
            <h2>
              Giveats <span>aide les restaurants</span>
              <br />
              comme le vôtre à...
            </h2>
          </div>
          <Carousel responsive={responsive}>
            <Card>
              <h5>
                Toucher et fidéliser <br />
                une clientèle engagée
              </h5>
              <img src={Engagement} alt="engagement" />
              <p>
                Nous vous aidons à diffuser votre offre tout ayant un{" "}
                <b> impact socio-environnemental </b> en adéquation avec les
                valeurs de vos clients.
                <br></br>
                <br></br>
                Votre restaurant se démarque auprès{" "}
                <b>
                  d’une communauté de foodies bienveillante et engagée{" "}
                </b>{" "}
                contre la précarité alimentaire et le gaspillage alimentaire.
              </p>
            </Card>
            <Card>
              <h5>
                Améliorer vos marges <br />
                et éliminer le gaspillage
              </h5>
              <img src={Fideliser} alt="Fideliser" />

              <p>
                Gagnez <b>jusqu’à 25% de revenu supplémentaire </b> via une
                stratégie de fidélisation ciblée et automatique, pensée pour vos
                clients de proximité.
                <br></br>
                <br></br>
                Réduisez vos pertes,{" "}
                <b>
                  vendez des excédents alimentaires dans votre établissement{" "}
                </b>{" "}
                et un menu solidaire sur l’appli Takeats aident des personnes
                dans le besoin du quartier à se nourrir sans surcoût.
              </p>
            </Card>
            <Card>
              <h5>
                Optimiser le service sur <br />
                tous vos canaux de vente
              </h5>
              <img src={Optimisation} alt="Optimisation" />

              <p>
                Giveats met à disposition une tablette le dashboard manager pour
                gérer toute votre activité.
                <br></br>
                Les clients bénéficient d’une appli 3-en-1 et physidigitale pour
                les commandes sur place ou en livraison.
                <br></br>
                <br></br>
                Organisez-vous et gagnez en efficacité ! les commandes arrivent
                à l’avance payée et schédulé avant le début du service.
              </p>
            </Card>
          </Carousel>
          <DivBtnRestaurant className="col-sm-12 col-12 givtext">
            <BtnRestaurant>Inscrire mon restaurant</BtnRestaurant>
          </DivBtnRestaurant>
          <div className="row">
            <div className="col-12 col-sm-12">
              <h2>
                Pourquoi devenir <span>un restaurant solidaire</span> <br />
                avec Giveats?
              </h2>
            </div>
          </div>
          <Video>
            <div className="video-responsive">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/mg6fnbcIn_M?enablejsapi"
                title="restaurant video"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                autoPlay={false}
              ></iframe>
            </div>
          </Video>
        </div>
      </HelpDiv>

      <VideoDiv>
        <div className="container">
          <UnderVideo className="row">
            <FunctionManager className="col-12 mt-5 phonehide">
              <div>
                <h3>
                  <span>Les fonctionnalités</span> Giveats manager pour votre
                  restaurant
                </h3>
              </div>
              <Carousel
                responsive={responsiveOrange}
                className="orange-carousel"
              >
                <div className="orange-round ">
                  <p>Digitalisation du menu sur place avec QR code</p>
                </div>
                <div className="orange-round ">
                  <p>
                    Réservation de table classique avec empreinte de la carte
                    bancaire
                  </p>
                </div>
                <div className="orange-round ">
                  <p>
                    Commande et règlement de l’addition à l’avance sur mobile
                  </p>
                </div>
                <div className="orange-round ">
                  <p>Commande programmable jusqu’à 3 jours à l’avance</p>
                </div>
              </Carousel>
              <Carousel
                responsive={responsiveOrange}
                className="orange-carousel"
              >
                <div className="orange-round ">
                  <p className="small-word ">
                    Yield <br /> Management
                  </p>
                </div>
                <div className="orange-round ">
                  <p>Vente des invendus alimentaires à un prix juste</p>
                </div>
                <div className="orange-round ">
                  <p className="small-word ">
                    Commande <br /> groupée
                  </p>
                </div>
                <div className="orange-round ">
                  <p>Solution de partage de l’addition entre convives</p>
                </div>
              </Carousel>
              <Carousel
                responsive={responsiveOrange}
                className="orange-carousel"
              >
                <div className="orange-round ">
                  <p>
                    Paiement CB <br /> et titres <br /> restaurant
                  </p>
                </div>
                <div className="orange-round ">
                  <p>Système de Réponse aux commentaires et notation client</p>
                </div>
                <div className="orange-round ">
                  <p>Mise à disposition des coordonnées clients</p>
                </div>
                <div className="orange-round ">
                  <p className="small-word ">
                    Support <br /> technique
                  </p>
                </div>
              </Carousel>
            </FunctionManager>
            <div className="col-12 col-sm-12 text-center">
              <h3>
                La 1ère interface web qui
                <span>
                  {" "}
                  regroupe toute <br />
                  votre activité sur place et en livraison, <br />
                </span>{" "}
                tout en aidant des personnes dans le besoin à se nourrir
              </h3>
              <img src={Server} alt="Server" />
            </div>
            <BtnRDV className="col-12 col-sm-12 text-center">
              <span>
                <BtnDemo />
              </span>
            </BtnRDV>
          </UnderVideo>
        </div>
      </VideoDiv>
    </>
  );
}
