import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";

export const TakeatsBody = styled.div`
  padding: 0;
  margin: 0;
  overflow-x: hidden;
`;

export const Div = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fafafa"};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 5)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};

  padding-top: 50px;

  h2 {
    width: 100%;
    font-weight: bold;
    margin-bottom: 88px;
    text-align: center;
    line-height: 48px;
    color: #000000;
  }

  @media only screen and ${device.mobileL} {
    margin-top: ${(props) =>
      props.marginTopPhone ? props.marginTopPhone : "0px"};
    h2 {
      margin-bottom: 10px;
    }
    img {
      width: 250px;
    }
  }

  @media only screen and ${device.tablet} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
    h2 {
      margin-bottom: 88px;
    }
    img {
      width: 350px;
    }
  }

  @media only screen and ${device.laptop} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
    h2 {
      margin-bottom: 88px;
    }
    img {
      width: 450px;
    }
  }
`;
export const TextDiv = styled.div`
  p {
    padding: 0px;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: ${(props) => (props.color ? props.color : "#000000")};
  }
  @media only screen and ${device.mobileL} {
    padding-top: 20px;
    p {
      font-size: 20px;
      text-align: center;
      margin: 17px 0px;
    }
    .purple {
      margin: 19px 0px;
    }
  }

  @media only screen and ${device.tablet} {
    padding-top: 0px;
    p {
      font-size: 25px;
      text-align: left;
      margin: 50px 0px;
    }
    .purple {
      margin: 17px 0px;
    }
  }

  @media only screen and ${device.laptop} {
    padding-top: 102px;
    p {
      font-size: 30px;
      text-align: left;
      margin: 50px 0px;
    }
    .purple {
      margin: 50px 0px;
    }
  }
`;

export const SectionButton = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  position: relative;
  z-index: 11;

  .btndiv {
    text-align: center;
  }

  @media only screen and ${device.mobileL} {
    padding: 65px 0px 0px 0px;
    margin-top: -335px;
  }
  àmedia only screen and ${device.tablet} {
    padding: 110px 0px 0px 0px;
    margin-top: -400px;
  }
  @media only screen and ${device.laptop} {
    padding: 110px 0px 0px 0px;
    margin-top: -400px;
  }
`;

export const DivPartenaire = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 11;
  margin-bottom: 200px;
  padding: 0px 50px;

  h2 {
    line-height: 60px;
    margin-bottom: 100px;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }
  @media only screen and ${device.mobileL} {
    margin-top: -208px;
  }

  @media only screen and ${device.tablet} {
    margin-top: 0px;
  }

  @media only screen and ${device.laptop} {
    margin-top: -0px;
    h2 {
      font-size: 46px;
    }
  }
`;

export const ItemCarousel = styled.div`
  text-align: center;

  h3 {
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px
  color: #000000;
  }
  @media only screen and ${device.mobileL} {
    
  font-size: 18px;
  img{
    width:140px
  }
  }

  @media only screen and ${device.tablet} {
    
  font-size: 23px;
  img{
    width:220px
  }
  }

  @media only screen and ${device.laptop} {
    font-size: 23px;
    img{
      width:220px
    }
  }
`;
