import styled from "styled-components";
import { device } from "../../theme/Device";
import bgline from "../../images/bgline.png";
import dextextbg from "../../images/dextextbg.png";
import dextextbgright from "../../images/dextextbgright.png";

export const Banner = styled.section`
  max-width: 100%;
  height: auto;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: -4px;
`;

export const ContainerBanner = styled.div`
  margin-top: -19%;
  position: relative;
  z-index: 99;
  min-height: 1057px;
`;

export const DivBanner = styled.div`
  height: auto;
  background: #700cbf;
  padding: 20px 0px 150px 0px;
  text-align: center;

  .p:before {
    display: none;
  }
`;

export const Title = styled.h1`
  font-weight: 850;
  margin-bottom: 28px;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  @media only screen and ${device.mobileL} {
    font-size: 28px;
  }

  @media only screen and ${device.tablet} {
    font-size: 32px;
  }

  @media only screen and ${device.laptop} {
    font-size: 40px;
  }
`;

export const BannerImg = styled.div`
  img {
    width: 100%;
  }
`;

export const P1 = styled.p`
  font-style: normal;
  font-weight: 800;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;

  @media only screen and ${device.mobileL} {
    font-size: 18px;
  }

  @media only screen and ${device.tablet} {
    font-size: 20px;
  }

  @media only screen and ${device.laptop} {
    font-size: 24px;
  }
`;

export const Caption = styled.div`
  background: #ffffff;
  border-radius: 34px;
  box-shadow: 0px 0px 39px rgb(0 0 0 / 25%);

  @media only screen and ${device.mobileL} {
    padding: 0px 0px;
    padding-top: 15px;
    min-height: 300px;
  }

  @media only screen and ${device.tablet} {
    padding: 50px 0px;
    min-height: 300px;
  }

  @media only screen and ${device.laptop} {
    padding: 50px 0px;
    min-height: 427px;
  }
`;

export const CaptionImg = styled.img`
  width: 224px;
  margin-bottom: 30px;

  @media only screen and ${device.mobileL} {
    width: 154px;
  }

  @media only screen and ${device.tablet} {
    width: 204px;
  }

  @media only screen and ${device.laptop} {
    width: 224px;
  }
`;

export const CaptionH4 = styled.h4`
  font-style: normal;
  font-weight: bold;
  line-height: 41px;
  text-align: center;
  color: #000000;
`;

export const Row = styled.div``;

export const Statistical = styled.section`
  width: 100%;
  height: auto;
  // padding: 60px 130px;
`;

export const H2title = styled.h2`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #000000;

  @media only screen and ${device.mobileL} {
    line-height: 45px;
  }

  @media only screen and ${device.tablet} {
    line-height: 60px;
  }

  @media only screen and ${device.laptop} {
    line-height: 60px;
  }
`;

export const Listing = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 18px;
  @media only screen and ${device.mobileL} {
    font-size: 16px;
  }

  @media only screen and ${device.tablet} {
    font-size: 18px;
  }

  @media only screen and ${device.laptop} {
    font-size: 18px;
  }
`;

export const Purple = styled.li`
  width: 80%;
  padding: 50px 46px;
  text-align: center;
  margin-bottom: 30px;
  display: inline-block;
  border-radius: 942px;
  background: #700cbf;
  color: #fff;
`;

export const Yellow = styled.li`
  width: 80%;
  padding: 50px 46px;
  text-align: center;
  margin-bottom: 30px;
  display: inline-block;
  border-radius: 942px;
  background: #ff9239;
  color: #fff;
  margin-bottom: 30px;
`;

export const Pink = styled.li`
  width: 80%;
  padding: 50px 46px;
  text-align: center;
  margin-bottom: 30px;
  display: inline-block;
  border-radius: 942px;
  background: #c988ff;
  color: #fff;
`;

export const Orange = styled.li`
  width: 80%;
  padding: 50px 46px;
  text-align: center;
  margin-bottom: 30px;
  display: inline-block;
  border-radius: 942px;
  background: #ff8b7b;
  color: #fff;
`;

export const Footsmall = styled.small`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  width: 100%;
  text-align: center;
  color: #000000;
  float: left;
`;

export const Hungry = styled.section`
  width: 100%;
  height: auto;
  text-align: center;
  background: #ff8b7b;
  padding-bottom: 50px;
  margin-top: 99px;
`;

export const HungryImg = styled.img`
  margin-top: -110px;
  @media only screen and ${device.mobileL} {
    width: 250px;
  }

  @media only screen and ${device.tablet} {
    width: 400px;
  }

  @media only screen and ${device.laptop} {
    width: 500px;
  }
`;

export const HungryH2 = styled.h2`
  font-style: normal;
  font-weight: 800;
  line-height: 75px;
  text-align: center;
  color: #ffffff;
`;

export const HungryP = styled.p`
  font-style: normal;
  font-weight: 400;
  //padding: 0px 150px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 27px;
  margin-top: 0;

  @media only screen and ${device.mobileL} {
    padding: 0px;
    font-size: 18px;
  }

  @media only screen and ${device.tablet} {
    padding: 20px 80px 60px 80px;
    font-size: 22px;
  }

  @media only screen and ${device.laptop} {
    padding: 20px 80px 60px 80px;
    font-size: 25px;
  }
`;

export const Precariousness = styled.section`
  width: 100%;
  height: auto;
  padding: 20px 0px 60px 0px;
  margin-top: 99px;
  position: relative;

  @media only screen and (max-width: 991px) {
    .row {
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
    }
  }
`;

export const PrecariousnessSVG = styled.div`
  position: relative;
  z-index: -1;
  @media only screen and ${device.mobileL} {
    margin-top: -130px;
  }

  @media only screen and ${device.tablet} {
    margin-top: -280px;
  }

  @media only screen and ${device.laptop} {
    margin-top: -280px;
  }
`;
export const PrecariousnessH4 = styled.h4`
  font-style: normal;
  font-weight: 800;
  line-height: 41px;
  color: #700cbf;
  @media only screen and ${device.mobileL} {
    line-height: 28px;
    margin-top: 45px;
  }

  @media only screen and ${device.tablet} {
    line-height: 41px;
    margin-top: 95px;
  }

  @media only screen and ${device.laptop} {
    line-height: 41px;
    margin-top: 95px;
  }
`;

export const PrecariousnessImg = styled.img`
  background: #c4c4c4;
  box-shadow: 0px 0px 37px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
  width: 100%;
`;

export const TwoProblem = styled.section`
  width: 100%;
  height: auto;
  padding: 90px 0px;
`;

export const TwoProblemH2 = styled.h2`
  line-height: 60px !important;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin-bottom: 62px;
`;

export const TwoProblemLeft = styled.div`
  min-height: 250px;
  background: url(${dextextbg});
  text-align: center;
  padding: 140px 90px;
  background-position: center top;
  background-repeat: no-repeat;

  @media only screen and ${device.mobileL} {
    background-size: auto 350px;
    padding: 80px 15%;
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.tablet} {
    background-size: auto 440px;
    padding: 140px 149px;
    p {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 992px) {
    background-size: auto 440px;
    padding: 140px 149px;
    p {
      font-size: 20px;
    }
  }

  @media only screen and ${device.laptop} {
    background-size: auto 460px;
    padding: 140px 90px;
    p {
      font-size: 23px;
    }
  }
`;

export const TwoProblemH3Left = styled.h3`
  font-style: normal;
  font-weight: 800;
  margin: 0px 0px 25px 0px;
  color: #000000;
`;

export const TwoProblemPLeft = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
`;

export const TwoProblemRight = styled.div`
  width: 100%;
  min-height: 250px;

  background: url(${dextextbgright});
  text-align: center;
  padding: 140px 90px;
  background-position: center top;
  background-repeat: no-repeat;
  @media only screen and ${device.mobileL} {
    background-size: auto 350px;
    padding: 79px 64px;
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.tablet} {
    background-size: auto 440px;
    padding: 140px 90px;
    p {
      font-size: 20px;
    }
  }

  @media only screen and ${device.laptop} {
    background-size: auto 460px;
    padding: 140px 90px;
    p {
      font-size: 23px;
    }
  }
`;

export const TwoProblemH3Right = styled.h3`
  font-style: normal;
  font-weight: 800;
  margin: 0px 0px 25px 0px;
  line-height: 48px;
  color: #000000;
`;

export const TwoProblemPRight = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 32px;
`;

export const SolutionDiv = styled.div`
  width: 100%;
  height: 100%;
  background: #fff5e4;
  padding: 70px 60px;
`;

export const SolutionH3 = styled.h3`
  margin-bottom: 0px !important;
  font-weight: 800;
  font-size: 40px;
  line-height: 75px;
  text-align: left;
`;

export const SolutionP = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  margin-bottom: 60px;
  color: #000000;

  @media only screen and ${device.mobileL} {
    font-size: 16px;
    margin-bottom: 30px;
  }

  @media only screen and ${device.tablet} {
    font-size: 21px;
    margin-bottom: 60px;
  }

  @media only screen and ${device.laptop} {
    font-size: 24px;
    margin-bottom: 60px;
  }
`;

export const SolutionImg = styled.img`
  width: 100%;
  height: auto;
`;

export const Know = styled.section`
  width: 100%;
  height: auto;

  @media only screen and ${device.mobileL} {
    padding: 80px 0px 93px 0px;
  }

  @media only screen and ${device.tablet} {
    padding: 80px 0px 170px 0px;
  }

  @media only screen and ${device.laptop} {
    padding: 80px 0px 170px 0px;
  }
`;

export const KnowTitle = styled.h2`
  line-height: 60px !important;
  font-style: normal;
  margin-bottom: 50px;
  font-weight: bold;
  text-align: center;
  color: #000000;
`;

export const KnowP = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 90px;
  text-align: center;
  color: #000000;
`;

export const KnowSpan = styled.span`
  color: #ff9239;
`;

export const KnowText1 = styled.div`
  text-align: center;
  background-repeat: no-repeat;
  background-position: center top;
  display: inline-block;

  @media only screen and ${device.mobileL} {
    width: 100%;
    height: auto;
    background-size: auto 80%;
    padding: 115px 110px;
  }

  @media only screen and ${device.tablet} {
    width: 453px;
    height: 400px;
    background-size: auto 80%;
    padding: 130px 110px;
  }

  @media only screen and ${device.laptop} {
    width: 453px;
    height: 333px;
    background-size: auto 100%;
    padding: 130px 110px;
  }
`;

export const KnowText1H2 = styled.h5`
  width: 100%;
  height: auto;
  color: #fff;
  line-height: 26px;
`;

export const KnowText1B = styled.b`
  color: #700cbf;
  font-weight: 500;
`;

export const KnowText2 = styled.div`
  text-align: center;
  background: url(../../Images/savieztextbg1.png);
  background-repeat: no-repeat;
  background-position: center top;
  display: inline-block;

  @media only screen and ${device.mobileL} {
    width: 100%;
    height: auto;
    background-size: auto 100%;
    padding: 90px 99px;
  }

  @media only screen and ${device.tablet} {
    width: 453px;
    height: 400px;
    background-size: auto 100%;
    padding: 140px 99px;
  }

  @media only screen and ${device.laptop} {
    width: 453px;
    height: 400px;
    background-size: 500px 100%;
    padding: 140px 99px;
  }
`;

export const KnowText2H2 = styled.h5`
  width: 100%;
  height: auto;
  color: #fff;
  line-height: 26px;
`;

export const KnowText2B = styled.b`
  color: #700cbf;
  font-weight: 500;
`;

export const KnowSmall = styled.small`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  top: -100px;
  position: relative;
`;

export const Sanitary = styled.section`
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top left;
  min-height: 1800px;
  background-size: 100% auto;
  padding-bottom: 100px;
`;

export const SanitaryH2 = styled.h2`
  font-style: normal;
  margin-bottom: 50px;
  font-weight: bold;
  text-align: center;
  color: #000000;

  @media only screen and ${device.mobileL} {
    line-height: 40px;
  }

  @media only screen and ${device.tablet} {
    line-height: 60px;
  }

  @media only screen and ${device.laptop} {
    line-height: 60px;
  }
`;

export const SanitarySpan = styled.span`
  color: #ff9239;
`;

export const SanitaryBlock = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
  padding: 47px;
  text-align: center;
  font-style: normal;
  display: inline-block;
  font-weight: normal;
  line-height: 34px;
  color: #000000;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "1%")};

  @media only screen and ${device.mobileL} {
    width: 100%;
    height: auto;
    min-height: 0px;
    font-size: 16px;
  }

  @media only screen and ${device.tablet} {
    width: 100%;
    height: auto;
    min-height: 0px;
    font-size: 19px;
  }

  @media only screen and ${device.laptop} {
    width: 399px;
    height: 245px;
    min-height: 245px;
    font-size: 21px;
  }
`;

export const SanitarySmall = styled.small`
  width: 100%;
  text-align: center;
  height: auto;
  display: inline-block;
  margin-bottom: 12%;
`;

export const SanitarySecondSpan = styled.span`
  @media only screen and ${device.mobileL} {
    color: black;
  }

  @media only screen and ${device.tablet} {
    color: black;
  }

  @media only screen and ${device.laptop} {
    color: #fff;
  }
`;

export const SanitarySecondH2 = styled.h4`
  line-height: 40px !important;
  font-style: normal;
  margin-top: 130px;
  margin-bottom: 50px;
  font-weight: bold;
  text-align: center;
  color: #000000;
`;

export const SanitaryThirdH2 = styled.h4`
  line-height: 40px;
  margin-top: 39px;
  font-style: normal;
  margin-bottom: 110px;
  font-weight: bold;
  text-align: center;
  color: #000000;
`;

export const SanitaryThirdSpan = styled.span`
  color: #ff9239;
`;

export const SanitaryProgressbar = styled.span`
  width: 55%;
  height: 27px;
  background: #ff9239;
  border-radius: 28px;
  display: inline-block;
`;

export const SanitaryProgBlock = styled.div`
  margin-bottom: 20px;

  h5 {
    font-weight: 800;
  }
`;
export const SanitaryPic = styled.img`
  position: relative;

  @media only screen and ${device.mobileL} {
    top: 0px;
    left: 0px;
    width: 100%;
  }

  @media only screen and ${device.tablet} {
    top: 0px;
    left: 0px;
    width: 350px;
  }

  @media only screen and ${device.laptop} {
    top: -100px;
    left: -35px;
    width: 350px;
  }
`;

export const SanitaryPici2 = styled.img`
  position: relative;
  width: 180px;
  top: 220px;
  left: 950px;
`;
export const SanitaryImg = styled.img`
  @media only screen and ${device.mobileL} {
    width: 100%;
  }

  @media only screen and ${device.tablet} {
    width: 252px;
  }

  @media only screen and ${device.laptop} {
    width: 318px;
  }
`;

export const SanitaryImgB = styled.b`
  font-style: normal;
  font-weight: 800;
  width: 100%;
  display: inline-block;
  margin: 20px 0px 60px 0px;
  line-height: 75px;
  text-align: center;
  @media only screen and ${device.mobileL} {
    font-size: 25px;
  }

  @media only screen and ${device.tablet} {
    font-size: 45px;
  }

  @media only screen and ${device.laptop} {
    font-size: 55px;
  }
`;

export const SanitaryProdDiv = styled.div`
  // margin-top: -120px;
`;

export const Testimony = styled.section`
  width: 100%;
  height: auto;
  background-color: #c988ff;
  background-repeat: no-repeat;
  padding: 160px 40px 130px 40px;

  @media only screen and ${device.mobileL} {
    background-position: right bottom;
    padding-top: 63px;
    padding-bottom: 80px;
    background-size: 100% auto;
  }

  @media only screen and ${device.tablet} {
    background-position: right bottom;
    padding-top: 63px;
    padding-bottom: 440px;
  }

  @media only screen and ${device.laptop} {
    background-position: right top;
    padding-top: 160px;
    padding-bottom: 130px;
    background-size: auto;
  }
`;

export const TestimonyP = styled.p`
  padding: 50px 0px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
  @media only screen and ${device.mobileL} {
    font-size: 20px;
  }

  @media only screen and ${device.tablet} {
    font-size: 24px;
  }

  @media only screen and ${device.laptop} {
    font-size: 27px;
  }
`;

export const TestimonyQuote = styled.img`
  @media only screen and ${device.mobileL} {
    width: 55px;
  }

  @media only screen and ${device.tablet} {
    width: 75px;
  }

  @media only screen and ${device.laptop} {
    width: 125px;
  }
`;

export const TestimonyContent = styled.div`
  @media only screen and ${device.mobileL} {
  }

  @media only screen and ${device.tablet} {
  }

  @media only screen and ${device.laptop} {
  }
`;
export const TestimonySmall = styled.small`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  margin-top: 79px;
  display: inline-block;
  color: #ffffff;
  margin-left: 50%;
`;

export const Major = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-position: top 350px left 200px;

  @media only screen and ${device.mobileL} {
    background-image: none;
    .majbox2,
    majbox3,
    majbox4,
    majobx5 {
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
    }
  }

  @media only screen and ${device.tablet} {
    background-image: none;
    .majbox2,
    majbox3,
    majbox4,
    majobx5 {
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
    }
  }

  @media only screen and ${device.laptop} {
    background-image: url(${bgline});
    .majbox2 {
      top: 39px;
    }
    .majbox3 {
      top: 230px;
      left: -100px;
    }
    .majbox4 {
      top: -80px;
      left: 120px;,
    }
    .majbox5 {
      right: -150px;
      top: 180px;,
    }
    
  }
`;

export const Majbox = styled.div`
  position: relative;
  height: 516.02px;
  text-align: center;
  padding: 90px 80px 100px 80px;
  line-height: 41px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% auto;

  @media only screen and ${device.mobileL} {
    width: auto;
  }

  @media only screen and ${device.tablet} {
    width: 400px;
  }

  @media only screen and ${device.laptop} {
    width: 400px;
  }
`;

export const MajorH3 = styled.h3`
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 21px;
  text-align: center;
  color: #700cbf;
`;

export const MajorP = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  text-align: center;

  @media only screen and ${device.mobileL} {
    font-size: 16px;
  }

  @media only screen and ${device.tablet} {
    font-size: 17px;
  }

  @media only screen and ${device.laptop} {
    font-size: 17px;
  }
`;

export const Together = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  padding: 50px 0px;
  margin-bottom: 100px;
  margin-top: 350px;

  svg {
    position: absolute;
    bottom: 0px;
    z-index: -2;
  }

  @media only screen and ${device.mobileL} {
    .imgtogether {
      text-align: center;
    }
  }

  @media only screen and ${device.tablet} {
    .imgtogether {
      text-align: center;
    }
  }

  @media only screen and ${device.laptop} {
    .imgtogether {
      text-align: right;
    }
  }
`;

export const TogetherSVG = styled.svg`
  position: absolute;
  bottom: 0px;
  z-index: -2;
`;

export const TogetherSpan = styled.span`
  width: 200px;
  height: 55px;
  margin-right: 8%;
  display: inline-block;
  line-height: 54px;
  background: #ffffff;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  color: #000000;
  box-shadow: 0px 0px 37px rgb(0 0 0 / 25%);
  border-radius: 56px;
  text-decoration: none;

  &:hover {
    color: white;
    background: #ff9239;
    text-decoration: none;
  }
`;

export const TogetherA = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    background: #ff9239;
    color: white;
  }
`;

export const TogetherP = styled.p`
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  text-align: center;

  @media only screen and ${device.mobileL} {
    color: black;
    font-size: 22px;
  }

  @media only screen and ${device.tablet} {
    color: black;
    font-size: 27px;
  }

  @media only screen and ${device.laptop} {
    color: #ffffff;
    font-size: 27px;
  }
`;
export const Source = styled.p`
  text-align: center;
  padding-top: 10px;
  font-size: 14px;
  padding-bottom: 10px;
`;

export const TogetherB = styled.b`
  width: 100%;
  height: auto;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 55px;
  line-height: 75px;
  text-align: center;
  color: #ff9339;
`;

export const ImgTogether = styled.img`
  @media only screen and ${device.mobileL} {
    padding-right: 100px;
    width: 450px;
  }

  @media only screen and ${device.tablet} {
    padding-right: 100px;
    width: 480px;
  }

  @media only screen and ${device.laptop} {
    padding-right: 150px;

    width: 650px;
  }
`;
