import styled from "styled-components";

export const Section = styled.section`
  padding: 200px 0px;
  h2 {
    width: 100%;
    height: auto;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    line-height: 55px;
    margin-bottom: 30px;
    color: #000000;
  }
`;

export const InputForm = styled.div`
text-align: -webkit-center;
h3 {
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  text-align: left;
  color: #000000;
`;

export const Userinnerwrap = styled.div`
  height: auto;
  padding: 50px 25px;
  margin-bottom: 25px;
  min-height: 575px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 27px rgba(112, 12, 191, 0.1);
  border-radius: 38px;

  input {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 13px 20px;
    margin-bottom: 17px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #c4c4c4;
  }
  .menudropdown {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 13px 20px;
    margin-bottom: 17px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    height: 54px;
    line-height: 29px;
    color: #c4c4c4;
    box-shadow: none;
  }

  label {
    width: 100%;
    height: auto;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    font-size: 16px;
    margin-bottom: 0.2rem;
    line-height: 25px;
    color: #606060;
  }

  label span {
    font-weight: 400;
  }

  .form-group {
    display: block;
    margin-bottom: 15px;

    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    label {
      position: relative;
      cursor: pointer;
    }
    margin-top: 10px;
    font-weight: 300;

    label {
      font-weight: 400 !important;
      color: #c4c4c4 !important;
    }
    label:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #c4c4c4;
      box-shadow: none;
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 5px;
      margin-right: 10px;
    }
    input:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 6px;
      height: 17px;
      background: #ff9239;
      width: 24px;
      border-radius: 5px;
      height: 25px;
    }
  }

  .input-phone {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 0px 0px;
    margin-bottom: 17px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #c4c4c4;
  }

  .submitbutton {
    width: 247px;
    height: 49px;
    line-height: 48px;
    background: #700cbf;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: #ffffff;
    border: none;
    margin: auto;
    margin-top: 30px;
    cursor: pointer;
    box-shadow: 0px 2px 14px rgba(99, 10, 170, 0.51);
    border-radius: 35px;

    &:hover {
      background: #ff9239;
    }
  }

  .checkboxtext {
    padding-left: 0px !important;
  }
  .checkboxtext p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    line-height: 20px;
    color: #c4c4c4;
  }
`;
