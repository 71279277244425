import axios from "axios";

const apiSendinblue = axios.create({
  baseURL: process.env.REACT_APP_SENDINBLUE_PATH,
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    "api-key": `${process.env.REACT_APP_SENDINBLUE_API_KEY}`,
  },
});

export default apiSendinblue;
