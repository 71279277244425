import React from "react";
import { Section } from "./TakeatsHome.styles";

export default function TakeatsHomeSvg() {
  return (
    <Section>
      <svg
        className="d-none"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 1091.5 1440 170.5"
        enableBackground="new 0 1091.5 1440 170.5"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill="#FF9239"
            d="M626.306,1211.53c-220.984,23.6-516.281-52.5-636.306-93.511V-38h1483v1249.53
    c-90.021,93.5-276.23,35-402.261,0C944.717,1176.53,902.535,1182.03,626.306,1211.53z"
          />

          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="1193.092"
            y1="202.9585"
            x2="-9.9088"
            y2="1644.4589"
            gradientTransform="matrix(1 0 0 -1 -8.3599 1286.0547)"
          >
            <stop offset="0.5573" stopColor="#FF8B7B" stopOpacity="0.67" />
            <stop offset="0.7552" stopColor="#FFBFB6" stopOpacity="0.3682" />
            <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_1_)"
            d="M626.306,1211.53c-220.984,23.6-516.281-52.5-636.306-93.511V-38h1483v1249.53
    c-90.021,93.5-276.23,35-402.261,0C944.717,1176.53,902.535,1182.03,626.306,1211.53z"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox="0 0 1920 170"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.811"
            y1="0.185"
            x2="0"
            y2="1.294"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.557" stopColor="#ff8b7b" stopOpacity="0.671" />
            <stop offset="0.755" stopColor="#ffbfb6" stopOpacity="0.369" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
        </defs>
        <g
          id="Group_9714"
          data-name="Group 9714"
          transform="translate(10 -1272)"
        >
          <path
            id="Path_21062"
            data-name="Path 21062"
            d="M813.808,1411.082C527.706,1425.539,145.393,1378.921-10,1353.8V1272H1910v139.082c-116.548,57.277-357.627,21.441-520.8,0S1171.434,1393.01,813.808,1411.082Z"
            transform="translate(0 0)"
            fill="#ff9239"
          />
          <path
            id="Path_21063"
            data-name="Path 21063"
            d="M813.808,1411.082C527.706,1425.539,145.393,1378.921-10,1353.8V1272H1910v139.082c-116.548,57.277-357.628,21.441-520.8,0S1171.434,1393.01,813.808,1411.082Z"
            transform="translate(0 0)"
            fill="url(#linear-gradient)"
          />
        </g>
      </svg>
    </Section>
  );
}
