import React from "react";
import CopyrightComponent from "./CopyrightComponent";
import {
  FooterDiv,
  // LangageWrapper,
  ContentFooter,
  SocialDiv,
  StoreSocialDiv,
  NewsletterForm,
  NewsletterDiv,
  ConditionAgreeDiv,
  Container,
  ImgDiv,
  Link,
} from "./Footer.styles";
import FooterLogo from "../../images/footlogo.png";
import AppleStore from "../../images/downloadapp1.png";
import AndroidStore from "../../images/downloadapp2.png";
import InstagramIcon from "../../images/instagram.png";
import FacebookIcon from "../../images/facebooknew.png";
import TwitterIcon from "../../images/twitternew.png";
import LinkedinIcon from "../../images/innew.png";
import TikTokIcon from "../../images/tiktok.png";
import YoutubeIcon from "../../images/youtube-2 .png";

export default function Footer() {
  const handlePreventDefault = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <FooterDiv>
        <Container className="container ">
          <ImgDiv className="col-12">
            <img src={FooterLogo} alt="footlogo" className="logo" />
          </ImgDiv>
          <div className="row d-flex justify-content-md-around">
            <ContentFooter className="col-6 col-md-3">
              <h3>À propos de Giveats</h3>
              <ul>
                <li>
                  <a href="/">Nous rejoindre</a>
                </li>
                <li>
                  <a href="/team">L’équipe</a>
                </li>
                <li>
                  <a href="/press">Presse</a>
                </li>
              </ul>
            </ContentFooter>
            <ContentFooter className="col-6 col-md-3">
              <h3 className="mention-contact">Mentions légales</h3>
              <ul>
                <li>
                  <Link to="/terms-of-use">
                    Conditions générales d’utilisation
                  </Link>
                </li>
                <li>
                  <Link to="privacy-policy">Politique de confidentialité</Link>
                </li>
                <li>
                  <Link to="/" className="d-none">
                    Charte données personnelles
                  </Link>
                </li>
                <li>
                  <Link to="use-of-cookies">Utilisations de cookies</Link>
                </li>
              </ul>
            </ContentFooter>
            <ContentFooter className="col-4 col-md-3 foottext">
              <h3 className="mention-contact">Contact</h3>
              <ul>
                <li>
                  <a href="mailto:contact@giveats.com ">
                    <i className="fa fa-envelope"></i> contact@giveats.com{" "}
                  </a>
                </li>
                <li>
                  <a href="tel:0033695294356">
                    <i className="fa fa-phone fa-flip-horizontal"></i>{" "}
                    0033695294356
                  </a>
                </li>
              </ul>
            </ContentFooter>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <StoreSocialDiv>
                <small>Disponible dès cet été 2022</small>
                <a href="/" className="store" onClick={handlePreventDefault}>
                  <img src={AppleStore} alt="appstore" />
                </a>
                <a href="/" className="store" onClick={handlePreventDefault}>
                  <img src={AndroidStore} alt="androidstore" />
                </a>
                <SocialDiv>
                  <a
                    href="https://www.instagram.com/giveatsapp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={InstagramIcon} alt="instagramicon" />
                  </a>
                  <a
                    href="https://www.facebook.com/giveatsFR/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={FacebookIcon} alt="facebookicon" />
                  </a>
                  <a
                    href="https://twitter.com/Giveats_app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterIcon} alt="twittericon" />
                  </a>
                  <a
                    href="https://fr.linkedin.com/company/giveatsfr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={LinkedinIcon} alt="linkedinicon" />
                  </a>
                  <a
                    href="https://www.tiktok.com/@giveats?lang=fr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TikTokIcon} alt="tiktokicon" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCtWN8bvfmk6aPAFwxB_5szg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={YoutubeIcon} alt="youtubeicon" />
                  </a>
                </SocialDiv>
              </StoreSocialDiv>
            </div>
            <div className="col-12 col-sm-6">
              <NewsletterForm action="/userform" autocomplete="off">
                <h3>Restons en contact !</h3>
                <NewsletterDiv>
                  <input
                    type="hidden"
                    name="_token"
                    value="zFtEPPPFpESkL8PCsGgIRhjtHImo7E3RUI33XlDu"
                  />
                  <input
                    type="email"
                    name="subscribetoemail"
                    placeholder="Laisse ton email"
                    required=""
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    readonly="readonly"
                  />

                  <button>M’inscrire à la newsletter</button>
                </NewsletterDiv>
                <div className="row">
                  <div className="col-1 col-sm-1">
                    <input type="checkbox" name="agree" required="" />
                  </div>

                  <ConditionAgreeDiv className="col-12 col-md-10">
                    <p>
                      En acceptant de t’abonner à notre newsletter, tu acceptes
                      de recevoir de nos nouvelles par email
                    </p>
                    <small>
                      Pas de pubs, pas de partage de données personnelles à des
                      fins commerciales. Tu peux te désabonner à tout moment en
                      cliquant sur le lien de désabonnement en bas de nos
                      newsletters.
                    </small>
                  </ConditionAgreeDiv>
                </div>
              </NewsletterForm>
            </div>
          </div>
        </Container>
      </FooterDiv>
      <CopyrightComponent />
    </>
  );
}
