import styled from "styled-components";
import PressTextBg from "../../images/presstextbg.png";
import { device } from "../../theme/Device";

export const Container = styled.div`
  min-height: 692px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  background: white;

  .pressbannner {
    width: 100%;
    height: auto;
    background: #ff9239;
    color: #fff;
    padding: 170px 0px 0px 0px;
    line-height: 0px;

    h1 {
      font-weight: 800;
      line-height: 60px;
      color: #ffffff;
      padding-left: 13%;
      padding-top: 3%;
    }
  }

  .preecontentmain {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url(${PressTextBg});
    padding-bottom: 50px;

    h2 {
      font-weight: 800;
      width: 100%;
      text-align: center;
      line-height: 75px;
      text-align: center;
      color: #263238;
      margin-bottom: 35px;
    }
    p {
      margin-bottom: 25px;
      line-height: 30px;
      color: #000000;
    }
  }

  .mainvideo {
    margin-top: 50px;

    .videomain {
      text-align: center;
      margin: 160px auto;
    }
    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .ressourcesmedias {
    margin-top: 60px;

    h2 {
      font-weight: 800;
      width: 100%;
      line-height: 75px;
      text-align: center;
      color: rgb(38, 50, 56);
    }

    .uploadbox {
      display: inline-block;
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 36px;
      background: rgb(255, 255, 255);
      border-radius: 29px;

      span {
        width: 100%;
        font-weight: 800;
        display: inline-block;
        margin-bottom: 20px;
        line-height: 41px;
        color: rgb(38, 50, 56);
        height: auto;
        text-align: center;
      }
      b a {
        color: rgb(0, 0, 0);
        text-decoration: none;
      }

      small a {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        width: 100%;
        text-decoration-line: underline;
        color: rgb(112, 12, 191);
      }
    }

    .uploadfilinput {
      width: 100%;
      height: auto;
      position: relative;
      display: inline-block;
      margin: 65px 0px 50px;
    }
  }
  @media only screen and ${device.mobileL} {
    .uploadbox {
      width: 290px;
      height: auto;
      margin: 60px 0px;
    }
    .ressourcesmedias span {
      font-size: 20px;
    }
    .uploadfilinput {
      margin: 40px 0px 50px;
    }
    .preecontentmain {
      p {
        font-size: 16px;
      }
    }
  }
  @media only screen and ${device.tablet} {
    .uploadbox {
      width: 350px;
      height: 350px;
      margin: 110px 0px;
    }
    .ressourcesmedias span {
      font-size: 22px;
    }
    .uploadfilinput {
      margin: 65px 0px 50px;
    }
    .preecontentmain {
      p {
        font-size: 18px;
      }
    }
  }
  @media only screen and ${device.laptop} {
    .uploadbox {
      width: 350px;
      height: 350px;
      margin: 110px 0px;
    }
    .ressourcesmedias span {
      font-size: 22px;
    }
    .uploadfilinput {
      margin: 65px 0px 50px;
    }
    .preecontentmain {
      p {
        font-size: 20px;
      }
    }
  }
`;

export const ItemCarousel = styled.div`
  text-align: center;
`;

export const H3 = styled.h4`
  font-style: normal;
  font-weight: bold;
  margin-bottom: 28px;
  font-size: 55px;
  line-height: 75px;
  text-align: center;
  color: #000000;
`;
