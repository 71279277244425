import React from "react";
import {
  BecomeDiv,
  ImageDiv,
  Ul,
  ThreeStepDiv,
  TitleStepDiv,
  StepCard,
  BtnStepDiv,
  MerchantDiv,
  TitleMerchantDiv,
  ImageMerchantDiv,
  MerchantCard,
  DivBtnStepDiv,
} from "./Become.styles";
import BtnRestaurant from "../../buttons/BtnRestaurant";
import TabImage from "../../../images/Vector-150.png";
import ForkSpoon from "../../../images/Unicon1.png";
import FoodMenu from "../../../images/Unicon2.png";
import CreditCard from "../../../images/Unicon3.png";
import RestaurantIcon from "../../../images/tousimg1.png";
import EpiceriesIcon from "../../../images/tousimg2.png";
import BakeryIcon from "../../../images/tousimg3.png";
import TraiteurIcon from "../../../images/tousimg4.png";

export default function Become() {
  return (
    <>
      <BecomeDiv>
        <div className="container">
          <h2>
            Comment devenir un restaurant partenaire <br />
            de Giveats ?{" "}
          </h2>
          <div className="row">
            <div className="col-lg-8 col-12">
              <Ul>
                <li>
                  <span>1</span>
                  <p>
                    Inscrivez-vous et renseignez les informations de votre
                    établissement. Choisissez la manière dont vous souhaitez
                    être payé
                  </p>
                </li>

                <li>
                  <span>2</span>
                  <p>
                    Ajoutez les membres de votre équipe (Gérants, managers,
                    serveurs, équipe de cuisine).{" "}
                  </p>
                </li>

                <li>
                  <span>3</span>
                  <p>
                    Mettez en ligne votre menu, sélectionnez vos plats servis
                    sur place, en livraison ou à emporter. Indiquez vos horaires
                  </p>
                </li>

                <li>
                  <span>4</span>
                  <p>
                    Vos clients passent les premières commandes. Traitez vos
                    commandes et consultez celles à venir
                  </p>
                </li>

                <li>
                  <span>5</span>
                  <p>
                    Répondez aux commentaires clients. Faites le suivi de votre
                    activité
                  </p>
                </li>
              </Ul>
            </div>
          </div>
          <ImageDiv className="mobilehide">
            <img src={TabImage} alt="tabimage" />
          </ImageDiv>
        </div>
      </BecomeDiv>
      <ThreeStepDiv>
        <div className="container">
          <div className="row">
            <TitleStepDiv className="col-sm-12 col-12">
              <h2>
                Un partenariat avec Giveats <br></br>
                en trois étapes{" "}
              </h2>
            </TitleStepDiv>
            <StepCard className="col-sm-4 col-12">
              <h5>
                <b>1</b> <br />
                Ajouter l’adresse du <br />
                restaurant et les <br />
                coordonnées
              </h5>
              <br></br>
              <img src={ForkSpoon} alt="forkspoon" />
            </StepCard>

            <StepCard className="col-sm-4 col-12">
              <h5>
                <b>2</b> <br />
                Télécharger le menu <br /> et les horaires de <br /> collecte ou
                de livraison
              </h5>
              <br></br>
              <img src={FoodMenu} alt="foodmenu" />
            </StepCard>

            <StepCard className="col-sm-4 col-12">
              <h5>
                <b>3</b> <br />
                Choisir comment <br />
                vous souhaitez recevoir <br />
                les paiements
              </h5>
              <br></br>
              <img src={CreditCard} alt="creditcard" />
            </StepCard>

            <DivBtnStepDiv className="col-sm-12 col-12">
              <span>
                <BtnRestaurant content="Je me lance" />
              </span>
            </DivBtnStepDiv>
          </div>
        </div>
      </ThreeStepDiv>
      <MerchantDiv>
        <div className="container">
          <div className="row">
            <TitleMerchantDiv className="col-sm-12 col-12">
              <h2>
                Pour <span>tous</span> les commerçants
              </h2>
            </TitleMerchantDiv>
          </div>
          <ImageMerchantDiv className="row">
            <MerchantCard className="col-sm-6 col-6 ">
              <img src={RestaurantIcon} alt="restaurants" />
              <h5>RESTAURANTS</h5>
            </MerchantCard>

            <MerchantCard className="col-sm-6 col-6">
              <img src={EpiceriesIcon} alt="epiceries" />
              <h5>ÉPICERIES</h5>
            </MerchantCard>

            <MerchantCard className="col-sm-6 col-6">
              <img src={BakeryIcon} alt="boulangeries" />
              <h5>BOULANGERIES</h5>
            </MerchantCard>

            <MerchantCard className="col-sm-6 col-6">
              <img src={TraiteurIcon} alt="traiteurs" />
              <h5>TRAITEURS</h5>
            </MerchantCard>
          </ImageMerchantDiv>
        </div>
      </MerchantDiv>
    </>
  );
}
