import React from "react";
import {
  Banner,
  TitleBannerDiv,
  TextContainer,
  Section,
} from "./TermsOfUse.styles";

export default function Mentions() {
  return (
    <Section>
      <Banner>
        <div className="container">
          <TitleBannerDiv className="col-sm-8">
            <h1>Conditions générales d'utilisations</h1>
          </TitleBannerDiv>
        </div>
      </Banner>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 342.318 1452 373.682"
        enable-background="new 0 342.318 1452 373.682"
        xmlSpace="preserve"
        className="svg"
      >
        <rect
          x="-27.756"
          y="342.318"
          fill="#FFFFFF"
          stroke="#000000"
          stroke-miterlimit="10"
          width="1548.155"
          height="537.054"
        ></rect>
        <path
          fill="#FF9239"
          d="M669.462,550.5c-289,240.4-567.058,165.5-669.961,98V0h726.25H1452v385
C1311.573,340,958.46,310.1,669.462,550.5z"
        ></path>
      </svg>
      <TextContainer className="container col-10">
        <h3 class="">Objet</h3>
        <p>
          La société Giveats (ci-après « Giveats ») exploite une plateforme en
          ligne accessible à l’adresse{" "}
          <a href="www.giveats.com" target="_blank">
            www.giveats.com
          </a>
          ,{" "}
          <a href="www.giveats.fr" target="_blank">
            www.giveats.fr
          </a>
          ,{" "}
          <a href="www.giveats.io" target="_blank">
            www.giveats.io
          </a>{" "}
          ou via une application mobile compatible avec iOS ou Android (ci-après
          ensemble et indifféremment la « Plateforme »), à travers laquelle elle
          propose à des consommateurs (ci-après les « Utilisateurs ») une
          solution leur permettant d’être mis en relation avec un restaurant
          référencé (ci-après le « Restaurant ») afin de commander un repas
          (ci-après « Repas ») à consommer sur place, à emporter ou à se faire
          livrer et de réaliser un micro-don alimentaire au profit de personnes
          en situation de précarité pour une aide alimentaire collaboratif ( en
          partenariat avec des associations caritatives).
        </p>
        <p>
          Les Utilisateurs et les Restaurants sont ci-après désignés ensemble ou
          individuellement les « Parties ».
        </p>
        <p>
          Les Utilisateurs reconnaissent et acceptent que Giveats n’est en aucun
          cas partie à la relation contractuelle les liant au Restaurant,
          Giveats n’étant en charge que de la mise en relation entre les
          Parties.
        </p>
        <p>
          Les présentes conditions générales (ci-après : « les Conditions
          Générales ») ont pour objet de définir les modalités et conditions
          d’utilisation des services proposés par Giveats (ci-après : les
          « Services ») ainsi que de définir les droits et obligations des
          parties dans ce cadre.
        </p>
        <p>
          Elles sont accessibles et imprimables à tout moment par un lien direct
          en page d’accueil de la Plateforme.
        </p>
        <h3 class="">Exploitant de la Plateforme et des Services, contact</h3>
        <p>
          La Plateforme et les Services sont exploités par Giveats, SAS
          immatriculée au RCS de Pontoise sous le n° 883 632 663, dont le siège
          social est situé 21 rue Vincent van Gogh – 95170 Deuil-la-Barre.
        </p>
        <p>
          Giveats peut être contactée à l’adresse suivante, notamment pour toute
          réclamation :
        </p>
        <p>Adresse postale : [21 Rue Vicent Van gogh, 95170, Deuil-La-Barre]</p>
        <p>Téléphone : [ 06 95 29 43 56 ]</p>
        <p>Adresse électronique : [contact@giveats.com]</p>
        <h3>Accès à la Plateforme et aux Services</h3>
        <h4>Capacité juridique </h4>
        <p>La Plateforme et les Services sont accessibles :</p>
        <ul>
          <li>
            A toute personne physique disposant de la pleine capacité juridique
            pour s’engager au titre des présentes Conditions Générales. La
            personne physique qui ne dispose pas de la pleine capacité juridique
            ne peut accéder à la Solution et aux Services qu’avec l’accord de
            son représentant légal.
          </li>
          <li>
            A toute personne morale agissant par l’intermédiaire d’une personne
            physique disposant de la capacité juridique pour contracter au nom
            et pour le compte de la personne morale.
          </li>
        </ul>
        <h4>Plateforme et Services réservés aux particuliers </h4>
        <p>
          La Plateforme et les Services s’adressent exclusivement aux
          particuliers et ne sont pas destinés à un usage professionnel, entendu
          comme tout usage lié directement ou indirectement à une activité
          rémunérée exercée de façon non occasionnelle dans tous les secteurs
          d’activité de l’industrie et du commerce.
        </p>
        <h3>Acceptation des Conditions Générales</h3>
        <p>
          L’acceptation des présentes Conditions Générales par les Utilisateurs
          qui s’inscrivent
        </p>
        <ul>
          <li>
            sur la Plateforme est matérialisée par une case à cocher dans le
            formulaire d’inscription.
          </li>
          <li>
            S’agissant des Utilisateurs non-inscrits sur la Plateforme, leur
            utilisation de celle-ci /ou des Services entraîne leur acceptation
            des présentes Conditions Générales, sans restriction ni réserve.
          </li>
        </ul>
        <p>
          L’acceptation des présentes Conditions Générales ne peut être que
          pleine et entière.
        </p>
        <ul>
          <li>
            Toute adhésion sous réserve est considérée comme nulle et non
            avenue. L’Utilisateur qui n’accepte pas d’être lié par les présentes
            Conditions Générales ne doit pas accéder à la Plateforme ni utiliser
            les Services.
          </li>
        </ul>
        <h3>
          {" "}
          Articulation avec l’intervention et les Conditions Générales de Stripe
        </h3>
        <p>
          Les Utilisateurs sont expressément informés et acceptent que tous les
          paiements effectués à travers la Plateforme, sont gérés par la société
          Stripe Payments Europe Ltd, société agréée en tant qu’établissement de
          monnaie électronique, dont le siège social est situé à Dublin
          (Irlande) (ci-après « Établissement de monnaie électronique »).
        </p>
        <p>
          Les Utilisateurs contractent directement avec l’Établissement de
          monnaie électronique s’agissant de la mise en œuvre de ces paiements,
          en acceptant les conditions générales de l’Établissement de monnaie
          électronique par le biais d’une case à cocher lors de leur inscription
          sur la Plateforme.
        </p>
        <h3>Inscription</h3>
        <ul>
          <li>
            Pour utiliser les Services, l’Utilisateur doit s’inscrire sur la
            Plateforme et :
          </li>
          <ul>
            <li>
              soit remplir le formulaire d’inscription disponible sur la
              Plateforme ;
            </li>
            <li>
              soit être inscrit à l’un des sites tiers indiqués sur la
              Plateforme et utiliser ses identifiants de connexion audit site
              tiers pour s’inscrire
            </li>
          </ul>
        </ul>
        <p>
          sur la Plateforme. Dans ce cas, il doit fournir à Giveats le cas
          échéant les informations complémentaires demandées. L’Utilisateur
          autorise expressément Giveats à accéder aux données de son compte sur
          le site tiers concerné.
        </p>
        <p>
          Dans tous les cas, l’Utilisateur doit fournir l’ensemble des
          informations marquées comme obligatoires. Toute inscription incomplète
          ne sera pas validée.
        </p>
        <ul>
          <li>
            L’Utilisateur garantit que toutes les informations qu’il donne dans
            le formulaire d’inscription sont exactes, à jour et sincères et ne
            sont entachées d’aucun caractère trompeur.
          </li>
          <ul>
            <li>
              Il s’engage à mettre à jour ces informations dans son Espace
              Personnel en cas de modifications, afin qu’elles correspondent
              toujours aux critères susvisés.
            </li>
            <li>
              L’Utilisateur est informé et accepte que les informations saisies
              aux fins de création ou de mise à jour de son Compte vaillent
              preuve de son identité. Les informations saisies par l’Utilisateur
              l’engagent dès leur validation.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            L’Utilisateur peut accéder à tout moment à son Espace Personnel
            après s’être identifié à l’aide de son identifiant de connexion
            ainsi que de son mot de passe.
          </li>
          <li>
            L’Utilisateur s’engage à utiliser personnellement les Services et à
            ne permettre à aucun tiers de les utiliser à sa place ou pour son
            compte, sauf à en supporter l’entière responsabilité.
          </li>
          <li>
            Il est pareillement responsable du maintien de la confidentialité et
            de la sécurité de son identifiant et de son mot de passe, tout accès
            à la Plateforme à l’aide de ces derniers étant réputé effectué par
            l’Utilisateur. Celui-ci doit immédiatement contacter Giveats s’il
            remarque que son Compte a été utilisé à son insu. Il reconnaît à
            Giveats le droit de prendre toutes mesures appropriées en pareil
            cas.
          </li>
        </ul>
        <h3>Services</h3>
        <p>
          Avant toute souscription en ligne et en application notamment des
          dispositions de l’article L111-1 du Code de la consommation,
          l’Utilisateur peut prendre connaissance des Services sur la
          Plateforme.
        </p>
        <p>
          L’Utilisateur a accès aux Services qui suivent, sous une forme et
          selon les fonctionnalités et moyens techniques que Giveats juge les
          plus appropriés.
        </p>
        <ul>
          <li>Commande d’un Repas ou réservation d’une table</li>
          <li>
            En fonction de sa géolocalisation, l’Utilisateur a directement accès
            depuis la Plateforme aux menus, caractéristiques et prix des Repas
            proposés par les Restaurants depuis la Plateforme.
          </li>
          <li>
            La Plateforme permet à l’Utilisateur de le mettre en relation avec
            des Restaurants afin d’effectuer une demande de réservation d’une
            table dans l’établissement d’un Restaurant (ci-après
            « Réservation ») ou d’une réaliser une commande de Repas. Giveats
            agit exclusivement comme intermédiaire dans le cadre de la
            réservation des tables ou de commande de Repas par l’Utilisateur.
          </li>
          <li>
            Une fois le Repas sélectionné, et en fonction de la disponibilité,
            l’Utilisateur aura la possibilité de choisir le mode de retrait ou
            de consommation du Repas. Il peut alors choisir de :
          </li>
          <li>
            Consommer le Repas sur place dans l’établissement du Restaurant et
            effectuer Réservation ;
          </li>
          <li>
            Sélectionner l’option « click and collect » et récupérer directement
            lui-même le Repas dans l’établissement du Restaurant ;
          </li>
          <li>
            Se faire livrer le Repas à l’adresse qu’il indiquera. La livraison
            du Repas ne sera possible que si l’adresse se situe dans le secteur
            géographique de l’établissement du Restaurant.
          </li>
        </ul>
        <p>
          Une fois le Repas sélectionné ainsi que le mode de retrait ou de
          consommation de celui-ci, l’Utilisateur valide la commande (ci-après
          la « Commande »).
        </p>
        <p>
          Le prix total de la Commande est alors indiqué. Celui-ci comprend le
          prix du Repas et de la livraison si ce mode de retrait a été choisi
          par l’Utilisateur.
        </p>
        <ul>
          <li>
            Chaque Commande passée sur la Plateforme sera transmise au
            Restaurant pour obtenir une confirmation de sa part. La Commande est
            définitive une fois qu’elle a été acceptée par le Restaurant. Une
            fois la Commande acceptée par le Restaurant, celle-ci ne peut plus
            être annulée par l’Utilisateur et le prix de la Commande devra être
            payé intégralement.
          </li>
          <li>
            A l’issue de la Commande, l’Utilisateur sera invité à laisser un
            commentaire et à noter le Restaurant auprès duquel il a effectué une
            Commande.
          </li>
        </ul>
        <h3>Micro-don alimentaire</h3>
        <p>
          A la fin du processus de Commande, l’Utilisateur peut choisir de
          réaliser un micro-don alimentaire permettant d’offrir un Repas
          (ci-après « Don ») destiné à une association partenaire de Giveats
          (ci-après « Association »).
        </p>
        <p>
          Si l’Utilisateur souhaite réaliser un Don, il doit sélectionner la
          valeur du Don qui peut être de 1 (un), 2 (deux), 3 (trois), 4 (quatre)
          ou 5 (cinq) euros.
        </p>
        <p>
          Si l’Utilisateur ne souhaite pas réaliser de Don, il doit sélectionner
          la valeur 0 (zéro).
        </p>
        <h3> Partage de l’addition</h3>
        <p>
          L’Utilisateur peut bénéficier du service de partage de l’addition
          permettant de calculer le montant dû par chacune des personnes ayant
          partagé un Repas ensemble, en fonction de la consommation de chacun.
        </p>
        <p>
          L’Utilisateur peut décider d’avertir les personnes concernées du
          montant dû en indiquant leur numéro de téléphone sur la Plateforme.
          Les personnes concernées recevront ainsi un SMS leur indiquant le
          montant à rembourser à l’Utilisateur ainsi qu’un lien vers un moyen de
          paiement pour rembourser l’Utilisateur.
        </p>
        <ul>
          <li>
            L’ensemble des Services susvisés seront rendus selon les modalités
            que Giveats jugera les plus appropriées.
          </li>
        </ul>
        <h3>Assistance technique</h3>
        <p>
          Giveats propose à l’Utilisateur une assistance technique accessible
          directement depuis la Plateforme via l’onglet « Aide et Support » ou
          par le chatbot sur le site internet, lui permettant de déclarer toute
          difficulté rencontrée lors de l’utilisation des Services.
        </p>
        <h3> Autres Services</h3>
        <p>
          Giveats se réserve la possibilité de proposer tout autre Service
          qu’elle jugera utile, sous une forme et selon les fonctionnalités et
          moyens techniques qu’elle estimera les plus appropriés pour rendre
          lesdits Services.
        </p>
        <h3> Hébergement de la Plateforme</h3>
        <p>
          Giveats s’engage à assurer, dans les termes d’une obligation de
          moyens, l’hébergement des Comptes et de tout contenu publié par
          l’Utilisateur sur son Compte conformément aux usages de la profession
          et à l’état de l’art, sur ses propres serveurs ou par un prestataire
          d’hébergement professionnel, exerçant son activité conformément aux
          usages de la profession et à l’état de l’art.
        </p>
        <p>
          Dans ce cadre, Giveats s’engage à fournir à l’Utilisateur des
          capacités de stockage et de traitement suffisantes dans le cadre des
          Services, conformément aux usages de la profession et à l’état de
          l’art.
        </p>
        <p>
          Giveats s’engage à mettre en œuvre l’ensemble des moyens techniques,
          conformes à l’état de l’art, nécessaires pour assurer la sécurité et
          l’accès aux Services, portant sur la protection et la surveillance des
          infrastructures, le contrôle de l’accès physique et/ou immatériel
          auxdites infrastructures, ainsi que sur la mise en œuvre des mesures
          de détection, de prévention et de récupération pour protéger les
          serveurs d’actes malveillants.
        </p>
        <p>
          Giveats s’engage également à prendre toutes précautions utiles, au
          regard de la nature des données et des risques présentés par les
          traitements automatisés de données mis en œuvre pour les besoins des
          Services, pour préserver la sécurité des données, et notamment
          empêcher qu’elles soient déformées, endommagées ou que des tiers non
          autorisés y aient accès.
        </p>
        <h3> Conditions financières</h3>
        <h3> Services gratuits</h3>
        <p>
          L’accès à la Plateforme et aux Services sont fournis aux Utilisateurs
          à titre gratuit.
        </p>
        <p>
          Les Utilisateurs n’auront à régler que le prix de leurs Commandes et
          du Don s’ils souhaitent en réaliser un, selon les modalités prévues à
          l’article suivant.
        </p>
        <h3>
          Prix et paiement des Commandes de Repas passées sur la Plateforme
        </h3>
        <p>
          Le prix total de la Commande indiqué à l’Utilisateur sur la Plateforme
          est exigible à la fin de la passation de Commande.
        </p>
        <p>
          Le paiement sera réalisé directement à Giveats, agissant au nom et
          pour le compte du Restaurant.
        </p>
        <p>
          Le paiement peut s’effectuer en ligne par carte bancaire à travers le
          service de paiement sécurisé en ligne Stripe qui seule conserve les
          coordonnées bancaires de l’Utilisateur à cette fin. Giveats ne
          conserve aucune coordonnée bancaire.
        </p>
        <p>
          L’Utilisateur garantit à Giveats qu’il dispose des autorisations
          nécessaires pour utiliser le mode de paiement choisi.
        </p>
        <p>
          Giveats adressera à l’Utilisateur par email et directement depuis son
          Espace Membre un récapitulatif sa facture.
        </p>
        <h3>
          Renonciation expresse au droit de rétractation concernant les Services
        </h3>
        <p>
          Les Utilisateurs sont informés qu’un droit de rétractation s’applique
          en principe aux contrats de prestations de services conclus à distance
          entre un professionnel et un consommateur, ce droit étant à exercer
          dans les 14 (quatorze) jours à compter de la conclusion du contrat.
        </p>
        <p>
          Ils sont toutefois expressément informés et acceptent que les Services
          leurs sont fournis dès leur inscription et sont ainsi pleinement
          exécutés avant la fin du délai de rétractation visé ci-dessus. En
          conséquence, ils renoncent expressément à leur droit de rétractation,
          qui ne pourra donc pas être exercé, conformément à l’article L.221-28
          du Code de la consommation.
        </p>
        <h3> Exclusion du droit de rétractation concernant les Commandes</h3>
        <p>
          Les Utilisateurs sont informés qu’un droit de rétractation s’applique
          en principe aux contrats de prestations de services conclus à distance
          entre un professionnel et un consommateur, ce droit étant à exercer
          dans les 14 (quatorze) jours à compter de la conclusion du contrat.
        </p>
        <p>
          Or, en application des dispositions de l’article L.221-28 du Code de
          la consommation, le droit de rétractation applicable en matière de
          vente à distance ne peut être exercé dans le cas de la fourniture de
          biens qui sont susceptibles de se détériorer ou de se périmer
          rapidement.
        </p>
        <p>
          Les Utilisateurs sont donc expressément informés et acceptent que
          toute Commande passée sur la Plateforme est ferme et définitive et que
          l’exercice du droit de rétractation est exclu.
        </p>
        <h3> Durée des Services et désinscription</h3>
        <p>Les Services sont souscrits pour une durée indéterminée.</p>
        <p>
          L’Utilisateur peut se désinscrire des Services à tout moment, en
          adressant une demande à cet effet à Giveats par email, aux coordonnées
          mentionnées à l’article « Exploitant de la Plateforme et des Services,
          contact ».
        </p>
        <p>
          La désinscription est effective dans un délai maximum de 7 (sept)
          jours à compter de cette demande. Elle entraîne la suppression
          automatique du Compte de l’Utilisateur.
        </p>
        <p>
          Giveats se réserve par ailleurs le droit de fermer et supprimer tout
          Compte qui resterait inactif pendant une durée continue de 1 (un) an.
        </p>
        <h3>Convention de preuve</h3>
        <p>L’Utilisateur reconnaît et accepte expressément :</p>
        <ul>
          <li>
            que les données recueillies sur la Plateforme et les équipements
            informatiques de Giveats font foi de la réalité des opérations
            intervenues dans le cadre des présentes,
          </li>
          <li>
            que ces données constituent le principal mode de preuve admis entre
            les Parties.
          </li>
        </ul>
        <h3>Obligations et responsabilité du Utilisateur</h3>
        <p>
          Sans préjudice des autres obligations prévues aux présentes Conditions
          Générales, l’Utilisateur s’engage à respecter les obligations qui
          suivent :
        </p>
        <ul>
          <li>
            L’Utilisateur s’engage, dans son usage des Services, à respecter les
            Conditions Générales, ainsi que les lois et règlements en vigueur,
            et à ne pas porter atteinte aux droits de tiers ou à l’ordre public.
            <p>
              Il est seul responsable de son utilisation de la Plateforme et des
              Services.
            </p>
          </li>
          <li>
            L’Utilisateur s’engage à fournir à Giveats toutes les informations
            nécessaires à la bonne exécution des Services. Plus généralement
            l’Utilisateur s’engage à coopérer activement avec Giveats en vue de
            la bonne exécution des Conditions Générales.
          </li>
          <li>
            L’Utilisateur est seul responsable des documents, éléments, données,
            informations et tout contenu qu’il fournit à Giveats dans le cade de
            l’utilisation des Services. Il garantit à Giveats qu’il est habilité
            à lui fournir ces éléments et qu’il dispose de tous les droits et
            autorisations nécessaires à leur exploitation dans le cadre des
            Services.
          </li>
          <li>
            L’Utilisateur reconnaît avoir pris connaissance de la Plateforme et
            de ses caractéristiques.
          </li>
          <li>
            L’Utilisateur s’engage à faire un usage strictement personnel des
            Services. Il s’interdit en conséquence de céder, concéder ou
            transférer tout ou partie de ses droits ou obligations au titre des
            présentes à un tiers, de quelque manière que ce soit.
          </li>
          <li>
            L’Utilisateur est seul responsable des contenus de toute nature
            (rédactionnels, graphiques, audios, audiovisuels ou autres) qu’il
            publie sur la Plateforme (ci-après les « Contenus ») et de toute
            conséquence qui en découlerait.
            <p>
              L’Utilisateur accepte que les Contenus publiés sur la Plateforme
              soient rendus publics par défaut et sont susceptibles d’être vus
              par d’autres utilisateurs de la Plateforme.
            </p>
          </li>
          <li>
            L’Utilisateur garantit à Giveats qu’il dispose de tous les droits et
            autorisations nécessaires à la diffusion de ces Contenus. Il
            s’engage à ce que lesdits Contenus soient licites, ne portent pas
            atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers,
            n’enfreignent aucune disposition législative ou règlementaire et
            plus généralement, ne soient aucunement susceptibles de mettre en
            jeu la responsabilité civile ou pénale de Giveats.
            <p>
              L’Utilisateur s’interdit ainsi de diffuser, notamment et sans que
              cette liste soit exhaustive :
            </p>
            <ul>
              <li>
                des Contenus pédopornographiques, pornographiques,
                diffamatoires, injurieux, racistes, obscènes, indécents,
                choquants, violents, xénophobes ou révisionnistes,
              </li>
              <li>des Contenus contrefaisants,</li>
              <li>des Contenus attentatoires à l’image d’un tiers,</li>
              <li>
                des Contenus mensongers, trompeurs ou proposant ou promouvant
                des activités illicites, frauduleuses ou trompeuses,
              </li>
              <li>
                et plus généralement des Contenus susceptibles de porter
                atteinte aux droits de tiers ou d’être préjudiciables à des
                tiers, de quelque manière et sous quelque forme que ce soit.
              </li>
            </ul>
          </li>
        </ul>
        <h3> Garantie de l’Utilisateur</h3>
        <ul>
          <li>
            L’Utilisateur garantit Giveats contre toutes plaintes, réclamations,
            actions et/ou revendications quelconques
            <p>
              qu’elle pourrait subir du fait de la violation, par l’Utilisateur
              de l’une quelconque de ses obligations ou garanties aux termes des
              présentes Conditions Générales.
            </p>
            <p>
              L’Utilisateur s’engage à indemniser Giveats de tout préjudice
              qu’elle subirait et à lui payer tous les frais, charges et/ou
              condamnations qu’elle pourrait avoir à supporter de ce fait.
            </p>
          </li>
          <li>
            L’Utilisateur confirme avoir au moins 18 ans lorsqu’il effectue une
            Commande contenant de l’alcool. Il est expressément informé que le
            Restaurant ou le livreur se réserve le droit de ne pas accepter la
            Commande ou de refuser d’effectuer la livraison s’il ne respecte pas
            les conditions passation de Commande indiquées sur la Plateforme,
            notamment concernant la vente d’alcool.
          </li>
        </ul>
        <h3>Responsabilité et garantie de Giveats</h3>
        <ul>
          <li>
            Giveats s’engage à fournir les Services avec diligence et selon les
            règles de l’art, étant précisé qu’il pèse sur elle une obligation de
            moyens, à l’exclusion de toute obligation de résultat, ce que
            l’Utilisateur reconnaît et accepte expressément.
          </li>
          <li>
            Giveats intervient exclusivement aux fins de fourniture des Services
            décrits aux présentes Conditions Générales.
          </li>
          <li>
            Giveats n’est pas partie aux relations entre l’Utilisateur et le
            Restaurant et ne saurait en aucun cas voir sa responsabilité engagée
            au titre des éventuelles difficultés liées à ces relations, ni être
            partie à quelques litiges éventuels que ce soit, concernant
            notamment la commande ou la livraison d’un repas, la réservation
            d’une table, la qualité du repas consommé, les garanties,
            déclarations et autres obligations quelconques auxquelles le
            Utilisateur serait tenu.
          </li>
          <li>
            Giveats n’est pas non plus en possession physique des Repas proposés
            à travers la Plateforme.
            <p>
              Elle ne pourrait voir sa responsabilité engagée s’agissant (i) de
              la conformité des offres des Restaurants aux lois et règlement en
              vigueur (ii) de la conformité des Repas, de leur conditionnement
              et/ou étiquetage aux dispositions légales et réglementaires (iii)
              de la conformité de la livraison des Repas aux stipulations
              contractuelles et de l’existence de possibles vices cachés, (iv)
              des risques liés au transport, au stockage, et à leur conservation
              et la remise des Repas, ou encore (v) du respect des règles
              d’hygiène et d’entretien, des responsabilités incombant au seul
              Restaurant.
            </p>
          </li>
          <li>
            Giveats ne garantit pas à l’Utilisateur que les Services proposés
            satisferont l’ensemble de ses besoins et attentes.
          </li>
          <li>
            Giveats s’engage à procéder régulièrement à des contrôles afin de
            vérifier le fonctionnement et l’accessibilité de la Plateforme. A ce
            titre, Giveats se réserve la faculté d’interrompre momentanément
            l’accès à la Plateforme pour des raisons de maintenance.
            <p>
              De même, Giveats ne saurait être tenue responsable des difficultés
              ou impossibilités momentanées d’accès à sa Plateforme qui auraient
              pour origine des circonstances qui lui sont extérieures, la force
              majeure, ou encore qui seraient dues à des perturbations des
              réseaux de télécommunication, les Utilisateurs étant informés de
              la complexité des réseaux mondiaux et de l’afflux, à certaines
              heures, des utilisateurs d’internet.
            </p>
          </li>
          <li>
            Les Services sont fournis par Giveats tels quels et sans garantie
            d’aucune sorte, expresse ou implicite. Giveats ne garantit notamment
            pas aux Utilisateurs (i) que les Services, soumis à une recherche
            constante pour en améliorer notamment la performance et le progrès,
            seront totalement exempts d’erreurs, de vices ou défauts, (ii) que
            les Services, étant standard et nullement proposés à la seule
            intention d’un Utilisateur donné en fonction de ses propres
            contraintes personnelles, répondront spécifiquement à ses besoins et
            attentes.
          </li>
        </ul>
        <h3> Comportements prohibés</h3>
        <ul>
          <li>
            Il est strictement interdit d’utiliser les Services aux fins
            suivantes :
            <ul>
              <li>
                l’exercice d’activités illégales, frauduleuses ou portant
                atteinte aux droits ou à la sécurité des tiers,
              </li>
              <li>
                l’atteinte à l’ordre public ou la violation des lois et
                règlements en vigueur,
              </li>
              <li>
                l’intrusion dans le système informatique d’un tiers ou toute
                activité de nature à nuire, contrôler, interférer, ou
                intercepter tout ou partie du système informatique d’un tiers,
                en violer l’intégrité ou la sécurité,
              </li>
              <li>
                les manipulations destinées à améliorer le référencement d’un
                site tiers,
              </li>
              <li>
                l’aide ou l’incitation, sous quelque forme et de quelque manière
                que ce soit, à un ou plusieurs des actes et activités décrits
                ci-dessus,
              </li>
              <li>
                et plus généralement toute pratique détournant les Services à
                des fins autres que celles pour lesquelles ils ont été conçus.
              </li>
            </ul>
          </li>
          <li>
            Il est strictement interdit aux Utilisateurs de copier et/ou de
            détourner à leurs fins ou à celles de tiers le concept, les
            technologies, tout ou partie des données ou tout autre élément de la
            Plateforme.
          </li>
          <li>
            Sont également strictement interdits : (i) tous comportements de
            nature à interrompre, suspendre, ralentir ou empêcher la continuité
            des Services, (ii) toutes intrusions ou tentatives d’intrusions dans
            les systèmes de Giveats, (iii) tous détournements des ressources
            système de la Plateforme, (iv) toutes actions de nature à imposer
            une charge disproportionnée sur les infrastructures de cette
            dernière, (v) toutes atteintes aux mesures de sécurité et
            d’authentification, (vi) tous actes de nature à porter atteinte aux
            droits et intérêts financiers, commerciaux ou moraux de Giveats, et
            enfin plus généralement (vii) tout manquement aux présentes
            Conditions Générales.
          </li>
          <li>
            Il est strictement interdit de monnayer, vendre ou concéder tout ou
            partie de l’accès aux Services ou à la Plateforme, ainsi qu’aux
            informations qui y sont hébergées et/ou partagées.
          </li>
        </ul>
        <h3>Sanctions des manquements</h3>
        <p>
          En cas de manquement à l’une quelconque des dispositions des
          Conditions Générales, ou plus généralement, d’infraction aux lois et
          règlements par l’Utilisateur, Giveats se réserve le droit de prendre
          toute mesure appropriée et notamment de :
        </p>
        <ul>
          <li>
            suspendre, supprimer ou empêcher l’accès aux Services du
            Utilisateur, auteur du manquement ou de l’infraction, ou y ayant
            participé,
          </li>
          <li>
            supprimer tout Contenu en lien avec le manquement ou l’infraction
            considéré(e), en totalité ou en partie,
          </li>
          <li>
            prendre toutes mesures appropriées et engager toute action en
            justice,
          </li>
          <li>
            avertir le cas échéant les autorités compétentes, coopérer avec
            elles et leur fournir toutes les informations utiles à la recherche
            et à la répression d’activités illégales ou illicites.
          </li>
        </ul>
        <p>
          L’Utilisateur est informé et accepte que tout manquement à ses
          obligations pourra entraîner, outre les conséquences prévues
          ci-dessus, la résolution immédiate des Conditions Générales par
          Giveats, par tout moyen écrit.
        </p>
        <h3>Confidentialité</h3>
        <p>
          Chaque partie s’engage à garder strictement confidentiels les
          documents, éléments, données et informations de l’autre partie dont
          elle serait destinataire qui seront expressément identifiés par
          l’autre partie comme étant confidentiels. S’agissant de Giveats, les
          parties conviennent d’ores et déjà expressément que cette obligation
          de confidentialité couvre les données à caractère personnel qu’elle
          sera amenée à traiter pour l’Utilisateur dans le cadre des Services.
        </p>
        <p>
          L’ensemble de ces informations est désigné ci-après les « Informations
          Confidentielles ».
        </p>
        <p>
          La partie qui reçoit des Informations Confidentielles s’engage à ne
          pas les divulguer sans accord préalable de l’autre partie, pendant une
          durée de 3 (trois) ans à compter de la fin de l’exécution des Services
          concernés. Elle ne pourra les transmettre à des employés,
          collaborateurs, stagiaires ou conseils que s’ils sont tenus à la même
          obligation de confidentialité que celle prévue aux présentes. Cette
          obligation ne s’étend pas aux documents, éléments, données et
          informations :
        </p>
        <ul>
          <li>dont la partie qui les reçoit avait déjà connaissance ;</li>
          <li>
            déjà publics lors de leur communication ou qui le deviendraient sans
            violation des Conditions Générales;
          </li>
          <li>qui auraient été reçus d’un tiers de manière licite ;</li>
          <li>
            dont la communication serait exigée par les autorités judiciaires,
            en application des lois et règlements ou en vue d’établir les droits
            d’une partie au titre des Conditions Générales.
          </li>
        </ul>
        <h3>Propriété intellectuelle</h3>
        <p>
          Les systèmes, logiciels, structures, infrastructures, bases de
          données, codes et contenus de toute nature (textes, images, visuels,
          logos, marques, bases de données, etc) exploités par Giveats sur la
          Plateforme, à l’exclusion des Contenus dont la propriété appartient à
          l’Utilisateur, sont protégés par tous droits de propriété
          intellectuelle ou droits des producteurs de bases de données en
          vigueur.
        </p>
        <p>
          Tous désassemblages, décompilations, décryptages, extractions,
          réutilisations, copies et plus généralement, tous actes de
          reproduction, représentation, diffusion et utilisation de l’un
          quelconque de ces éléments, en tout ou partie, sans l’autorisation de
          Giveats sont strictement interdits et pourront faire l’objet de
          poursuites judiciaires.
        </p>
        <h3>Données à caractère personnel</h3>
        <p>
          Giveats pratique une politique de protection des données personnelles
          dont les caractéristiques sont explicitées dans le document intitulé
          « Charte relative à la protection des données à caractère personnel »,
          dont l’Utilisateur est expressément invité à prendre connaissance.
        </p>
        <h3> Liens et sites tiers</h3>
        <p>
          Giveats ne pourra en aucun cas être tenue pour responsable de la
          disponibilité technique de sites web exploités par des tiers (y
          compris ses éventuels partenaires) auxquels l’Utilisateur accéderait
          par l'intermédiaire de la Plateforme.
        </p>
        <p>
          Giveats n'endosse aucune responsabilité au titre des contenus,
          publicités, produits et/ou services disponibles sur de tels sites
          tiers dont il est rappelé qu’ils sont régis par leurs propres
          conditions d’utilisation.
        </p>
        <p>
          Giveats n'est pas non plus responsable des transactions intervenues
          entre le Utilisateur et un quelconque annonceur, professionnel ou
          commerçant (y compris ses éventuels partenaires) vers lequel le
          Utilisateur serait orienté par l'intermédiaire de la Plateforme et ne
          saurait en aucun cas être partie à quelques litiges éventuels que ce
          soit avec ces tiers concernant notamment l’achat de produits et/ou
          services, les garanties, déclarations et autres obligations
          quelconques auxquelles ces tiers sont tenus.
        </p>
        <h3> Force majeure</h3>
        <p>
          Aucune Partie ne pourra voir sa responsabilité engagée pour un défaut
          d’exécution de ses obligations contractuelles si ce défaut est dû à un
          évènement indépendant de la volonté des Parties et constitutif de
          force majeure, telle que définie à l’article 1218 du Code civil.
        </p>
        <p>
          Par force majeure, les parties conviennent notamment d’entendre la
          survenance d’un évènement présentant les caractéristiques
          d’imprévisibilité et d’irrésistibilité habituellement reconnues par la
          loi et les tribunaux français ainsi que les grèves, activités
          terroristes, émeutes, insurrections, guerres, actions
          gouvernementales, épidémies, catastrophes naturelles ou défaut
          imputable à un prestataire tiers de télécommunication.
        </p>
        <p>
          La partie empêchée devra informer dans les meilleurs délais l’autre
          partie en indiquant la nature du cas de force majeure. Les parties se
          rapprocheront afin de déterminer ensemble les moyens les plus
          appropriés pour pallier, si possible, les conséquences du ou des
          évènement(s) constitutif(s) de la force majeure.
        </p>
        <p>
          Si le cas de force majeure perdure plus de 30 (trente) jours, chaque
          partie pourra résilier les Conditions Générales, de plein droit, sans
          formalité judiciaire, sans préavis et sans droit à indemnités de
          quelque nature que ce soit, par l’envoi d’une lettre recommandée avec
          avis de réception ayant effet immédiat.
        </p>
        <p>
          Si, à la suite d’un cas de force majeure, la partie affectée est
          empêchée de remplir seulement une partie de ses obligations
          contractuelles, elle reste responsable de l’exécution des obligations
          qui ne sont pas affectées par le cas de force majeure ainsi que de ses
          obligations de paiement.
        </p>
        <p>
          Dès cessation du cas de force majeure, la partie empêchée doit
          informer immédiatement l’autre partie et reprendre l’exécution des
          obligations affectées dans un délai raisonnable.
        </p>
        <h3>Publicité </h3>
        <p>
          Giveats, en collaboration avec l’Utilisateur, se réserve la faculté
          d’insérer sur toute page de la Plateforme et dans toute communication
          aux Utilisateurs tous messages publicitaires ou promotionnels sous une
          forme et dans des conditions dont Giveats sera seule juge.
        </p>
        <p></p>
        <h3> Modifications</h3>
        <p>
          Giveats se réserve la faculté de modifier à tout moment les présentes
          Conditions Générales.
        </p>
        <p>
          Les Utilisateurs seront informés de ces modifications par tout moyen
          utile.
        </p>
        <p>
          Les Utilisateurs qui n’acceptent pas les Conditions Générales
          modifiées doivent se désinscrire des Services selon les modalités
          prévues à l’article « Durée des Services et désinscription ».
        </p>
        <p>
          Tout Utilisateur qui a recours aux Services postérieurement à l’entrée
          en vigueur des Conditions Générales modifiées est réputé avoir accepté
          ces modifications.
        </p>
        <h3> Langue</h3>
        <p>
          Dans l’hypothèse d’une traduction des présentes Conditions Générales
          dans une ou plusieurs langues, la langue d’interprétation sera la
          langue française en cas de contradiction ou de contestation sur la
          signification d’un terme ou d’une disposition.
        </p>
        <h3> Médiation</h3>
        <p>
          L’Utilisateur dispose du droit de recourir gratuitement à un médiateur
          de la consommation en vue de la résolution amiable de tout litige
          portant sur l’exécution des présentes qui l'opposerait à Giveats, dans
          les conditions prévues aux articles L611-1 et suivants et R612-1 et
          suivants du Code de la consommation.
        </p>
        <p>
          Il peut contacter à cette fin le médiateur de la consommation
          suivant :Centre de médiation et de règlement amiable des huissiers de
          justice (Medicys) Adresse postale : 73 Boulevard de Clichy, 75009
          Paris Adresse électronique : contact@medicys.fr Téléphone : 01 49 70
          15 93 https://medicys.fr/ Loi applicable Les présentes Conditions
          Générales sont régies par la loi française.
        </p>
      </TextContainer>
    </Section>
  );
}
