import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../theme/Device";

export const Download = styled(LinkR)`
  background: #c988ff;
  width: 240px;
  height: 45px;
  border-radius: 24px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  line-height: 44px;
  text-decoration: none;

  &:hover {
    background: #ff9239;
    color: #ffffff;
  }

  @media only screen and ${device.mobileL} {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  @media only screen and ${device.tablet} {
    margin-right: 15px;
    margin-bottom: 0px;
  }

  @media only screen and ${device.laptop} {
    margin-right: 15px;
    margin-bottom: 0px;
  }
`;

export const ButtonInscris = styled(LinkR)`
  height: 45px;
  line-height: 44px;
  font-weight: bold;
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : "#fff")};
  background: ${(props) => (props.background ? props.background : "#700cbf")};
  text-align: center;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  display: inline-block;
  border-radius: 53px;

  :hover {
    text-decoration: none;
    background: #fff;
    color: #700cbf;
    display: inline-block;
  }

  @media only screen and ${device.mobileL} {
    margin-bottom: 20px;
    margin-right: ${(props) => (props.mRgMob ? props.mRgMob : "0px")};
    width: ${(props) => (props.widthMob ? props.widthMob : "240px")};
  }

  @media only screen and ${device.tablet} {
    margin-bottom: 20px;
    margin-right: ${(props) => (props.mRgTab ? props.mRgTab : "0px")};
    width: ${(props) => (props.widthTab ? props.widthTab : "240px")};
  }

  @media only screen and ${device.laptop} {
    margin-bottom: 0px;
    margin-right: ${(props) => (props.mRgLaptop ? props.mRgLaptop : "0px")};
    width: ${(props) => (props.widthLaptop ? props.widthLaptop : "240px")};
  }
`;

export const ButtonDemo = styled.a`
  height: 45px;
  line-height: 44px;
  font-weight: bold;
  width: 240px;
  font-size: 18px;
  color: #700cbf;
  background: #fff;
  text-align: center;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  display: inline-block;
  border-radius: 53px;
  cursor: pointer;

  :hover {
    text-decoration: none;
    background: #700cbf;
    color: #fff;
    display: inline-block;
  }
`;
