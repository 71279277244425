import styled from "styled-components";
import { device } from "../../theme/Device";
import TeamBg from "../../images/teambg.png";

export const TeamBody = styled.div`
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  @media only screen and ${device.mobileL} {
    .imgromeo {
      width: 211px;
    }

    .imgteam {
      width: 260px;
    }
  }

  @media only screen and ${device.tablet} {
    .imgromeo {
      width: 467px;
    }
    .imgteam {
      width: 560px;
    }
  }

  @media only screen and ${device.laptop} {
    .imgromeo {
      width: 567px;
    }
    .imgteam {
      width: 760px;
    }
  }
`;

export const BannerDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 200px 0px 0px 0px;
  h1 {
    font-weight: 800;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    line-height: 75px;
    color: #263238;
  }
`;

export const VideoDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 90px;

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export const AboutUsDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: -6px;
  padding: 70px 100px;
  background: url(${TeamBg});
  background-position: top right;
  background-repeat: no-repeat;

  @media only screen and ${device.mobileL} {
    padding: 70px 30px;
  }

  @media only screen and ${device.tablet} {
    padding: 70px 100px;
  }

  @media only screen and ${device.laptop} {
    padding: 70px 100px;
  }
`;

export const TitleH2 = styled.h2`
  line-height: 60px;
  margin-bottom: ${(props) => (props.marginBot ? props.marginBot : "140px")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  font-weight: bold;
  text-align: center;
  color: #000000;

  span {
    color: #ff9239;
  }

  @media only screen and ${device.mobileL} {
    margin-bottom: ${(props) => (props.marginBot ? props.marginBot : "20px")};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  }

  @media only screen and ${device.tablet} {
    margin-bottom: ${(props) => (props.marginBot ? props.marginBot : "35px")};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  }

  @media only screen and ${device.laptop} {
    margin-bottom: ${(props) => (props.marginBot ? props.marginBot : "140px")};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  }
`;

export const StoryTextDiv = styled.div`
  p {
    font-weight: normal;
    line-height: 23px;
    color: #000000;
    margin-bottom: 10px;

    @media only screen and ${device.mobileL} {
      font-size: 14px;
    }

    @media only screen and ${device.tablet} {
      font-size: 15px;
    }

    @media only screen and ${device.laptop} {
      font-size: 16px;
    }
  }
`;

export const TeamP = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 10px;

  i {
    color: #700cbf;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
  }
`;

export const TeamImg = styled.img`
  border-radius: 33px;
  filter: drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.25));
`;

export const Figure = styled.figure`
  text-align: center;
  margin-top: 30px;
`;

export const MissionDiv = styled.div`
  background: #ffffff;
  text-align: center;
  margin: 70px 0px;
  position: relative;
  display: inline-block;
  box-shadow: 0px 0px 38px rgb(0 0 0 / 25%);
  border-radius: 39px;

  img {
    height: 100px;
    margin: 60px 0px 35px 0px;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;
  }

  @media only screen and ${device.mobileL} {
    width: 290px;
    min-height: 300px;
    p {
      font-size: 16px;
    }
  }

  @media only screen and ${device.tablet} {
    width: 350px;

    min-height: 335px;
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.laptop} {
    width: 370px;
    min-height: 335px;
    p {
      font-size: 20px;
    }
  }
`;

export const MissionNumberDiv = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  font-weight: 800;
  font-size: 35px;
  line-height: 58px;
  color: #ffffff;
  background: #ff9239;
  border-radius: 50%;
  left: -13px;
  top: -25px;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
`;

export const ValueDiv = styled.div`
  @media only screen and ${device.mobileL} {
    padding-right: 0px;
    padding-left: 0px;

    .imgsittingwoman {
      width: 258px;
    }
  }

  @media only screen and ${device.tablet} {
    padding-right: 0px;
    padding-left: 0px;

    .imgsittingwoman {
      width: 308px;
    }
  }

  @media only screen and ${device.laptop} {
    padding-right: 110px;
    padding-left: 110px;

    .imgsittingwoman {
      width: 408px;
    }
  }
`;

export const ValueTextDiv = styled.div`
  width: auto;
  height: auto;
  display: inline-block;
  text-align: left;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "0px")};

  h5 {
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    line-height: 34px;
    color: #000000;
  }

  p {
    font-weight: normal;
    line-height: 20px;
    color: #000000;
  }

  @media only screen and ${device.mobileL} {
    p {
      font-size: 13px;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      font-size: 15px;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      font-size: 16px;
    }
  }
`;

export const ValueImg = styled.img`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  height: 23px;
`;

export const FoundersDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 30px;

  @media only screen and ${device.mobileL} {
    padding: 50px 30px;
    img {
      width: 300px;
    }
  }

  @media only screen and ${device.tablet} {
    padding: 50px 30px;
    img {
      width: 345px;
    }
  }

  @media only screen and ${device.laptop} {
    padding: 50px 90px;
    img {
      width: 389px;
    }
  }
`;

export const FoundersContentDiv = styled.div`
  text-align: center;
  margin-bottom: 70px;

  img {
    margin-bottom: 20px;
  }

  h4 {
    font-weight: 800;
    margin-bottom: 8px;
    line-height: 41px;
  }

  p {
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    color: #000000;
  }
`;

export const RestOfTeamDiv = FoundersDiv;

export const RestOfTeamContentDiv = FoundersContentDiv;
