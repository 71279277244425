import React from "react";
import Carousel from "react-multi-carousel";
import {
  FoodExperience,
  ForWho,
  Video,
  Item,
  Card,
} from "./TakeatsFoot.styles";
import BtnBenef from "../../buttons/BtnBenef";
import BtnAsso from "../../buttons/BtnAsso";
import Sauve from "../../../images/Sauve.png";
import Repas from "../../../images/repas.png";
import Autonomie from "../../../images/Autonomie.png";

export default function TakeatsFood() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <FoodExperience className="container">
        <h2 className="takeatstitle">
          Une <span>aide alimentaire quotidienne</span> <br /> autour de toi
        </h2>
        <div className="row ">
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            <Item>
              <Card>
                <div className="col-sm-12 col-12 givtext">
                  <h5>Une appli pour sauver les invendus dans les restos</h5>
                  <img src={Sauve} alt="save" />
                  <p>
                    Avec l’appli Takeats, des restaurants partenaires et des
                    foodies t’aident à te nourrir. Tu seras notifié sur l’appli
                    des repas solidaires et invendus à sauver autour de toi, il
                    suffit de réserver.
                    <br></br>
                    C’est 100% gratuit
                  </p>
                </div>
              </Card>
            </Item>

            <Item>
              <Card>
                <div className="col-sm-12 col-12 givtext">
                  <h5>Des repas solidaires à récupérer quotidiennement</h5>
                  <img src={Repas} alt="meal" />

                  <p>
                    Nos restaurants partenaires mettent en ligne des plats
                    solidaires quotidiennement. Leurs excédents de plat sont
                    récupérables en fin de service à récupérer à emporter.
                    <br></br>
                    L’aide alimentaire 2.0 qui va t’aider à manger arrive.
                  </p>
                </div>
              </Card>
            </Item>

            <Item>
              <Card>
                <div className="col-sm-12 col-12 givtext">
                  <h5>Gagne en autonomie pour te nourrir</h5>
                  <img src={Autonomie} alt="aut" />
                  <p>
                    Inscris-toi auprès d’une association partenaire pour activer
                    ta période d’aide alimentaire avec Takeas. Pour cela il
                    suffit de remplir le formulaire en ligne pour étudier ta
                    demande ou te rapprocher auprès d’une des associations
                    partenaires.
                  </p>
                  <small className="btn3">
                    <a href="/"> Voir les assos partenaires </a>
                  </small>
                </div>
              </Card>
            </Item>
          </Carousel>
        </div>
      </FoodExperience>

      <ForWho>
        <div className="container">
          <h2>Qui peut devenir utilisateurs de Takeats?</h2>
          <div className="row">
            <div className="col-12 col-lg-6 boxborder">
              <div className="foodbox">
                <h4>Toute personne en précarités alimentaire</h4>

                <p>
                  Toi qui avait du mal à manger dans les fins de mois
                  difficiles... <br /> Toi pour qui les choix alimentaires sont
                  limités,
                  <br /> Toi pour qui manger reste un challenge quotidien,
                  <br /> Toi à qui il arrive de faire la manche pour trouver de
                  quoi manger,
                  <br /> Toi pour qui l’accès à une alimentation variée reste
                  difficile,
                  <br /> Toi pour qui manger au restaurant n’est pas souvent une
                  possibilité
                </p>
                <b>
                  Étudiants, intérimaires, personnes sans emploi, l’appli
                  Takeats t’invite à sauver des repas autour de toi.
                </b>
                <span className="btn1">
                  <BtnBenef content="Préinscris-toi !" />
                </span>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="foodbox">
                <h4>
                  Associations caritatives luttant contre <br />
                  la précarité
                </h4>
                <p>
                  Giveats s’engage à reverser 10% de son chiffre d’affaires pour
                  lutter contre la précarité et faire en sorte que la précarité
                  alimentaire précaire ne soit plus une fatalité.
                </p>
                <b>
                  Tu es un travailleur social d’une association caritative qui
                  partage la même conviction et la même mission ? N’hésites pas
                  à nous contacter pour soutenir Giveats ou référencer l’appli
                  auprès des personnes que tu accompagnes !
                </b>
                <span className="btn4">
                  <BtnAsso />
                </span>
              </div>
            </div>
            <Video>
              <div className="video-responsive">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/NvorwuYdRfA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="row">
                <span className="btn1 col-12 col-md-6 btnvideo">
                  <BtnBenef content="Devenir Taker" />
                </span>
                <span className="btn4 col-12 col-md-6 btnvideo">
                  <BtnAsso to="/association-form">Devenir partenaire</BtnAsso>
                </span>
              </div>
            </Video>
          </div>
        </div>
      </ForWho>
    </>
  );
}
