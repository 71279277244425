import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  OurPromiseDiv,
  TextPleaseDiv,
  SolutionPleaseDiv,
  ImagePleaseDiv,
  JoinUsCarouselDiv,
  Item,
  Card,
  TitleCarouselDiv,
  BottomDiv,
  WhiteBgDiv,
  OrangebgDiv,
  BtnSubRestaurant,
  DivBtnSubRestaurant,
  DivPartenaire,
  ItemCarousel,
  H3,
  BtnCreateAccount,
} from "./OurPromise.styles";
import BtnRestaurant from "../../buttons/BtnRestaurant";
import sliderpic1 from "../../../images/sliderpic1.png";
import sliderpic2 from "../../../images/sliderpic2.png";
import sliderpic3 from "../../../images/sliderpic3.png";
import sliderpic4 from "../../../images/sliderpic4.png";
import sliderpic5 from "../../../images/sliderpic5.png";
import sliderpic6 from "../../../images/sliderpic6.png";
import sliderpic7 from "../../../images/sliderpic7.png";
import PromiseMobImg from "../../../images/mobilepic.png";
import CapTiedo from "../../../images/Unicon4.png";
import JohnJohnJohn from "../../../images/Unicon10.png";
import Lamangerie from "../../../images/Unicon11.png";
import WithLoveImg from "../../../images/withloveimg.png";

export default function OurPromise() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <>
      <OurPromiseDiv>
        <div className="container">
          <h2>Notre promesse pour votre établissement </h2>
          <div className="col-12 col-sm-12">
            <img
              src={PromiseMobImg}
              className="img-fluid"
              alt="promisemobimg"
            />
          </div>
        </div>
      </OurPromiseDiv>

      <SolutionPleaseDiv>
        <div className="container-lg">
          <TextPleaseDiv className="row">
            <div className="col-lg-6 col-12">
              <h3>
                NOTRE SOLUTION <br />
                VOUS PLAÎT ? <br></br>
                RESTONS EN CONTACT !
              </h3>

              <h4>Devenez un commerce solidaire</h4>
              <div>
                <BtnRestaurant bg="#ff9239" content="Créer mon compte" />
              </div>
              <p>
                <b>200 euros</b> de <b>frais d’activation offerts,</b> pour les
                50 premiers inscrits avant le 30 mai 2022 !
              </p>
            </div>
            <ImagePleaseDiv className="col-lg-6 col-12 ">
              <img src={WithLoveImg} alt="withloveim" />
            </ImagePleaseDiv>
          </TextPleaseDiv>
        </div>
      </SolutionPleaseDiv>
      <JoinUsCarouselDiv>
        <div className="container">
          <TitleCarouselDiv className="col-sm-12 col-12">
            <h2>Ils nous ont déjà rejoints !</h2>
          </TitleCarouselDiv>
          <Carousel responsive={responsive2}>
            <Item>
              <Card>
                <a href="https://www.facebook.com/Restaurant-Cap-Tiedo-101325231378552/">
                  <img src={CapTiedo} alt="captiedo" />
                </a>
                <h3>Cap Tiedo</h3>
                <p>
                  192 rue de Tolbiac <br /> 75013 PARIS
                </p>
              </Card>
            </Item>
            <Item>
              <Card>
                <a href="https://www.facebook.com/John-john-john-144331786266287">
                  <img src={JohnJohnJohn} alt="johnjohnjohn" />
                </a>
                <h3>John John John</h3>
                <p>
                  59 Rue de Tolbiac <br /> 75013 Paris
                </p>
              </Card>
            </Item>
            <Item>
              <Card>
                <a href="https://www.facebook.com/lamangerie">
                  <img src={Lamangerie} alt="lamangerie" />
                </a>
                <h3>La Mangerie</h3>
                <p>
                  7 Rue de Jarente <br /> 75004 Paris
                </p>
              </Card>
            </Item>
          </Carousel>
        </div>
      </JoinUsCarouselDiv>

      <BottomDiv>
        <WhiteBgDiv class="whitecaptionbg">
          <h4>8M</h4>
          <p>
            de Français·e·s <br />
            dépendent de l’aide <br />
            alimentaire aujourd’hui
          </p>
          <span>
            <a href="https://giveats.io/caritative">En savoir +</a>
          </span>
        </WhiteBgDiv>

        <OrangebgDiv>
          <div className="container">
            <h3>
              RIEN NE CHANGE <br className="mobilehide" />
              POUR VOUS, <br />
              VOS SURPLUS ONT <br className="mobilehide" />
              DE LA VALEUR, <br />
              TOUT CHANGE <br className="mobilehide" />
              POUR EUX
            </h3>
            <br />
            <p>
              Avec Giveats prend part à une <br /> initiative citoyenne et{" "}
              <br /> collaborative pour enrayer <br />
              l’insécurité alimentaire !
            </p>
            <DivBtnSubRestaurant className="col-sm-12 col-12 givtext">
              <span className="givbtn">
                <BtnRestaurant />
              </span>
            </DivBtnSubRestaurant>
          </div>
        </OrangebgDiv>
      </BottomDiv>

      <DivPartenaire>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-12">
              <H3>Ils parlent de nous !</H3>
            </div>
            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={["tablet", "mobile"]}
            >
              <ItemCarousel>
                <a href="https://www.ouest-france.fr/leditiondusoir/2021-06-01/les-bonnes-nouvelles-du-jour-malgre-le-coronavirus-eda8f8cd-0e9c-4429-8fef-a1701c89a339">
                  <img src={sliderpic1} alt="sliderpic1" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.neonmag.fr/giveats-lappli-qui-permet-de-commander-des-repas-et-den-offrir-aux-personnes-dans-le-besoin-556525.html">
                  <img src={sliderpic2} alt="sliderpic2" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.ladn.eu/adn-business/news-business/actualites-annonceurs/application-giveats-foodservice-lutte-precarite-gaspillage-alimentaire/">
                  <img src={sliderpic3} alt="sliderpic3" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.7detable.com/article/digital/giveats-l-appli-qui-permet-de-commander-un-repas-et-d-en-offrir-egalement/3387">
                  <img src={sliderpic4} alt="sliderpic4" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://lapauseinfo.fr/giveats-application-vocation-eliminer-famine/">
                  <img src={sliderpic5} alt="sliderpic5" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.snacking-today.com/article/giveats-la-nouvelle-appli-food-service-francaise">
                  <img src={sliderpic6} alt="sliderpic6" />
                </a>
              </ItemCarousel>
              <ItemCarousel>
                <a href="https://www.neorestauration.com/article/giveats-l-appli-de-commande-de-repas-qui-donne-a-manger-aux-precaires,58388">
                  <img src={sliderpic7} alt="sliderpic7" />
                </a>
              </ItemCarousel>
            </Carousel>
          </div>
        </div>
      </DivPartenaire>
    </>
  );
}
