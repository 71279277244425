import React from "react";
import { Container, Image, ButtonDiv, FigureImage } from "./TakeatsHome.styles";
import BtnBenef from "../../buttons/BtnBenef";
import BtnAsso from "../../buttons/BtnAsso";
import Test from "../../../images/innerbannnerimg1.png";
import BannerLogo from "../../../images/bannnerlogo.png";
import TakeatsHomeSvg from "./TakeatsHomeSvg";

export default function TakeatsHome() {
  return (
    <>
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <FigureImage>
                <img alt="bannnerlogo" src={BannerLogo} />
              </FigureImage>
              <h1>
                Des paniers repas solidaire <br></br>
                dans les restaurants partenaires
              </h1>
            </div>
            <div className="col-12 col-md-7">
              <p>
                Un mouvement collectif et unique pour réinventer une aide
                alimentaire quotidienne, simple et directe entre particuliers
                dans les restaurants partenaires de Giveats.
              </p>
            </div>

            <ButtonDiv>
              <span className="btn1">
                <BtnBenef mRgLaptop="20px" mRgTab="20px" />
              </span>
              <span className="btn2">
                <BtnAsso
                  content="
                  Devenir une association partenaire"
                  widthMob="320px"
                  widthTab="340px"
                  widthLaptop="360px"
                />
              </span>
            </ButtonDiv>
            <div>
              <Image alt="innerbannnerimg1" src={Test} />
            </div>
          </div>
        </div>
      </Container>
      <TakeatsHomeSvg />
    </>
  );
}
