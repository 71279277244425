import React from "react";
import { ButtonInscris } from "./Btn.styles";

const BtnBenef = (props) => {
  return (
    <ButtonInscris
      color={props.color}
      background={props.bg}
      mRgMob={props.mRgMob}
      mRgTab={props.mRgTab}
      mRgLaptop={props.mRgLaptop}
      to={props.to ? props.to : "/beneficiary-form"}
    >
      {props.content ? props.content : "Devenir bénéficaire"}
    </ButtonInscris>
  );
};

export default BtnBenef;
