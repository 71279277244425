import React, { useState, useEffect } from "react";
import {
  Header,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavBtnUl,
  NavLink,
  NavBtnLink,
  NavBtnLink2,
  NavLi,
  DropdownMenu,
  DropDownItem,
  ToggleButton,
  HamburgerMenu,
} from "./Navbar.styles";
import { useLocation, matchPath } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoWhite from "../../images/logo-white.png";
import Abouticon from "../../images/abouticon.png";
import PressIcon from "../../images/subicon5.png";
import BlogIcon from "../../images/subicon6.png";
import EquipeIcon from "../../images/subicon4.png";
import PayementIcon from "../../images/tarifs.png";
import Hamburger from "../../images/menu_hamburger.png";
export default function Navbar(props) {
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);

  // add url location to variable
  const sampleLocation = useLocation();
  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState(Logo);

  const greyLink = "#c4c4c4";
  const whiteLink = "#fff";
  const [navColorLink, setNavColorLink] = useState(whiteLink);

  // set the style of the navbar when collapse
  const [style, setStyle] = useState("cont container-xl");

  // s
  const [showMenu, setShowMenu] = useState(true);

  const changeShowMenu = () => {
    if (
      (style === "cont container-xl" && window.screen.width >= 992) ||
      style === "navbar-phone container-xl"
    ) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  // set pathname to collapse on location
  const { pathname } = useLocation();

  //navbar scroll changeBackground function
  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // Change the class of the div for the menu
  const changeStyle = () => {
    if (style === "cont container-xl") {
      setStyle("navbar-phone container-xl");
    } else {
      setStyle("cont container-xl");
    }
  };

  //logo scroll function
  const changeLogo = () => {
    if (
      window.scrollY >= 60 ||
      style === "navbar-phone container-xl" ||
      sampleLocation.pathname === "/team" ||
      sampleLocation.pathname === "/restaurant-form" ||
      sampleLocation.pathname === "/association-form" ||
      sampleLocation.pathname === "/userform" ||
      sampleLocation.pathname === "/beneficiary-form" ||
      sampleLocation.pathname === "/blog" ||
      matchPath(sampleLocation.pathname, { path: "/blog/:id" })
    ) {
      setNavbarLogo(Logo);
      setNavColorLink(greyLink);
    } else {
      setNavbarLogo(LogoWhite);
      setNavColorLink(whiteLink);
    }
  };

  useEffect(() => {
    changeBackground();
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
    window.addEventListener("scroll", changeLogo);
  });

  useEffect(() => {
    setStyle("cont container-xl");
  }, [pathname]);

  //Reload page if is the same page
  const changeLocationIfSame = (path) => {
    console.log(sampleLocation.location);
    console.log(path);
    if (sampleLocation.pathname === path) {
      window.location.reload();
    }
  };

  return (
    <>
      <Header
        className={
          navbar
            ? "navbar navbar-active navbar-expand-lg"
            : "navbar navbar-expand-lg"
        }
      >
        <div className={style}>
          <NavLogo
            to={"/"}
            className="navbar-brand"
            onClick={() => {
              changeLocationIfSame("/");
            }}
          >
            <span
              className="collapse-span"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <img className="d-block logo" src={navbarLogo} alt="Logo" />{" "}
            </span>
          </NavLogo>
          <ToggleButton
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={changeStyle}
          >
            <HamburgerMenu src={Hamburger} alt="hamburger_menu" />
            {/* <Bars /> */}
          </ToggleButton>

          <NavbarContainer
            className="collapse navbar-collapse"
            id="navbarNavDropdown"
            style={showMenu === true ? {} : { display: "none" }}
          >
            <NavMenu className="navbar-nav">
              <NavLi className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  color={navColorLink}
                >
                  SOLUTIONS
                </NavLink>
                <DropdownMenu
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <DropDownItem
                      className="dropdown-item"
                      to={"/charity"}
                      onClick={() => {
                        changeLocationIfSame("/charity");
                      }}
                    >
                      <img alt="abouticon" src={Abouticon} />{" "}
                      <span
                        className="collapse-span"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        A PROPOS DE <br /> L'INSECURITE ALIMENTAIRE
                      </span>
                    </DropDownItem>
                  </li>
                  <li>
                    <DropDownItem
                      className="dropdown-item"
                      to={"/tarif"}
                      onClick={() => {
                        changeLocationIfSame("/tarif");
                      }}
                    >
                      <span
                        className="collapse-span"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        <img alt="paymenticon" src={PayementIcon} /> TARIFS{" "}
                      </span>
                    </DropDownItem>
                  </li>
                </DropdownMenu>
              </NavLi>

              <NavLi className="nav-item">
                <NavLink
                  to={"/charity"}
                  onClick={() => {
                    changeLocationIfSame("/charity");
                  }}
                  color={navColorLink}
                >
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    L'INSÉCURITÉ ALIMENTAIRE
                  </span>
                </NavLink>
              </NavLi>
              <NavLi className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  color={navColorLink}
                >
                  RESSOURCES
                </NavLink>
                <DropdownMenu
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  left="0%"
                  dropWidth="150px"
                >
                  <li>
                    <DropDownItem
                      className="dropdown-item"
                      to={"/team"}
                      onClick={() => {
                        changeLocationIfSame("/team");
                      }}
                    >
                      <span
                        className="collapse-span"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        <img alt="equipe" src={EquipeIcon} /> NOTRE EQUIPE{" "}
                      </span>
                    </DropDownItem>
                  </li>
                  <li>
                    <DropDownItem className="dropdown-item" to={"/blog"}>
                      <img alt="blogicon" src={BlogIcon} /> BLOG
                    </DropDownItem>
                  </li>
                  {/* <li>
                    <DropDownItem className="dropdown-item">
                      <img alt="testicon" src={TestiIcon} /> TEMOIGNAGES
                    </DropDownItem>
                  </li> */}
                  <li>
                    <DropDownItem
                      className="dropdown-item"
                      to={"/press"}
                      onClick={() => {
                        changeLocationIfSame("/press");
                      }}
                    >
                      <span
                        className="collapse-span"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        <img alt="pressicon" src={PressIcon} /> PRESSE
                      </span>
                    </DropDownItem>
                  </li>
                </DropdownMenu>
              </NavLi>
            </NavMenu>

            <NavBtnUl className="navbar-nav  ">
              <NavLi className="nav-item takeats">
                <NavBtnLink2
                  to={"/takeats"}
                  onClick={() => {
                    changeLocationIfSame("/takeats");
                  }}
                >
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Takeats
                  </span>
                </NavBtnLink2>
              </NavLi>
              <NavLi className="nav-item restaurant">
                <NavBtnLink
                  to={"/restaurant"}
                  onClick={() => {
                    changeLocationIfSame("/restaurant");
                  }}
                >
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Restaurateur
                  </span>
                </NavBtnLink>
              </NavLi>
            </NavBtnUl>
          </NavbarContainer>
        </div>
      </Header>
    </>
  );
}
