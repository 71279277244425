import styled from "styled-components";

import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";

export const SolutionTableDiv = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 100px;
  padding: 0px 0px;
`;

export const TitleTableau = styled.h2`
  line-height: 60px;
  margin-bottom: 60px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  span {
    color: #ff9239;
  }
`;

export const Table = styled.table`
  font-weight: bold;
  font-size: 19px;
  margin: auto;
  line-height: 22px;
  width: 85%;
  text-align: center;

  td,
  th {
    padding: 18px 20px;
  }

  b {
    font-weight: 600;
    font-size: 19px;
    line-height: 41px;
    color: #c4c4c4;
  }
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #606060;

  span {
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #700cbf;
  }
`;

export const ThLBR = styled.th`
  border-bottom: 1px solid #606060;
  border-right: 1px solid #606060;
  border-left: 1px solid #606060;
`;

export const TdLR = styled.td`
  border-right: 1px solid #606060;
  border-left: 1px solid #606060;
`;

export const ImageClose = styled.img`
  width: 30px;
  height: auto;
`;

export const MobileCardDiv = styled.div`
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%);
  border-radius: 38px;
  margin-bottom: 35px;
  min-height: 208px;
  p {
    font-weight: 600;
  }

  .giveats {
    color: #700cbf;
    font-size: 20px;
    font-weight: 800;
  }

  .grey {
    color: #c4c4c4c4;
  }
  img {
    width: 30px;
  }

  @media only screen and ${device.mobileL} {
    .giveats {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }

  @media only screen and ${device.tablet} {
    .giveats {
      font-size: 20px;
    }
    p {
      font-size: 18px;
    }
  }
`;
