import React from "react";
import { Banner, TitleBannerDiv, TextContainer } from "./PrivacyPolicy.styles";

export default function Mentions() {
  return (
    <section>
      <Banner>
        <div className="container">
          <TitleBannerDiv className="col-sm-8">
            <h1>Politique de confidentialité</h1>
          </TitleBannerDiv>
        </div>
      </Banner>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 342.318 1452 373.682"
        enable-background="new 0 342.318 1452 373.682"
        xmlSpace="preserve"
      >
        <rect
          x="-27.756"
          y="342.318"
          fill="#FFFFFF"
          stroke="#000000"
          stroke-miterlimit="10"
          width="1548.155"
          height="537.054"
        ></rect>
        <path
          fill="#FF9239"
          d="M669.462,550.5c-289,240.4-567.058,165.5-669.961,98V0h726.25H1452v385
C1311.573,340,958.46,310.1,669.462,550.5z"
        ></path>
      </svg>
      <TextContainer className="container col-10">
        <p>
          Lors de votre consultation du site https://giveats.com/ (ci-après le «
          Site »), nous sommes amenés à recueillir des données à caractère
          personnel vous concernant.
        </p>
        <p>
          Le but de cette charte est de vous informer sur les modalités selon
          lesquelles nous traitons ces données.
        </p>
        <h3>1. Responsable de traitement</h3>
        <p>
          Giveats, société par actions simplifiée <br />
          RCS Pontoise n° 883 632 663 <br />
          Siège social : 21 rue Vincent van Gogh 95170 Deuil-la-Barre <br />
          (ci-après : « Nous »)
        </p>
        <h3>2. A quoi servent les données collectées ?</h3>
        <p>
          Vos données sont traitées pour répondre à une ou plusieurs des
          finalités suivantes :
        </p>
        <ul>
          <li>- Constituer un fichier de clients et prospects</li>
          <li>
            - Adresser des newsletters, sollicitations et messages promotionnels
          </li>
          <li>- Répondre à vos demandes d’information</li>
        </ul>
        <h3>3. Quelle est la base légale du traitement ?</h3>
        <ul>
          <li>
            - Notre intérêt légitime à développer et promouvoir notre activité
          </li>
          <li>- Votre consentement s’agissant de l'envoi de newsletters</li>
        </ul>
        <h3>4. Quelles données personnelles collectons-nous ?</h3>
        <p>
          Une donnée à caractère personnel est une donnée qui permet
          d’identifier un individu
        </p>
        <p>
          Nous collectons des données qui relèvent des catégories suivantes :
        </p>
        <ul>
          <li>
            Des données d’identification (par exemple nom, prénom, adresse
            email, numéro de téléphone, nom de votre entreprise, numéro SIRET,
            nom du représentant légal de votre entreprise)
          </li>
          <li>- Des données de connexion (par exemple : adresse IP)</li>
        </ul>
        <p>
          Les données obligatoires sont indiquées lorsque vous nous fournissez
          vos données. Elles sont nécessaires pour vous fournir nos services.
        </p>
        <h3>5. Qui sont les destinataires des données ?</h3>
        <ul>
          <li>Le personnel de notre société</li>
          <li>
            Nos sous-traitants:{" "}
            <b>
              prestataire d’hébergement, outil CRM, prestataire d'envoi de
              newsletters, prestataire de logiciel de support et d’assistance &
              outils de productivité et de bureautique{" "}
            </b>{" "}
          </li>
          <li>
            - Le cas échéant : les services chargés du contrôle (commissaire aux
            comptes notamment), les organismes publics, exclusivement pour
            répondre à nos obligations légales, les auxiliaires de justice, les
            officiers ministériels et les organismes chargés d’effectuer le
            recouvrement de créances.
          </li>
        </ul>
        <h3>6. Quelle est la durée de conservation des données ?</h3>
        <table class="table">
          <tbody>
            <tr>
              <td>Données collectées à des fins de prospection</td>
              <td>
                3 ans à partir de la collecte des données ou du dernier contact
                avec le prospect
              </td>
            </tr>
            <tr>
              <td>En cas d’exercice de vos droits</td>
              <td>
                <ul>
                  <li>
                    Si nous vous demandons un justificatif d’identité : nous le
                    conservons seulement pendant le temps nécessaire à la
                    vérification d’identité
                  </li>
                  <li>
                    Si vous exercez votre droit d’opposition à recevoir de la
                    prospection : nous conservons cette information 3 ans.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
          7. Vos données sont-elles susceptibles d’être transférées hors de
          l’Union européenne ?
        </h3>
        <p>
          Vos données sont stockées sur les serveurs de la société OVH, en
          France.
        </p>
        <p>
          Ces dernières peuvent être transférées hors de l’Union européenne dans
          le cadre des outils que nous utilisons et de nos relations avec nos
          sous-traitants (voir article 5 « Qui sont les destinataires des
          données ? »).
        </p>
        <p>Ce transfert est sécurisé au moyen des outils suivants :</p>
        <ul>
          <li>
            Soit ces données sont transférées dans un pays ayant été jugé comme
            offrant un niveau de protection adéquat par une décision de la
            Commission Européenne{" "}
          </li>
          <li>
            Soit nous avons conclu avec nos sous-traitants un contrat spécifique
            encadrant les transferts de vos données en dehors de l’Union
            Européenne, sur la base des clauses contractuelles types entre un
            responsable de traitement et un sous-traitant, approuvées par la
            Commission Européenne
          </li>
        </ul>
        <h3>8. Quels sont vos droits sur vos données ?</h3>
        <p>
          Vous disposez des droits suivants s’agissant de vos données à
          caractère personnel :
        </p>
        <ul>
          <li>
            Droit à l’information : C’est justement la raison pour laquelle nous
            avons rédigé la présente charte.
          </li>
          <li>
            Droit d’accès : Vous avez le droit d’accéder à tout moment à
            l’ensemble de vos données à caractère personnel.
          </li>
          <li>
            Droit de rectification : Vous avez le droit de rectifier à tout
            moment vos données à caractère personnel inexactes, incomplètes ou
            obsolètes.
          </li>
          <li>
            Droit à la limitation : Vous avez le droit d’obtenir la limitation
            du traitement de vos données à caractère personnel dans certains cas
            définis à l’art.18 du RGPD
          </li>
          <li>
            Droit à l’oubli : Vous avez le droit d’exiger que vos données à
            caractère personnel soient effacées, et d’en interdire toute
            collecte future.
          </li>
          <li>
            Droit d’introduire une réclamation auprès d’une autorité de contrôle
            compétente (en France, la CNIL), si vous considérez que le
            traitement de vos données à caractère personnel constitue une
            violation des textes applicables
          </li>
          <li>
            Droit de définir des directives relatives à la conservation, à
            l'effacement et à la communication de vos données à caractère
            personnel après votre mort.
          </li>
          <li>
            Droit de retirer votre consentement : vous pouvez retirer votre
            consentement à tout moment. Ce retrait ne remettra pas en cause la
            légalité du traitement effectué avant le retrait
          </li>
          <li>
            Droit à la portabilité : Vous avez le droit de recevoir les données
            à caractère personnel que vous nous avez fournies dans un format
            standard lisible par machine et d’exiger leur transfert au
            destinataire de votre choix
          </li>
          <li>
            Droit d’opposition: Vous avez le droit de vous opposer au traitement
            de vos données à caractère personnel. Notez toutefois que nous
            pourrons maintenir leur traitement malgré cette opposition, pour des
            motifs légitimes ou la défense de droits en justice.
          </li>
        </ul>
        <p>
          Vous pouvez exercer ces droits en nous écrivant aux coordonnées
          ci-dessous. Nous pourrons vous demander à cette occasion de nous
          fournir des informations ou documents complémentaires pour justifier
          de votre identité
        </p>
        <h3>9. Point de contact en matière de données personnelles</h3>
        <p>
          email de contact: Contact@giveats.com <br />
          adresse de contact: Giveats, 21 rue Vincent van Gogh 95170 Deuil-la-
          Barre
        </p>
        <h3>10. Modifications</h3>
        <p>
          Nous pouvons modifier à tout moment la présente charte. Ces
          modifications s’appliqueront à la date d’entrée en vigueur de la
          version modifiée. Vous êtes donc invité à consulter régulièrement la
          dernière version de cette charte.
        </p>
        <p>Entrée en vigueur : Le 10 septembre 2020 </p>
        <h3>1. Qu’est-ce qu’un Cookie ?</h3>
        <p>
          Lors de votre navigation sur notre Site des cookies, pixels et autres
          traceurs (ci-après désignés ensemble les « Cookies ») sont déposés sur
          votre navigateur.
        </p>
        <p>
          Un Cookie est un petit fichier, souvent crypté, stocké dans votre
          navigateur ou votre terminal et identifié par un nom. Il est déposé
          lors de la consultation d’un site ou d’une application. Chaque fois
          que vous revenez sur le site ou sur l’application en question, le
          Cookie est récupéré sur votre navigateur ou sur votre terminal. Ainsi,
          chaque fois que vous consultez le site ou l’application, le navigateur
          est reconnu.
        </p>
        <p>
          Le dépôt de ces Cookies est susceptible de nous permettre d’accéder à
          vos données de navigation et/ou à des données à caractère personnel
          vous concernant.
        </p>
        <h3>2. Identification des Cookies</h3>
        <h4>Ø Cookies techniques et fonctionnels</h4>
        <p>
          Les Cookies techniques et fonctionnels sont nécessaires au bon
          fonctionnement du Site et pour vous fournir nos services. Ils sont
          utilisés tout au long de votre navigation, afin de la faciliter et
          d’exécuter certaines fonctions.{" "}
        </p>
        <p>
          Un Cookie technique peut par exemple être utilisé pour mémoriser vos
          réponses renseignées dans un formulaire ou encore vos préférences
          s’agissant de la langue ou de la présentation du Site, lorsque de
          telles options sont disponibles.
        </p>
        <p>Nous utilisons les Cookies techniques et fonctionnels suivants: </p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>2 ans</td>
              <td>Utilisé pour distinguer les utilisateurs</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>24 heures</td>
              <td>Utilisé pour distinguer les utilisateurs</td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>1 minute</td>
              <td>Utilisé pour limiter le taux de demande.</td>
            </tr>
            <tr>
              <td>AMP_TOKEN</td>
              <td>30 secondes à 1 an</td>
              <td>
                Contient un jeton qui peut être utilisé pour récupérer un ID
                client à partir du service AMP Client ID. D'autres valeurs
                possibles indiquent la désactivation, la demande en vol ou une
                erreur lors de la récupération d'un ID client à partir du
                service AMP Client ID.
              </td>
            </tr>
            <tr>
              <td>_gac_</td>
              <td>90 jours</td>
              <td>
                Contient des informations relatives à la campagne pour
                l'utilisateur.
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Ø Cookies analytiques</h3>
        <p>
          Ces Cookies nous permettent de mesurer le nombre de visites, le nombre
          de pages vues et l’activité des utilisateurs. Ils peuvent le cas
          échéant collecter votre adresse IP pour déterminer la ville depuis
          laquelle vous vous connectez. Les Cookies analytiques nous permettent
          de générer des statistiques de fréquentation et de navigation de notre
          Site afin d’améliorer nos performances. Les Cookies utilisés
          permettent également d’identifier les problèmes de navigation et à
          terme, de les régler.
        </p>
        <p>Nous utilisons les Cookies analytiques suivants : </p>
        <table class="table">
          <thead>
            <tr>
              <th>Nom du Cookie</th>
              <th>Fonction du Cookie</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>__utma</td>
              <td>2 ans à compter de la création / mise à jour</td>
              <td>
                Utilisé pour distinguer les utilisateurs et les sessions. Le
                cookie est créé lorsque la bibliothèque javascript s'exécute et
                qu'aucun cookie __utma existant n'existe. Le cookie est mis à
                jour chaque fois que des données sont envoyées à Google
                Analytics.
              </td>
            </tr>
            <tr>
              <td>__utmt</td>
              <td>10 minutes</td>
              <td>Utilisé pour limiter le taux de demande.</td>
            </tr>
            <tr>
              <td>__utmb</td>
              <td>30 minutes à partir du set / de la mise à jour</td>
              <td>
                Utilisé pour déterminer les nouvelles sessions / visites. Le
                cookie est créé lorsque la bibliothèque javascript s'exécute et
                qu'aucun cookie __utmb existant n'existe. Le cookie est mis à
                jour chaque fois que des données sont envoyées à Google
                Analytics.
              </td>
            </tr>
            <tr>
              <td>__utmc</td>
              <td>Fin de la session du navigateur</td>
              <td>
                Non utilisé dans ga.js. Défini pour l'interopérabilité avec
                urchin.js. Historiquement, ce cookie fonctionnait en conjonction
                avec le cookie pour déterminer si l'utilisateur était dans une
                nouvelle session / visite.__utmb
              </td>
            </tr>
            <tr>
              <td>__utmz</td>
              <td>6 mois à compter de la création / mise à jour</td>
              <td>
                Stocke la source de trafic ou la campagne qui explique comment
                l'utilisateur a atteint votre site. Le cookie est créé lors de
                l'exécution de la bibliothèque javascript et est mis à jour
                chaque fois que des données sont envoyées à Google Analytics.
              </td>
            </tr>
            <tr>
              <td>__utmv</td>
              <td>2 ans à compter de la création / mise à jour</td>
              <td>
                Utilisé pour stocker des données de variables personnalisées au
                niveau des visiteurs. Ce cookie est créé lorsqu'un développeur
                utilise la méthode avec une variable personnalisée au niveau du
                visiteur. Le cookie est mis à jour chaque fois que des données
                sont envoyées à Google Analytics.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Pour plus de précisions, nous vous invitons à consulter{" "}
          <b>la documentation Google </b> au sujet des cookies utilisés par
          Google Analytics.
        </p>
        <h3>3. Vos préférences en matière de Cookies</h3>
        <h4>Ø Cookies pouvant être déposés sans consentement</h4>
        <p>
          Certains Cookies ne nécessitent pas votre consentement, c’est le cas
          des :{" "}
        </p>
        <ul>
          <li>
            Cookies techniques et fonctionnels qui sont nécessaires au
            fonctionnement du Site;
          </li>
          <li>
            De certains Cookies de mesure d’audience ou des Cookies qui
            permettent de tester des versions différentes du Site à des fins
            d’optimisation des choix éditoriaux
          </li>
        </ul>
        <h4>
          Ø L’acceptation ou le refus des Cookies soumis à votre consentement
          express
        </h4>
        <p>
          Tous les autres Cookies nécessitent votre consentement. Il s’agit des
          Cookies analytiques. Vous pouvez choisir librement d’accepter ou de
          refuser l’utilisation de ces Cookies.{" "}
        </p>
        <p>
          Vous pouvez accepter ou refuser ces Cookies lors de votre première
          navigation sur le Site.
        </p>
        <p>
          <b>
            Vos choix d’accepter ou de refuser les cookies seront conservés
            pendant une durée de six (6) mois.{" "}
          </b>
        </p>
        <p>
          Vous êtes libre de retirer votre consentement et plus généralement de
          modifier vos préférences à tout moment, via <b>le lien suivant.</b>
        </p>
        <h4>Ø Le paramétrage de votre navigateur</h4>
        <p>
          Il est également possible de paramétrer votre navigateur afin qu’il
          accepte ou refuse certains Cookies.{" "}
        </p>
        <p>
          Chaque navigateur propose des modalités de configuration différentes :
        </p>
        <ul>
          <li>
            Pour Internet Explorer : rendez-vous dans le menu « Paramètres »,
            puis « Option Internet ». Cliquez sur « Confidentialité » puis sur «
            Paramètres de confidentialité avancés » ;
          </li>
          <li>
            Pour Chrome : rendez-vous dans le menu « Paramètres » puis cliquez
            sur « Confidentialité et Sécurité ». Cliquez enfin sur «
            Autorisation » et « Cookies et données du sites » ;
          </li>
          <li>
            Pour Safari : rendez-vous dans l’application « Réglages » puis allez
            sur Safari. Cliquez sur l’onglet « Confidentialité et Sécurité ».
            Vous pouvez désormais choisir de bloquer tous les Cookies ;
          </li>
          <li>
            Pour Iphone : rendez-vous dans le menu « Réglage », puis « Safari »,
            cliquez sur « Cookies ». Vous pouvez désormais choisir de bloquer
            tous les Cookies ;{" "}
          </li>
          <li>
            Pour Android : rendez-vous sur « Chrome », cliquez sur l’onglet « »
            tout en haut à droite de l’écran, puis sur « paramètre de site » et
            ensuite sur « Cookies ». Vous pouvez désormais choisir d’accepter ou
            de bloquer les Cookies.
          </li>
        </ul>
      </TextContainer>
    </section>
  );
}
