import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PostDataService from "../../services/post.service";
import parse from "html-react-parser";
import { Post, CardContainer } from "./Blog.styles";
import CardBlog from "./CardBlog";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export default function BlogsId(props) {
  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const publicFolder = process.env.REACT_APP_PUBLIC_FOLDER;
  const initialPostState = {
    id: null,
    desc: "",
    title: "",
    imageUrl: "",
    alt: "",
    category: "",
  };
  const [post, setCurrentPost] = useState(initialPostState);
  const [show, setShow] = useState(false);
  const shareUrl = window.location.href;
  const [idCat, setIdCat] = useState("");

  const getPost = (id) => {
    PostDataService.get(id)
      .then((response) => {
        setCurrentPost(response.data);
        setShow(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) getPost(id);
  }, [id]);

  useEffect(() => {
    if (show === true) {
      setIdCat(post.category[0]._id);
      PostDataService.getByCategoryPublished(idCat)
        .then((res) => {
          setPosts(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [idCat, post.category, show]);

  return (
    <Post>
      <div className="content container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-12">
            <div className="row">
              <div className="header-blog">
                <h1 className="title">{post.title}</h1>
                <div className="category-blog">
                  <p>{show === true ? post.category[0].name : null}</p>
                </div>
              </div>
              <div className="user-info ">
                <img
                  // src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/24043/GNXkJJi1iiq0dsHZ"
                  src={
                    show === true
                      ? process.env.REACT_APP_PUBLIC_FOLDER +
                        post.user[0].imageUrl
                      : null
                  }
                  alt=""
                />
                <div className="user-details">
                  <p>{show === true ? post.user[0].username : null}</p>
                  <p>{new Date(post.createdAt).toLocaleDateString("fr-FR")}</p>
                </div>
              </div>
            </div>
            <div className="img">
              <img src={publicFolder + post.imageUrl} alt={post.alt} />
            </div>
            <div className="text-content">
              <p>{parse(post.desc)}</p>
            </div>
          </div>
          <div className="share col-lg-9 col-12 d-flex justify-content-center">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>
            <FacebookMessengerShareButton url={shareUrl}>
              <FacebookMessengerIcon size={40} round={true} />
            </FacebookMessengerShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>
            <PinterestShareButton url={shareUrl}>
              <PinterestIcon size={40} round={true} />
            </PinterestShareButton>
            <RedditShareButton url={shareUrl}>
              <RedditIcon size={40} round={true} />
            </RedditShareButton>
            <TelegramShareButton url={shareUrl}>
              <TelegramIcon size={40} round={true} />
            </TelegramShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={40} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
          </div>
          <CardContainer className="row d-flex justify-content-around">
            {posts.slice(0, 3).map((post) => (
              <CardBlog
                key={post._id}
                category={post.category[0].name}
                title={post.title}
                imageUrl={publicFolder + post.imageUrl}
                _id={post._id}
                desc={post.desc}
                profilPic={
                  post.user[0].imageUrl
                    ? process.env.REACT_APP_PUBLIC_FOLDER +
                      post.user[0].imageUrl
                    : null
                }
                user={post.user[0].username}
                createdAt={new Date(post.createdAt).toLocaleDateString("fr-FR")}
              ></CardBlog>
            ))}
          </CardContainer>
        </div>
      </div>
    </Post>
  );
}
