import React from "react";
import { Banner, TitleBannerDiv, TextContainer } from "./Mentions.styles";

export default function Mentions() {
  return (
    <section>
      <Banner>
        <div className="container">
          <TitleBannerDiv className="col-sm-8">
            <h1>Mentions Legales</h1>
          </TitleBannerDiv>
        </div>
      </Banner>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 342.318 1452 373.682"
        enable-background="new 0 342.318 1452 373.682"
        xmlSpace="preserve"
      >
        <rect
          x="-27.756"
          y="342.318"
          fill="#FFFFFF"
          stroke="#000000"
          stroke-miterlimit="10"
          width="1548.155"
          height="537.054"
        ></rect>
        <path
          fill="#FF9239"
          d="M669.462,550.5c-289,240.4-567.058,165.5-669.961,98V0h726.25H1452v385
C1311.573,340,958.46,310.1,669.462,550.5z"
        ></path>
      </svg>
      <TextContainer className="container col-10">
        <h3>GIVEATS</h3>
        <p>
          Société par actions simplifiée immatriculée au RCS de Pontoise sous le
          numéro 883 632 663 dont le siège social est situé 21 rue Vincent van
          Gogh 95170 Deuil-la-Barre
        </p>
        <p>N° de TVA intracommunautaire : [FR36883632663]</p>
        <p>
          Numéro de téléphone et l’adresse électronique de la société GIVEATS :{" "}
        </p>
        <p>
          {" "}
          00 33 6 95 29 43 56{" "}
          <a href="mailto:contact@giveats.com">contact@giveats.com</a>
        </p>
        <p>
          Le nom du directeur de la publication SIDI CAMARA & KERMISON BERNARD{" "}
        </p>
        <p>
          Le nom, la dénomination / raison sociale et l'adresse et le numéro de
          téléphone du prestataire d’hébergement :{" "}
        </p>
        <p>
          OVH, 2 rue Kellermann, 59100 ROUBAIX, France <br /> 00 33 01 70 61 31
          00
        </p>
      </TextContainer>
    </section>
  );
}
