import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";
import bgGradient from "../../../images/porbg.png";

export const FoodExperience = styled.div`
  width: 100%;
  height: auto;
  margin-top: 93px;

  .col-12 {
    margin-bottom: 40px;
  }

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  h2 {
    margin-bottom: 50px;
    font-weight: 800;
    line-height: 45px;
    text-align: center;
    color: rgb(0, 0, 0);
  }

  .takeatstitle {
    span {
      color: #9262ff;
    }
  }
`;

export const FoodDiv = styled.div`
  margin-left: auto;
  margin-right: auto;

  h5 {
    font-weight: bold;
    line-height: 27px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin: 0px 0px 10px;
    padding: 0px;
  }

  p {
    font-weight: 300;
    line-height: 27px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: rgb(255, 255, 255);
    margin: 0px 0px 20px;
  }

  .foodsecand {
    color: rgb(255, 255, 255);
    box-shadow: rgb(229, 229, 229) 6px 7px 8px 2px;
    padding: 30px;
    background: rgb(255, 139, 123);
    border-radius: 38px;

    small a {
      display: inline-block;
      text-decoration: none;
      height: 43.1px;
      font-weight: bold;
      font-size: 15px;
      line-height: 37px;
      text-align: center;
      color: rgb(38, 50, 56);
      background: rgb(255, 255, 255);
      border-width: 3px;
      border-style: solid;
      border-color: rgb(255, 255, 255);
      border-image: initial;
      border-radius: 53px;
    }

    small a:hover {
      color: rgb(255, 255, 255);
      text-decoration: none;
      border-width: 3px;
      border-style: solid;
      border-color: rgb(255, 255, 255);
      border-image: initial;
      background: none;
    }
  }

  .foodfirst {
    box-shadow: rgb(229, 229, 229) 6px 7px 8px 2px;
    padding: 30px;
    color: rgb(255, 255, 255);
    background: rgb(255, 146, 57);
    border-radius: 38px;
  }

  .foodthird {
    box-shadow: rgb(229, 229, 229) 6px 7px 8px 2px;
    padding: 30px;
    background: rgb(112, 12, 191);
    border-radius: 38px;
  }

  .btn3 a {
    width: 197px;
    box-shadow: 1px 2px 21px rgb(0 0 0 / 25%);
  }

  figure img {
    width: auto;
    height: 100px;
  }

  @media only screen and ${device.mobileL} {
    text-align: center;
    width: 360px;
    p {
      font-size: 14px;
    }
  }

  @media only screen and ${device.tablet} {
    text-align: center;
    width: 400px;
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.laptop} {
    text-align: center;
    width: 400px;
    p {
      font-size: 20px;
    }
  }
`;

export const ForWho = styled.div`
background: url(${bgGradient});
background-size: 100% 75%;
background-position: top center;
background-repeat: no-repeat;
padding-left: 0px;
padding-right: 0px;

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  h2 {
    font-weight: bold;
    margin-bottom 50px;
    line-height: 60px;
    text-align: center;

    br {
      display: none;
    }
  }


  .foodbox{
    text-align: center;
    border-radius: 38px;
    height: 100%;
    background: #fff;
    box-shadow: rgb(112, 12, 191) 4px 9px 25px;


    h4 {
      font-weight: bold;
      line-height: 27px;
      text-align: center;
      color: #000;
      margin: 0px 0px 10px;
      padding: 0px;
    }

    h4 {
      margin-bottom: 40px;
    }

    p {
      font-weight: 300;
      line-height: 27px;
      text-align: center;
      color: #000;
      margin: 0px 0px 20px;
    }

    b {
      float: left;
      margin-bottom: 30px;
    }
  }

  @media only screen and ${device.mobileL} {
    margin-top: 0px;
    .foodbox{
      padding: 10px;
    }
    p{
      font-size: 15px;
    }
    .boxborder{
      margin-bottom: 50px;
    }
    b { 
      font-size: 13px;
    }
  }

  @media only screen and ${device.tablet} {
    .foodbox{
      padding: 30px;
    }
    margin-top: 93px;
    p{
      font-size: 17px;
    }
    
    .boxborder{
      margin-bottom: 50px;
    }
    b { 
      font-size: 14px;
    }
  }

  @media only screen and ${device.laptop} {
    .foodbox{
      padding: 30px;
    }
    margin-top: 93px;
    p{
      font-size: 20px;
    }
  
    .boxborder{
      margin-bottom: 0px;
    }
    b { 
      font-size: 16px;
    }
  }
`;

export const Video = styled.div`
  text-align: center;
  margin: 160px auto;

  a {
    margin: 60px 90px 0px 90px;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .btnvideo a {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const Item = styled.div`
  //width: 400px;
`;

export const Card = styled.div`
  text-align: center;
  width: 85%;
  height: 100%;
  padding: 30px;
  border-radius: 38px;
  background: #fff;
  min-height: 413px;

  h5 {
    font-weight: bold;
    line-height: 27px;
    text-align: center;
    color: #000;
    margin: 0px 0px 10px;
    padding: 0px;
  }
  img {
    width: 220px;
    padding-bottom: 20px;
  }
  small a {
    display: inline-block;
    text-decoration: none;
    height: 43.1px;
    font-weight: bold;
    font-size: 15px;
    line-height: 37px;
    text-align: center;
    color: rgb(38, 50, 56);
    background: rgb(255, 255, 255);
    border-width: 3px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 53px;
    box-shadow: 1px 2px 21px 0px #00000040;
  }
`;
