import React from "react";
import {
  SolutionTableDiv,
  Table,
  ThLBR,
  TdLR,
  Tr,
  ImageClose,
  TitleTableau,
  MobileCardDiv,
} from "./SolutionTable.style";
import TableClose from "../../../images/tableclose.png";

export default function SolutionTable() {
  return (
    <SolutionTableDiv>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <TitleTableau className="h2title">
              Une solution à <span>moindre coût</span>{" "}
            </TitleTableau>
          </div>
          <div className="col-12 col-sm-12 mobilehide">
            <Table>
              <tbody>
                <Tr>
                  <th></th>
                  <ThLBR>
                    Tarif <br /> par livraison
                  </ThLBR>
                  <ThLBR>
                    Tarif <br />
                    Click&amp;collect
                  </ThLBR>
                  <th>
                    Tarif <br /> réservation de table + précommande en ligne
                  </th>
                </Tr>
                <Tr>
                  <td>
                    <span>GIVEATS</span>
                  </td>
                  <TdLR>
                    <span>25%</span>
                  </TdLR>
                  <TdLR>
                    <span>13%</span>
                  </TdLR>
                  <td>
                    <span>2€HT / table reservée</span>
                  </td>
                </Tr>

                <Tr>
                  <td>
                    AUTRES MARKETPLACES <br />
                    DE LIVRAISON
                  </td>
                  <TdLR>
                    <b>30%</b>
                  </TdLR>
                  <TdLR>
                    <b>15%</b>
                  </TdLR>
                  <td>
                    <ImageClose src={TableClose} alt="tableclose" />
                  </td>
                </Tr>

                <tr>
                  <td>
                    AUTRES PLATEFORMES <br /> DE RÉSERVATION DE <br />
                    TABLE
                  </td>
                  <TdLR>
                    <ImageClose src={TableClose} alt="tableclose" />
                  </TdLR>
                  <TdLR>
                    <ImageClose src={TableClose} alt="tableclose" />
                  </TdLR>
                  <td>
                    <b>2€HT / couvert</b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className="text-center container">
        <MobileCardDiv className="col-12 desktophide">
          <div className="col-12 row">
            <div className="col-6">
              <p> </p>
            </div>
            <div className="col-6">
              <p>
                Tarif <br /> par livraison
              </p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p className="giveats">Giveats</p>
            </div>
            <div className="col-6">
              <p>25%</p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p>Autres marketplace de livraison</p>
            </div>
            <div className="col-6">
              <p className="grey">30%</p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p>Autres plateformes de réservation</p>
            </div>
            <div className="col-6">
              <p>
                <img src={TableClose} alt="" />
              </p>
            </div>
          </div>
        </MobileCardDiv>
        <MobileCardDiv className="col-12 desktophide">
          <div className="col-12 row">
            <div className="col-6">
              <p> </p>
            </div>
            <div className="col-6">
              <p>
                Tarif <br /> Click&Collect
              </p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p className="giveats">Giveats</p>
            </div>
            <div className="col-6">
              <p>13%</p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p>Autres marketplace de livraison</p>
            </div>
            <div className="col-6">
              <p className="grey">15%</p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p>Autres plateformes de réservation</p>
            </div>
            <div className="col-6">
              <p>
                <img src={TableClose} alt="" />
              </p>
            </div>
          </div>
        </MobileCardDiv>
        <MobileCardDiv className="col-12 desktophide">
          <div className="col-12 row">
            <div className="col-6">
              <p> </p>
            </div>
            <div className="col-6">
              <p>Tarif par réservation de table + commande en ligne</p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p className="giveats">Giveats</p>
            </div>
            <div className="col-6">
              <p>2€ / table</p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p>Autres marketplace de livraison</p>
            </div>
            <div className="col-6">
              <p>
                <img src={TableClose} alt="" />
              </p>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <p>Autres plateformes de réservation</p>
            </div>
            <div className="col-6">
              <p className="grey">2€ /convives</p>
            </div>
          </div>
        </MobileCardDiv>
      </div>
    </SolutionTableDiv>
  );
}
