import React from "react";
import { Card } from "./Blog.styles";
import { useHistory } from "react-router-dom";

export default function CardBlog(props) {
  const history = useHistory();

  return (
    <Card
      onClick={() => history.push(`/blog/${props._id}`)}
      className="col-md-4 col-12"
    >
      <div className="card-header">
        <img src={props.imageUrl} alt="city" />
      </div>
      <div className="card-body">
        <div className="card-text">
          <span className="tag tag-pink">{props.category}</span>
          <h4>{props.title}</h4>
        </div>
        <div className="user">
          {props.profilPic ? (
            <img
              // src="https://studyinbaltics.ee/wp-content/uploads/2020/03/3799Ffxy.jpg"
              src={props.profilPic}
              alt="user"
            />
          ) : null}
          <div className="user-info">
            <h5>{props.user}</h5>
            <small>{props.createdAt}</small>
          </div>
        </div>
      </div>
    </Card>
  );
}
