import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";
import BgBottom from "../../../images/img12.png";
import BgWhite from "../../../images/whitecaptionbg.png";
import BgOrange from "../../../images/subcribeboxbottombg1.png";

export const OurPromiseDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(
      143.16deg,
      rgba(255, 146, 57, 0.5) -8.14%,
      rgba(255, 255, 255, 0) 84.72%
    ),
    rgba(112, 12, 191, 0.9);

  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
`;

export const SolutionPleaseDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 80px;
  background: #700cbf;
`;

export const TextPleaseDiv = styled.div`
  text-align: center;

  h3 {
    font-weight: 800;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }

  h4 {
    font-weight: 800;
    line-height: 61px;
    text-align: center;
    color: #ffffff;
    margin-top: 80px;
  }

  p {
    text-align: center;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 25px;
    margin-top: 30px;
    padding: 0px 40px;
    color: #ffffff;

    b {
      font-weight: 700;
    }
  }

  @media only screen and ${device.mobileL} {
    p {
      font-size: 18px;
    }
    h3 {
      margin-top: 83px;
    }
    h4 {
      margin-top: 9px;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      font-size: 20px;
    }
    h3 {
      margin-top: 100px;
    }
    h4 {
      margin-top: 20px;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      font-size: 20px;
    }
    h3 {
      margin-top: 180px;
    }
    h4 {
      margin-top: 80px;
    }
  }
`;

export const BtnCreateAccount = styled(LinkR)`
  width: 200px;
  height: 55px;
  line-height: 54px;
  background: #ff9239;
  text-align: center;
  color: #fff;
  display: inline-block;
  box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
  border-radius: 53px;
  margin-bottom: 30px;
`;

export const ImagePleaseDiv = styled.div`
  padding: 0;

  img {
    width: 100%;
  }
`;

export const JoinUsCarouselDiv = styled.div`
  width: 100%;
  height: auto;

  @media only screen and ${device.mobileL} {
    margin-top: 83px;
    padding: 0px 0px;
  }

  @media only screen and ${device.tablet} {
    margin-top: 153px;
    padding: 0px 50px;
  }

  @media only screen and ${device.laptop} {
    margin-top: 153px;
    padding: 0px 50px;
  }
`;

export const Item = styled.div`
  @media only screen and ${device.mobileL} {
    width: auto;
    margin: 0px 00px;
  }

  @media only screen and ${device.tablet} {
    width: auto;
    margin: 0px 00px;
  }

  @media only screen and ${device.laptop} {
    width: 300px;
    margin: 0px 50px;
  }
`;

export const Card = styled.div`
  text-align: center;
  height: 100%;
  padding: 30px;
  min-height: 413px;

  h3 {
    font-weight: bold;
    font-size: 23px;
    line-height: 25px;
    text-align: center;
    color: #000;
    margin-top: 20px;
    padding: 0px;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  @media only screen and ${device.mobileL} {
    img {
      width: 200px;
    }
  }

  @media only screen and ${device.tablet} {
    img {
      width: 230px;
    }
  }

  @media only screen and ${device.laptop} {
    img {
      width: 230px;
    }
  }
`;

export const TitleCarouselDiv = styled.div`
  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }
`;

export const BottomDiv = styled.div`
  margin-top: 70px;

  position: relative;
  width: 100%;
  height: auto;
  display: inline-block;
  background: url(${BgBottom});
`;

export const WhiteBgDiv = styled.div`
  z-index: 100;
  text-align: center;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 430px;
  height: 366px;
  background: url(${BgWhite});
  background-size: 100% auto;
  padding: 110px 161px 0px 0px;

  h4 {
    font-weight: bold;
    font-size: 40px;
    margin: 0px;
    padding: 0px;
    line-height: 52px;
    color: #ff9239;
  }

  p {
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  a {
    width: 189px;
    height: 53px;
    line-height: 52px;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    background: #ffaf3b;
    box-shadow: 5px 4px 29px rgb(0 0 0 / 25%);
    border-radius: 53px;
  }

  @media only screen and ${device.mobileL} {
    width: auto;
    background-size: auto;
  }

  @media only screen and ${device.tablet} {
    width: 430px;
    background-size: 100% auto;
  }

  @media only screen and ${device.laptop} {
    width: 430px;
    background-size: 100% auto;
  }
`;

export const OrangebgDiv = styled.div`
  background: url(${BgOrange});
  width: 707px;
  height: 742px;
  margin-top: 0px;
  text-align: center;
  float: right;

  h3 {
    line-height: 44px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

  p {
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and ${device.mobileL} {
    padding: 19px 10px 10px 341px;
    p {
      font-size: 20px;
    }
  }

  @media only screen and ${device.tablet} {
    padding: 130px 10px 10px 238px;
    p {
      font-size: 23px;
    }
  }

  @media only screen and ${device.laptop} {
    padding: 130px 10px 10px 238px;
    p {
      font-size: 25px;
    }
  }
`;

export const DivBtnSubRestaurant = styled.div`
  text-align: center;

  margin-top: 50px;
  position: relative;
  z-index: 200;
`;

export const DivPartenaire = styled.div`
  width: 100%;
  height: auto;
`;

export const ItemCarousel = styled.div`
  text-align: center;
  margin-bottom: 100px;

  @media only screen and ${device.mobileL} {
    img {
      width: 150px;
    }
  }

  @media only screen and ${device.tablet} {
    img {
      width: 200px;
    }
  }

  @media only screen and ${device.laptop} {
    img {
      width: 200px;
    }
  }
`;

export const H3 = styled.h3`
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 80px;
  line-height: 75px;
  text-align: center;
  span {
    color: #ff9239;
  }
`;
