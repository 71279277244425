import React from "react";
import {
  Image,
  TexteImage,
  AdvantageDiv,
  FunctionalityDiv,
  FuncImage,
  FuncBtnDemo,
  ImgAbsoluteDiv,
  DivTextImage,
  ImgRelative,
  DivBGColor,
  DivHideTabletImg,
} from "./Advantage.styles";
import BtnDemo from "../../buttons/BtnDemo";
import TabImg1 from "../../../images/gatiimg1.png";
import TabImg2 from "../../../images/handimg1.png";
import TabImg3 from "../../../images/handimg2.png";
import TabImg4 from "../../../images/handimg3.png";
import TabImgPhone2 from "../../../images/gatiimg2.png";
import TabImgPhone3 from "../../../images/gatiimg3.png";
import TabImgPhone4 from "../../../images/gatiimg4.png";
import FuncImg from "../../../images/tableview.png";
import FuncImg1 from "../../../images/tableview1.png";
import FuncImg2 from "../../../images/tableview2.png";
import FuncImg3 from "../../../images/tableview3.png";

export default function Advantage() {
  return (
    <>
      <AdvantageDiv>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-12">
              <h2>
                <span>Les avantages</span> à utiliser <br />
                Giveats restaurateur ?
              </h2>
            </div>
          </div>
        </div>
        <DivBGColor>
          <div className="container">
            <div className="row">
              <Image className="col-lg-6 col-12">
                <img src={TabImg1} alt="tabimg1" />
              </Image>
              <TexteImage className="col-lg-6 col-12">
                <div>
                  <p>
                    Prendre le virage du digital responsable en devenant un
                    restaurant solidaire{" "}
                  </p>
                </div>
                <div>
                  <p>
                    Gagner en visibilité auprès d’une communauté de foodies
                    engagés
                  </p>
                </div>
              </TexteImage>
            </div>
          </div>
        </DivBGColor>
        <DivHideTabletImg className="desktophide">
          <img src={TabImgPhone2} alt="tabletimage" />
        </DivHideTabletImg>
        <DivBGColor backgroundColor="#700CBF">
          <div className="container">
            <DivTextImage
              className="col-sm-12 col-12 row"
              color="#fff"
              height="550px"
            >
              <div className="col-lg-5 col-12">
                <div>
                  <p>
                    Augmenter votre chiffre d’affaires avec de nouvelles sources
                    de revenus pérennes
                  </p>
                </div>
                <div>
                  <p>
                    Rentabiliser vos pertes avec une solution antigaspillage et
                    solidaire{" "}
                  </p>
                </div>
              </div>
              <ImgAbsoluteDiv className="col-md-7 col-12 mobilehide">
                <ImgRelative src={TabImg2} alt="tabimg2" />
              </ImgAbsoluteDiv>
            </DivTextImage>
          </div>
        </DivBGColor>
        <DivHideTabletImg className="desktophide">
          <img src={TabImgPhone3} alt="tabletimage" />
        </DivHideTabletImg>
        <DivBGColor>
          <div className="container">
            <DivTextImage className="col-sm-12 col-12 row" height="445px">
              <ImgAbsoluteDiv className="col-lg-6 col-12 mobilehide">
                <ImgRelative src={TabImg3} alt="tabimg3" />
              </ImgAbsoluteDiv>
              <div className="col-lg-5 col-12">
                <div>
                  <p>
                    Gérer vos livraisons et le service sur place sur une seule
                    plateforme digitale{" "}
                  </p>
                </div>
                <div>
                  <p>
                    Fluidifier votre service en simplifiant le parcours d’achat
                    de vos clients sur place{" "}
                  </p>
                </div>
              </div>
            </DivTextImage>
          </div>
        </DivBGColor>
        <DivHideTabletImg className="desktophide">
          <img src={TabImgPhone4} alt="tabletimage" />
        </DivHideTabletImg>
        <DivBGColor backgroundColor="#FF9239">
          <div className="container">
            <DivTextImage className="col-12 row" color="#fff" height="403px">
              <div className="col-lg-5 col-12">
                <div>
                  <p>
                    Éliminer les no-shows sur les réservations de table avec un
                    acompte (+ une option de commande à l’avance)
                  </p>
                </div>
              </div>
              <ImgAbsoluteDiv className="col-md-6 col-12 mobilehide">
                <ImgRelative src={TabImg4} alt="tabimg4" />
              </ImgAbsoluteDiv>
            </DivTextImage>
          </div>
        </DivBGColor>
      </AdvantageDiv>
      <FunctionalityDiv>
        <div className="container">
          <div class="row">
            <div class="col-12 col-sm-12">
              <h2>
                <span>Nos fonctionnalités</span> pour votre restaurant
              </h2>
            </div>
            <div class="col-12 col-sm-12 img-container">
              <FuncImage className="phonehide" src={FuncImg} alt="funcimg" />
              <FuncImage
                className="mb-4 tablethide"
                src={FuncImg1}
                alt="funcimg"
              />
              <FuncImage
                className="mb-4 tablethide"
                src={FuncImg2}
                alt="funcimg"
              />
              <FuncImage
                className="mb-4 tablethide"
                src={FuncImg3}
                alt="funcimg"
              />
            </div>
            <div class="col-12 col-sm-12 text-center">
              <span class="givbtn">
                <BtnDemo />
              </span>
            </div>
          </div>
        </div>
      </FunctionalityDiv>
    </>
  );
}
