import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../../theme/Device";

export const Div = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 65px;
  position: relative;
  background: linear-gradient(
      140.47deg,
      #ff8b7b 19.27%,
      rgba(255, 255, 255, 0) 80.73%
    ),
    linear-gradient(
      194.03deg,
      rgba(99, 10, 170, 0.8) 18.29%,
      rgba(255, 255, 255, 0) 90%
    ),
    linear-gradient(0deg, #ff9339, #ff9339);

  h2 {
    color: #fff;
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: 700;
    text-align: center;
  }

  .sncdh2 {
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  h5 {
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: #ffffff;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  @media only screen and ${device.mobileL} {
    padding-top: 60px;
    margin-top: 0px;
    .sncdh2 {
      margin-bottom: 0px;
    }
    h5 {
      text-align: center;
    }
  }
  @media only screen and ${device.tablet} {
    padding-top: 100px;
    margin-top: 100px;
    .sncdh2 {
      margin-bottom: 48px;
    }
    h5 {
      text-align: left;
    }
  }
  @media only screen and ${device.laptop} {
    padding-top: 100px;
    margin-top: 100px;
    .sncdh2 {
      margin-bottom: 48px;
    }
    h5 {
      text-align: left;
    }
  }
`;

export const List = styled.li`
  margin-bottom: 27px;
  width: 100%;
  display: inline-block;

  span {
    border-radius: 50px;
    display: inline-block;
    background: #ffffff;
    text-align: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    float: left;
    margin-right: 20px;
    color: #700cbf;
    box-shadow: 2px 5px 11px -2px rgb(0 0 0 / 25%);
  }

  p {
    font-weight: normal;
    font-size: 23px;
    width: 80%;
    margin-bottom: 0px;
    float: left;
    line-height: 20px;
    color: #ffffff;
  }

  @media only screen and ${device.mobileL} {
    p {
      font-size: 18px;
    }
    span {
      line-height: 45px;
      width: 45px;
      height: 45px;
      font-size: 30px;
    }
  }

  @media only screen and ${device.tablet} {
    p {
      font-size: 20px;
    }
    span {
      line-height: 55px;
      width: 55px;
      height: 55px;
      font-size: 35px;
    }
  }

  @media only screen and ${device.laptop} {
    p {
      font-size: 23px;
    }
    span {
      line-height: 55px;
      width: 55px;
      height: 55px;
      font-size: 35px;
    }
  }
`;

export const Image = styled.div`
  margin-bottom: 55px;

  @media only screen and ${device.mobileL} {
    padding-left: 0px;
    text-align: center;
    img {
      width: 142px;
    }
  }

  @media only screen and ${device.tablet} {
    padding-left: 35px;
    img {
      width: 310px;
    }
  }

  @media only screen and ${device.laptop} {
    padding-left: 35px;
    img {
      width: 310px;
    }
  }
`;

export const DivWhiteImg = styled.div`
  text-align: center;

  img {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

export const DivWhy = styled.div`
  width: 100%;
  height: auto;
  background: #ff9239;
  padding-bottom: 100px;

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  @media only screen and ${device.mobileL} {
    padding-top: 60px;
    img {
      width: 100%;
    }
  }

  @media only screen and ${device.tablet} {
    padding-top: 100px;
    img {
      width: 100%;
    }
  }

  @media only screen and ${device.laptop} {
    padding-top: 100px;
    img {
      width: 100%;
    }
  }
`;

export const TextDiv = styled.div`
  padding-top: 20px;

  h5 {
    margin-left: -10%;
    padding: 0px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 28px;
    width: auto;
    display: inline-block;
    color: #ffffff;
  }

  p {
    margin-left: -5%;
    font-weight: normal;
    margin-bottom: 43px;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
  }

  b {
    font-size: 34px;
    font-weight: bolder;
    color: white;
    text-shadow: 3px 0px 1px #700cbf;
  }

  @media only screen and ${device.mobileL} {
    padding-left: 0px;
    h5 {
      margin-left: 0px;
    }
    p {
      margin-left: 0px;
      font-size: 16px;
    }
  }

  @media only screen and ${device.tablet} {
    padding-left: 80px;
    h5 {
      margin-left: -10%;
    }
    p {
      margin-left: -5%;
      font-size: 20px;
    }
  }

  @media only screen and ${device.laptop} {
    padding-left: 80px;
    h5 {
      margin-left: -10%;
    }
    p {
      margin-left: -5%;
      font-size: 20px;
    }
  }
`;

export const ButtonDiv = styled.div`
  .btn5 a {
    width: 175px;
    height: 51px;
    line-height: 50px;
    display: inline-block;
    background: #ffffff;
    font-size: 20px;
    text-align: center;
    color: #263238;
    box-shadow: 1px 2px 21px rgba(0, 0, 0, 0.25);
    border-radius: 53px;
  }
  .btn5 a:hover {
    background: #fff;
    color: #000;
    text-decoration: none;
  }
`;
