import styled from "styled-components";
import { device } from "../../../theme/Device";

export const AdvantageDiv = styled.div`
  width: 100%;
  height: auto;
  padding-top: 80px;

  h2 {
    line-height: 60px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;

    span {
      color: #ff9239;
    }
  }
`;

export const Image = styled.div`
  padding: 0px;

  img {
    width: 100%;
  }
`;

export const ImgAbsoluteDiv = styled.div``;

export const ImgRelative = styled.img`
  position: relative;
  right: ${(props) => (props.right ? props.right : "-68px")};
  top: ${(props) => (props.top ? props.top : "-222px")};
  left: ${(props) => (props.left ? props.left : "0px")};
  width: 694px;
`;

export const TexteImage = styled.div`
  color: ${(props) => (props.color ? props.color : "#000000")};

  p {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 25px;
  }

  @media only screen and ${device.mobileL} {
    padding: 80px 15px 50px 15px;
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.tablet} {
    padding: 65px 16px 48px 15px;
    p {
      font-size: 20px;
    }
  }

  @media only screen and ${device.laptop} {
    padding: 171px 120px 50px 120px;
    p {
      font-size: 20px;
    }
  }
`;
export const DivBGColor = styled.div`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fafafa"};
`;

export const DivTextImage = styled.div`
  color: ${(props) => (props.color ? props.color : "#000000")};
  padding: 170px 0px 50px 0px;

  margin: 0;

  p {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 25px;
  }
  @media only screen and ${device.mobileL} {
    height: auto;
    padding: 90px 0px 50px 0px;
    p {
      font-size: 18px;
    }
  }

  @media only screen and ${device.tablet} {
    height: auto;
    padding: 90px 0px 50px 0px;
    p {
      font-size: 20px;
    }
  }

  @media only screen and ${device.laptop} {
    height: ${(props) => (props.height ? props.height : "")};
    padding: 170px 0px 50px 0px;
    p {
      font-size: 20px;
    }
  }
`;
export const DivHideTabletImg = styled.div`
  img {
    width: 100%;
  }
`;

export const FunctionalityDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;

  h2 {
    line-height: 60px;
    font-family: Nunito;
    font-style: normal;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;

    span {
      color: #ff9239;
    }
  }

  .img-container {
    margin-bottom: 100px;
  }
  @media only screen and ${device.mobileL} {
    padding: 0px 0px;
  }

  @media only screen and ${device.tablet} {
    padding: 0px 0px;
  }

  @media only screen and ${device.laptop} {
    padding: 0px 90px;
  }
`;

export const FuncImage = styled.img`
  width: 100%;
`;
