import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { device } from "../../theme/Device";

export const Header = styled.header`
  background-color: "transparent";
  height: 100px;
  margin-top: -100px;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  // padding: 0.5rem calc((100vw - 1000px) / 2);

  i {
    font-size: 12px;
    position: relative;
    top: -1px;
    margin-left: 3px;
    color: #c4c4c4;
  }
  .nav-link {
    display: inline;
  }

  .nav-item {
    margin-left: 18px;
    display: block;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-phone {
    background-color: #fff;
    margin-top: -20px;
    padding-top: 30px;
    padding-bottom: 100vh;

    li {
      margin-top: 8px;

      ul {
        padding: 0px;
        margin-top: 0px;
        filter: none;
      }
    }
    .takeats {
      margin-top: 18px;
    }

    .restaurant {
      margin-top: 30px;
    }
  }
`;

export const NavbarContainer = styled.div`
  justify-content: space-between;
`;

export const NavLogo = styled(LinkR)`
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  text-decoration: none;

  @media only screen and ${device.mobileL} {
    img {
      width: 150px;
    }
  }

  @media only screen and ${device.tablet} {
    img {
      width: 196px;
    }
  }

  @media only screen and ${device.laptop} {
    img {
      width: 196px;
    }
  }
`;

export const NavMenu = styled.ul`
  list-style: none;
`;

export const NavLi = styled.li``;

export const NavBtnUl = styled.ul``;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #ff9239;
  white-space: nowrap;
  padding: 10px 15px;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  &:hover {
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0px 0px 5px #656565;
    -moz-box-shadow: 0px 0px 5px 3px #656565;
    color: #fff;
  }
`;

export const NavLink = styled(LinkR)`
  border-radius: 50px;
  white-space: nowrap;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-align: right;
  color: ${(props) => (props.color ? props.color : "#fff")};

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #c4c4c4;
  }
  &:focus {
    color: #c4c4c4;
  }
`;

export const NavBtnLink2 = styled(LinkR)`
  border-radius: 50px;
  padding: 10px 32px;
  background: #fd8368;
  white-space: nowrap;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  &:hover {
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0px 0px 5px #656565;
    -moz-box-shadow: 0px 0px 5px 3px #656565;
    color: #fff;
  }
`;
export const NavBtnLinkS = styled(LinkR)`
  width: 150px;
  height: 45px;
  border-radius: 45px;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3 ease;

  :hover {
    background: #2ceae5;
    transform: translateY(-7px);
    color: #fff;
    box-shadow: 0px 10px 25px rgba(45, 223, 229, 0.445);
  }
`;
export const DropdownMenu = styled.ul`
  width: ${(props) => (props.dropWidth ? props.dropWidth : "220px")};
  background: #ffffff;
  margin-top: 23px !important;
  padding: 27px 0px;
  border-radius: 17px;
  border: none;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  left: auto;
  right: 0px;

  :before {
    content: "";
    position: absolute;
    left: ${(props) => (props.left ? props.left : "0")};
    top: 18px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 69px 59px;
    border-color: transparent transparent rgb(255, 255, 255);
    transform: rotate(-86deg);
    z-index: -2;
  }
`;

export const DropDownItem = styled(LinkR)`
  padding: 0rem 0.8rem 0rem 0.8rem;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 9px;
  text-align: left;
  color: #c4c4c4;

  :hover,
  :focus {
    color: #615f5f;
    background: none;
  }

  img {
    margin-right: 10px;
  }

  span {
    padding-left: 33px;
  }

  .collapse-span {
    padding-left: 0px;
  }
`;

export const ToggleButton = styled.button`
  #checkbox {
    display: none;
  }
  .hamburger {
    position: relative;
    padding: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .line {
    position: absolute;
    width: 21px;
    height: 2px;
    background-color: #fff;
    border-radius: 3px;
    transition: transform 0.25s, opacity 0.35s;
  }
  .line--top {
    transform: translate(-7px);
  }

  .line--bottom {
    transform: translate(7px);
  }

  #checkbox:checked ~ .line--top {
    transform: rotate(45deg);
  }
  #checkbox:checked ~ .line--middle {
    transform: translateX(16px);
    opacity: 0;
  }
  #checkbox:checked ~ .line--top {
    transform: rotate(-45deg);
  }
`;

export const HamburgerMenu = styled.img`
  width: 32px;
`;
