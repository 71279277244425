const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "200px",
  mobileLMax: "425px",
  tablet: "768px",
  laptop: "992px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileLMax: `(max-width: ${size.mobileLMax})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletMax: `(max-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopMax: `(max-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
