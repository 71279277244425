import React from "react";
import { ButtonInscris } from "./Btn.styles";

const BtnAsso = (props) => {
  return (
    <ButtonInscris
      color={props.color}
      background={props.bg ? props.bg : "#FF9239"}
      widthMob={props.widthMob}
      widthTab={props.widthTab}
      widthLaptop={props.widthLaptop}
      to={props.to ? props.to : "/association-form"}
    >
      {props.content ? props.content : "Devenir partenaire"}
    </ButtonInscris>
  );
};

export default BtnAsso;
