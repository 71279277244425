import React, { useState, useEffect } from "react";
import PostDataService from "../../services/post.service";
import CategoryDataService from "../../services/category.service";
import CardBlog from "./CardBlog";
import Categorie from "./Category";
import {
  BlogBody,
  BlogContainer,
  TitleBlog,
  CardContainer,
  CategoryContainer,
  TitleCat,
  Search,
} from "./Blog.styles";

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [postsByCatValue, setPostsByCatValue] = useState("all");
  const [postsByCat, setPostsByCat] = useState([]);
  const [categories, setCategories] = useState([]);
  const publicFolder = process.env.REACT_APP_PUBLIC_FOLDER;

  useEffect(() => {
    PostDataService.getByDatePublished()
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    CategoryDataService.getAll()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (postsByCatValue !== "all") {
      PostDataService.getByCategoryPublished(postsByCatValue)
        .then((res) => {
          setPostsByCat(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [postsByCatValue]);

  const handleChange = (event) => {
    setPostsByCatValue(event.target.value);
  };

  return (
    <BlogBody>
      <BlogContainer className="container">
        <TitleBlog className="col-12">
          <h1>BLOG</h1>
        </TitleBlog>
        {/* <CardContainer className="row d-flex justify-content-around">
          {posts.slice(0, 3).map((post) => (
            <CardBlog
              key={post._id}
              category={post.category[0].name}
              title={post.title}
              imageUrl={publicFolder + post.imageUrl}
              _id={post._id}
              desc={post.desc}
              profilPic={
                post.user[0].imageUrl
                  ? process.env.REACT_APP_PUBLIC_FOLDER + post.user[0].imageUrl
                  : null
              }
              user={post.user[0].username}
              createdAt={new Date(post.createdAt).toLocaleDateString("fr-FR")}
            ></CardBlog>
          ))}
        </CardContainer> */}
        {/* <CategoryContainer className="row d-flex justify-content-around">
          <TitleCat>
            <h2>CATEGORIES</h2>
          </TitleCat>
          {categories.map((category) => (
            <Categorie key={category._id} name={category.name} />
          ))}
          <Search class="box">
            <div id="cover">
              <form method="get" action="">
                <div class="tb">
                  <div class="td">
                    <input type="text" placeholder="Search" required />
                  </div>
                  <div class="td" id="s-cover">
                    <button type="submit">
                      <div id="s-circle"></div>
                      <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Search>
        </CategoryContainer> */}
        <div>
          <select value={postsByCatValue} onChange={handleChange}>
            <option value="all">All</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}{" "}
              </option>
            ))}
          </select>
        </div>
        <CardContainer className="row d-flex justify-content-around">
          {postsByCatValue === "all"
            ? posts.map((post) => (
                <CardBlog
                  key={post._id}
                  category={post.category[0].name}
                  title={post.title}
                  _id={post._id}
                  desc={post.desc}
                  imageUrl={publicFolder + post.imageUrl}
                  user={post.user[0].username}
                  profilPic={
                    post.user[0].imageUrl
                      ? process.env.REACT_APP_PUBLIC_FOLDER +
                        post.user[0].imageUrl
                      : null
                  }
                  createdAt={new Date(post.createdAt).toLocaleDateString(
                    "fr-FR"
                  )}
                ></CardBlog>
              ))
            : postsByCat.map((post) => (
                <CardBlog
                  key={post._id}
                  category={post.category[0].name}
                  title={post.title}
                  _id={post._id}
                  desc={post.desc}
                  imageUrl={publicFolder + post.imageUrl}
                  user={post.user[0].username}
                  profilPic={
                    post.user[0].imageUrl
                      ? process.env.REACT_APP_PUBLIC_FOLDER +
                        post.user[0].imageUrl
                      : null
                  }
                  createdAt={new Date(post.createdAt).toLocaleDateString(
                    "fr-FR"
                  )}
                ></CardBlog>
              ))}
        </CardContainer>
      </BlogContainer>
    </BlogBody>
  );
}
